.weight-tracker-card {
  font-family: 'Montserrat', sans-serif;
  background-color: white;
  border-radius: 10px;
  width: calc(100% - 10px);
  margin: 10px 5px;
  height:84px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.weight-tracker-header {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 0 15px;
}

.header-left {
  flex: 1;
  text-align: left;
}

.header-center {
  flex: 1;
  text-align: center;
  font-size: 12px;
  color: rgb(131, 131, 131);
  font-family: 'Montserrat';
  font-weight: 300;
}

.header-right {
  flex: 1;
  text-align: right;
}

.weight-empty-state {
  display: flex;

  cursor: pointer;
}

.empty-state-title {
  font-family: 'Montserrat-SemiBold';
  font-size: 14px;
}

.empty-state-subtitle {
  font-size: 12px;
  color: #82867E;
  margin-top: 4px;
}

.weight-content {
  padding: 8px 16px;
}

.view-progress-button {
  background: none;
  border: 1px solid #f1a092;
  color: #f1a092;
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
}

.view-progress-button:hover {
  background: rgba(241, 160, 146, 0.1);
}

/* Dialog Styles */
.weight-graph-dialog,
.info-dialog {
  padding: 20px;
  max-width: 350px;
}

.dialog-title {
  font-family: 'Montserrat-SemiBold';
  font-size: 16px;
  margin-bottom: 16px;
}

.dialog-content {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}

.ai-consultation-prompt {
  font-size: 12px;
  color: #666;
  text-align: center;
  margin: 15px 5px;
}

.consult-button {
  background: #2D7933;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

.dialog-close-button {
  background: none;
  border: none;
  color: #f1a092;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 16px;
} 