/* WeekSelector.css */
.week-selector {
    margin: 20px 0;
    width:calc(100vw - 50px);
    max-width: 380px;
  }

.week-selector  select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    color: black;
  }
  