.contact-us-page-top {
  background: url('images/thymeless-short.jpg');
  min-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: grayscale(0.1) brightness(0.97) opacity(0.92);
  /* justify-content: center; */
}

.contact-us-form-line-item {
  padding: 5px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media(min-width:1542px) {
  .contact-us-page-top {
    background: url('images/thymeless-wide-short.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    filter: grayscale(0.1) brightness(0.97) opacity(0.92);


  }
}

@media(max-width:742px) {
  .contact-us-page-top {
    background: url('images/thymeless-mobile.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    /* padding:60px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  .contact-us-form-line-item {
    padding: 5px;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

}

.contact-us-form {
  margin: 20px;
  padding: 10px;
}

.contact-us-form-header {
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  margin: 20px;
}

.contact-us-form input {
  font-size: 16px;
}

.contact-us-form textarea {

  font-size: 16px;
}

.contact-us-form-input-narrow-left {
  margin-right: 10px !important;
  width: calc(50% - 5px) !important;
}

.contact-us-form-input-narrow-right {
  width: calc(50% - 5px) !important;
}

.contact-us-form-input-wide {
  width: 100% !important;
}

.contact-us-form-submit-button {
  display: flex;
  justify-content: center;
  margin: 8px 0px !important;
}