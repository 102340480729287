.bt-basic-info-form {
    padding: 30px 15px;
    max-width: 500px;
    width: calc(100vw - 30px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.bt-basic-info-section {
    margin-bottom: 20px;
}

/* For medium height screens */
@media screen and (min-height: 700px) {
    .bt-basic-info-section {
        margin-bottom: 28px;
    }
    
    .bt-basic-info-title {
        margin-bottom: 28px;
    }
}

/* For taller screens */
@media screen and (min-height: 800px) {
    .bt-basic-info-form {
        justify-content: center;
    }
    
    .bt-basic-info-section {
        margin-bottom: 36px;
    }
    
    .bt-basic-info-title {
        margin-bottom: 36px;
    }
}

.bt-basic-info-height-imperial {
    display: flex;
    gap: 16px;
    position: relative;
}

.bt-basic-info-height-imperial .MuiTextField-root {
    flex: 1;
}

.bt-basic-info-title {
    font-size: 18px;
    margin-bottom: 20px;
    font-family: 'Montserrat-SemiBold';
    text-align: center;
}

/* Material-UI overrides scoped to basic info form */
.bt-basic-info-form .MuiOutlinedInput-root {
    border-radius: 8px;
}

.bt-basic-info-form .MuiInputLabel-outlined {
    background-color: white;
    padding: 0 4px;
}

.bt-basic-info-form .MuiFormHelperText-root {
    margin-top: 4px;
    font-size: 12px;
}
