.modify-recipe-top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.modify-recipe-top-column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 10px 10px 00px 10px;
    max-width: 480px;
    align-items: center;
}

.modify-recipe-action-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 10px 10px 0px 10px;
}

.modify-recipe-top-button {
    font-size: 13px !important;
    border-radius: 10px !important;
    text-transform: none !important;
    font-family: 'Montserrat-SemiBold' !important;
    color: #4e4e4e !important;
    padding:5px 9px !important;
}

.modify-recipe-instructions-add-button {

    font-size: 13px !important;
    border-radius: 10px !important;
    text-transform: none !important;
    font-family: 'Montserrat-SemiBold' !important;
    color: #4e4e4e !important;
    margin-top: 15px !important;
}

.modify-recipe-change-image-button {
    font-size: 12px !important;
    min-width: 40px !important;
    height: 30px !important;
    margin-top: -33px !important;
    background-color: rgb(255, 255, 255, 0.9) !important;
    align-self: center !important;
}

.modify-recipe-staged-changes-warning {
    position: sticky !important;
    z-index: 100 !important;
    top: 5px;
    opacity: 0.9;
    font-size: 12px !important;
    padding: 0px 5px !important;
    margin-top: -10px;

}


.modify-recipe-staged-changes-warning  .MuiSvgIcon-fontSizeSmall{
    font-size: 16px;

}

.modify-recipe-staged-changes-warning .MuiAlert-action{
    padding-left: 0px;
}

.modify-recipe-staged-changes-warning .MuiAlert-message{
    width:100%;
}

.modify-recipe-exit-button {
    min-width: 20px !important;
    max-height: 20px !important;
    padding: 5px 2px !important;
    right: 15px;
    text-align: right;
    position: fixed !important;
    top: 15px;
    z-index: 500 !important;
    color: rgb(83, 83, 83) !important;
}

.modify-recipe-exit-button svg {
    font-size: 16px !important;

}

.modify-recipe-name {
    margin: 15px 0px 5px 0px !important;
    width: 400px !important;
    max-width: 70vw;
}

.modify-recipe-description {
    margin: 15px 0px 0px 0px !important;
    width: 400px !important;
    max-width: 70vw;
}

.modify-recipe-minutes {
    margin: 10px 0px !important;
    width: 195px !important;
    max-width: 70vw;
}


.modify-recipe-top .MuiSelect-selectMenu {

    white-space: normal !important;
}

.modify-recipe-top .MuiChip-root {
    margin: 1px
}


.modify-recipe-tags, .modify-recipe-categories {
    margin: 12px 0px 5px 0px;
    font-size: 14px;
    white-space: normal; /* Allow tags to wrap */
}

.chip-container {
    display: flex;
    flex-wrap: wrap; /* Ensure that chips wrap within the container */
    justify-content: center;
}

.chip-container .MuiChip-root {
    margin: 2px;
}


.modify-recipe-picture {
    height: 260px;
    width: 260px;
    object-fit: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: auto 50px;

}


.modify-recipe-instruction-textfield {
    width: 600px;
    margin-right: 10px !important;
    margin-left: 10px !important;
}

.modify-recipe-instruction-textfield .MuiInput-root {
    font-size: 14px !important;
    font-family: 'Montserrat-Medium';
}

.modify-recipe-recipe-instruction-number {
    background: #D9EEBD !important;
    color: #07490D !important;
    width: 25px;
    height: 25px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 50%;
    font-family: 'Montserrat-SemiBold';
    margin-right: 3px;
}



.modify-recipe-ingredient-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.modify-recipe-ingredient-label {
    display: flex;
    margin: 5px 8px;
    font-size: 12px;
    font-family: 'Montserrat-SemiBold';
    text-align: left;
    max-width: 200px;
}

.modify-recipe-ingredient-image-div {
    width: 80px;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-right: 15px;
}

.modify-recipe-ingredient-row {
    display: flex;
    align-items: center;
    height: 75px;
    border-top: #ececec solid 1px;

}


.modify-recipe-name input {
    font-size: 14px;
    font-family: 'Montserrat-Medium';
}

.modify-recipe-name textarea {
    font-size: 14px;
    font-family: 'Montserrat-SemiBold';


}

.modify-recipe-description textarea {
    font-size: 14px;
    font-family: 'Montserrat-Medium';


}

.ingredient-search-textfield input {
    font-size: 16px;
}


.modify-recipe-minutes input {
    font-size: 14px;
    font-family: 'Montserrat-Medium';
}



.modify-recipe-ingredient-image {
    height: 50px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}




.modify-recipe-ingredient-column .MuiInput-root {
    margin: 4px !important;
    height: 22px;
    font-size: 13px !important;
    font-family: 'Montserrat-SemiBold';
    color: #797979
}

.modify-recipe-amount-in-grams {
    color: grey;
    margin-right: 10px;
    font-size: 12px;
    font-family: 'Montserrat-SemiBold';
}

.modify-recipe-amount-in-grams1 {
    color: grey;
    margin-right: 10px;
    font-size: 12px;
    min-width: 70px;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat-SemiBold';
}

.modify-recipe-ingredient-delete-button {
    min-width: 10px !important;
    max-width: 10px !important;
    font-size: 10px !important;
    padding: 2px 8px !important;
    margin-left: auto !important;
}

.modify-recipe-ingredient-column .MuiSelect-select.MuiSelect-select {
    padding-right: 14px;
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold';
}

.modify-recipe-ingredient-row-drag-indicator {
    display: none !important;
}

.modify-recipe-unit-text {
    margin-left: -15px;
    font-family: 'Montserrat-SemiBold';
}

.modify-recipe-unit-text-normal {
    font-size: 11px;
    min-width: 70px;
    font-family: 'Montserrat-SemiBold';
}

.modify-recipe-unit-select .MuiSvgIcon-root {
    font-size: 14px !important;
    margin-top: 5px !important;
}


.modify-recipe-instruction-row {
    margin-top: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px !important;
    margin-left: 10px;
    margin-right: 10px;

}


.modify-recipe-instruction-textfield {
    width: calc(100% - 50px);
    margin-right: 10px !important;
    margin-left: 8px !important;

}

.modify-recipe-instruction-textfield .MuiInput-root {
    font-size: 13px !important;
    color: #676767;
    font-family: 'Montserrat-SemiBold';

}






.modify-recipe-ingredients-header {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Montserrat-SemiBold';
}

.modify-recipe-instructions {
    width: 100%;
    padding-bottom: 10px;
}


.modify-recipe-ingredients-manual-toggle {
    color: blue !important;
}

.modify-recipe-nutrient-textfield {
    margin: 5px !important;
    width: 100%;
}

.ingredient-search-textfield {
    width: 250px !important;
    margin-top: 10px !important;
}



.modify-recipe-ingredients-row {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}



.modify-recipe-ingredients-calorie-label {
    margin-top: 20px;
    font-family: 'Montserrat-Medium';
    font-size: 15px;
    max-width: 100px;
    padding: 6px 0px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* color:white; */
    border-radius: 1rem;
}




.modify-recipe-ingredient-column-inner {
    display: flex;
    margin: 0 5px;
}

.modify-recipe-amount-text-field {
    height: 20px !important;
}

.modify-recipe-instructions-header {
    font-size: 20px;
    margin-top: 20px;
    font-family: 'Montserrat-SemiBold';
}


.modify-recipe-instruction-drag-slot {
    height: 20px;
    margin: 20px 40px;
    background-color: rgb(220, 220, 220);
}

.modify-recipe-save-button {
    background: rgb(23, 161, 76) !important;
    color: rgb(248, 248, 248) !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
    margin-right: 10px !important;

}

.modify-recipe-delete-button {
    background: rgb(161, 19, 26) !important;
    color: rgb(248, 248, 248) !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
    margin-left: 10px !important;

}

.modify-recipe-cancel-button {
    margin: 5px 5px 5px 0px !important;
    border: 0 solid #E2E8F0;
    /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
    box-sizing: border-box;
    color: #1A202C;
    display: inline-flex;
    font-family: Inter, sans-serif;
    font-size: 14px;
    /* font-weight: 300; */
    justify-content: center;
    line-height: 24px;
    overflow-wrap: break-word;
    padding: 5px 18px;
    text-decoration: none;
    width: auto;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 6px;
    line-height: 20px;
    background: #b1b1b1;
    color: white;
    font-family: 'Montserrat-Medium';
    text-transform: none !important;


}

@media(min-width:501px) {

    .modify-recipe-exit-button {
        float: right;
        top: 15px;
        padding: 15px 6px !important;
        margin-right: -20px !important;
        margin-top: -20px !important;

        position: sticky !important;
    }

}