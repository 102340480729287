

.calendar-page-side-bar {
  width: 275px;
  box-shadow: 3px 0px 5px -2px rgb(0 0 0 / 20%);
  border-bottom: 5px solid rgb(231, 231, 231);
  display: flex; 
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;

}

.calendar-page-side-bar-collapsed{
  text-align: center;
  width: 45px;
  border-right: #e7e7e7 6px solid;
  background-color: #f9f9f9;
}

.side-bar-expand-button{
  background: #f6f6f6;
  width: 340px;
  height: 45px;
  transform-origin: top left;
  position: absolute;
  left: 0;
  top: calc(50vh + 140px);
  transform: rotate(-90deg);
  font-size: 15px !important;
  letter-spacing: 0.06em !important;
  font-weight: 300 !important;
  text-shadow: 0 1px 1px #ffffff;
  text-transform: none !important;
}


.side-bar-collapse-button{
  align-self:flex-end;
  min-width:20px !important;
}


  .calendar-page-side-bar .calorie-box {
    margin:-20px 2px 0px 2px;
  }

.calendar-page-side-bar .calorie-meal-suggestion-calculator-message{
  color:dimgrey;
  font-size: 12px;
  margin:0px 0px;
}

.calendar-side-bar-delivery-service{
 margin-top:40px;
 font-size:14px;
text-align: center;
}

.side-bar-drop-down-button{
  font: inherit !important;
  color: currentColor !important;
  border: 0 !important;
  height: 1.1876em !important;
  margin: 0 3px !important;
  padding: 7px 0 7px !important;
  min-width: 0 !important;
  background: none !important;
  box-sizing: content-box !important;
  animation-name: mui-auto-fill-cancel !important;
  letter-spacing: inherit !important;
  animation-duration: 10ms !important;
  -webkit-tap-highlight-color: transparent !important;
  text-transform: none !important;
}

.side-bar-drop-down-button:before{
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
}

.side-bar-drop-down-button:hover:before{
  border-bottom: 2px solid black
}

.calendar-page-side-bar .MuiOutlinedInput-adornedEnd{
  padding-right: 4px !important;
}
