/* RecipeCookingPlanner.css */
.recipe-cooking-planner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  margin-top: 20px;
  color: #333;
}

.cooking-recipe-list {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  height: calc(100dvh - 200px);
  overflow-y: auto;
}

.recipe {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin: 10px 0;
  width: 300px;
  background-color: #f9f9f9;
}

.recipe h3 {
  margin-top: 0;
  color: #333;
}

.recipe p {
  color: #666;
}