.login-frame {
  margin: 20px auto;
  text-align: center;
  border-radius: 12px;
  padding: 24px 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  width: 320px;  /* Set fixed width for desktop */
  max-width: calc(100vw - 32px);  /* Responsive for mobile */
  background: white;
}

.login-header {
  font-family: "Montserrat-SemiBold", Helvetica;
  font-size: 24px;
  margin-bottom: 20px;
  color: #162e16;
}

.login-form-line-item {
  padding: 8px 0;
}

.login-button {
  width: 100%;
  height: 48px;
  background-color: #2D7933 !important;
  color: white !important;
  border-radius: 24px !important;
  font-family: "Montserrat-SemiBold", Helvetica !important;
  font-size: 16px !important;
  text-transform: none !important;
  margin: 8px 0 !important;
}

.login-google-button {
  width: 100%;
  background-color: white !important;
  color: #1a73e8 !important;
  margin: 8px 0 !important;
  border-radius: 24px !important;
  text-transform: none !important;
  padding: 8px 16px !important;
  font-weight: 500 !important;
  height: 48px !important;
  font-size: 16px !important;
  border: 1px solid #1a73e8 !important;
}

.login-apple-button {
  width: 100%;
  background-color: black !important;
  color: white !important;
  margin: 8px 0 !important;
  border-radius: 24px !important;
  text-transform: none !important;
  padding: 8px 16px !important;
  font-weight: 500 !important;
  height: 48px !important;
  font-size: 16px !important;
}

.login-form-input-wide .MuiOutlinedInput-root {
  border-radius: 12px;
}

.login-form-input-wide input {
  font-size: 16px !important;
  padding: 16px !important;
  width: 100% !important;
}

.login-form-divider {
  display: flex;
  align-items: center;
  margin: 16px 0;
  color: #666;
  font-size: 14px;
}

.login-form-divider::before,
.login-form-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
  margin: 0 16px;
}

.login-form-switch {
  margin-top: 16px;
  font-size: 14px;
  color: #666;
}

.login-form-switch a {
  color: #2D7933;
  text-decoration: none;
  font-weight: 500;
}

.login-facebook-button {
  background-color: #405A9A !important;
  color: white !important;
  margin: 10px !important;
}

.google-logo {
  width: 18px;
  height: 18px;
  margin-right: 1px;
  border-radius: 50%;
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  color: #2D7933;
}

.login-google-button {
  border: 1px solid #ddd !important;
  color: #333 !important;
  background-color: white !important;
}

.login-google-button:hover {
  background-color: #f8f8f8 !important;
}

@media(max-width:742px) {
  .login-form-input-wide input {
    font-size: 14px !important;
    width: 230px !important;
    padding: 13.5px 14px !important;
  }
  .login-form-input-wide label {
    font-size: 14px !important;
  }

  .login-form-input-wide .MuiInputLabel-outlined{
    transform: translate(14px, 15px) scale(1) !important; 
  }

  .login-form-input-wide .MuiInputLabel-outlined.MuiInputLabel-shrink{
    transform: translate(14px, -6px) scale(0.75) !important;
  }
}

/* Override browser autofill styles */
.login-form-input-wide input:-webkit-autofill,
.login-form-input-wide input:-webkit-autofill:hover,
.login-form-input-wide input:-webkit-autofill:focus,
.login-form-input-wide input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #000 !important;
    font-family: "Montserrat", Helvetica !important;
    transition: background-color 5000s ease-in-out 0s;
}

/* For Firefox */
.login-form-input-wide input:autofill {
    background-color: white !important;
    color: #000 !important;
    font-family: "Montserrat", Helvetica !important;
}

/* For other browsers */
.login-form-input-wide input:-internal-autofill-selected {
    background-color: white !important;
    color: #000 !important;
    font-family: "Montserrat", Helvetica !important;
}

/* Add base input styles */
.login-form-input-wide input {
    font-family: "Montserrat", Helvetica !important;
}