.home-mobile {
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.home-mobile .div {
    background-color: #f9f9f9;
    height: 6766px;
    overflow: hidden;
    position: relative;
    width: 390px;
}

.home-mobile .ellipse {
    height: 842px;
    left: 945px;
    position: absolute;
    top: 5900px;
    width: 842px;
}

.home-mobile .overlap-group {
    height: 5925px;
    left: -8px;
    position: absolute;
    top: 0;
    width: 398px;
}

.home-mobile .rectangle {
    height: 5925px;
    left: 8px;
    position: absolute;
    top: 0;
    width: 390px;
}

.home-mobile .plan-your-meals-for {
    color: #162e16;
    font-family: "Montserrat", Helvetica;
    font-size: 30px;
    font-weight: 600;
    left: 30px;
    letter-spacing: 0;
    line-height: 33px;
    position: absolute;
    text-align: center;
    top: 32px;
    width: 345px;
}

.home-mobile .text-wrapper {
    color: #020e27;
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 110px;
    left: 50px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    text-align: center;
    top: 130px;
    width: 305px;
}

.home-mobile .group {
    height: 65px;
    left: 324px;
    position: absolute;
    top: 10px;
    width: 66px;
}

.home-mobile .img {
    height: 30px;
    left: 125px;
    position: absolute;
    top: 340px;
    width: 147px;
}

.landing-mobile-appstore-badge-bottom {
    height: 40px;
    left: 50px;
    position: absolute;
    top: 6590px;
  }
  
  .landing-mobile-playstore-badge-bottom {
    height: 40px;
    left: 210px;
    position: absolute;
    top: 6590px;
  }

.home-mobile .img-bottom {
    height: 36px;
    left: 132px;
    position: absolute;
    top: 6590px;
    width: 127px;
}

.home-mobile .text-wrapper-2-bottom {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 12.6px;
    font-weight: 500;
    left: 149px;
    letter-spacing: 0;
    position: absolute;
    top: 6599px;
    white-space: nowrap;
    width: 123px;
}

.home-mobile .arrow-bottom {
    height: 12px;
    left: 224px;
    position: absolute;
    top: 6602px;
    width: 18px;
}



.home-mobile .text-wrapper-2 {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 12.6px;
    font-weight: 500;
    left: 146px;
    letter-spacing: 0;
    position: absolute;
    top: 347px;
    white-space: nowrap;
    width: 123px;
}

.home-mobile .arrow {
    height: 12px;
    left: 234px;
    position: absolute;
    top: 348px;
    width: 18px;
}

.home-mobile .image {
    height: 28px;
    left: 324px;
    object-fit: cover;
    position: absolute;
    top: 277px;
    width: 29px;
}

.home-mobile .group-2 {
    height: 183px;
    left: 329px;
    position: absolute;
    top: 114px;
    width: 72px;
}

.home-mobile .group-3 {
    height: 263px;
    left: 8px;
    position: absolute;
    top: 45px;
    width: 115px;
}

.home-mobile .text-wrapper-3 {
    color: #162e16;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 25px;
    font-weight: 500;
    height: 101px;
    left: 35px;
    letter-spacing: -0.08px;
    line-height: 33px;
    position: absolute;
    top: 615px;
    width: 332px;
}

.home-mobile .text-wrapper-4 {
    color: #162e16;
    font-family: "Montserrat", Helvetica;
    font-size: 28px;
    font-weight: 600;
    left: 109px;
    letter-spacing: 0;
    line-height: 31.4px;
    position: absolute;
    text-align: center;
    top: 558px;
}

.home-mobile .text-wrapper-5 {
    color: #939393;
    font-family: "Gotham-Medium", Helvetica;
    font-size: 12.6px;
    font-weight: 500;
    left: 113px;
    letter-spacing: 1.26px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 528px;
    white-space: nowrap;
    width: 182px;
}

.home-mobile .p {
    color: #162e16;
    font-family: "Montserrat", Helvetica;
    font-size: 28px;
    font-weight: 600;
    left: 20px;
    letter-spacing: 0;
    line-height: 33px;
    position: absolute;
    text-align: center;
    top: 2608px;
    width: 366px;
}

.home-mobile .master-your-meal {
    color: #162e16;
    font-family: "Montserrat", Helvetica;
    font-size: 28px;
    font-weight: 600;
    left: 20px;
    letter-spacing: 0;
    line-height: 33px;
    position: absolute;
    text-align: center;
    top: 3228px;
    width: 366px;
}

.home-mobile .select-and-edit {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 14.5px;
    font-weight: 400;
    left: 35px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    top: 683px;
    width: 343px;
}

.home-mobile .text-wrapper-6 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 14.5px;
    font-weight: 400;
    left: 47px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    text-align: center;
    top: 3307px;
    width: 313px;
}

.home-mobile .text-wrapper-7 {
    color: #162e16;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 25px;
    font-weight: 500;
    height: 101px;
    left: 0;
    letter-spacing: -0.08px;
    line-height: 33px;
    position: absolute;
    text-align: right;
    top: 1100px;
    width: 355px;
}

.home-mobile .use-our-built-in {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 14.5px;
    font-weight: 400;
    left: 35px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    top: 1171px;
    width: 343px;
}

.home-mobile .group-4 {
    height: 178px;
    left: 22px;
    position: absolute;
    top: 1355px;
    width: 359px;
}

.home-mobile .prepare-and-enjoy {
    color: #162e16;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 25px;
    font-weight: 500;
    height: 101px;
    left: 34px;
    letter-spacing: -0.08px;
    line-height: 33px;
    position: absolute;
    top: 2100px;
    width: 355px;
}

.home-mobile .text-wrapper-8 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 14.5px;
    font-weight: 400;
    left: 35px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    top: 2170px;
    width: 343px;
}

.home-mobile .group-5 {
    height: 212px;
    left: 29px;
    position: absolute;
    top: 825px;
    width: 348px;
}

.home-mobile .text-wrapper-9 {
    color: #162e16;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 25px;
    font-weight: 500;
    height: 101px;
    left: 35px;
    letter-spacing: -0.08px;
    line-height: 33px;
    position: absolute;
    top: 1600px;
    width: 332px;
}

.home-mobile .text-wrapper-10 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 14.5px;
    font-weight: 400;
    left: 35px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    top: 1660px;
    width: 343px;
}

.home-mobile .group-6 {
    height: 364px;
    left: 11px;
    position: absolute;
    top: 1709px;
    width: 387px;
}

.home-mobile .element {
    height: 251px;
    left: 17px;
    object-fit: cover;
    position: absolute;
    top: 2315px;
    width: 381px;
}

.home-mobile .eat-according-your {
    height: 33px;
    left: 131px;
    position: absolute;
    top: 2734px;
    width: 175px;
}

.home-mobile .save-time-grocery {
    height: 33px;
    left: 221px;
    position: absolute;
    top: 2840px;
    width: 128px;
}

.home-mobile .feel-organized-and {
    height: 33px;
    left: 222px;
    position: absolute;
    top: 2990px;
    width: 134px;
}

.home-mobile .reduce-food-waste {
    height: 33px;
    left: 131px;
    position: absolute;
    top: 3102px;
    width: 188px;
}

.home-mobile .group-7 {
    height: 580px;
    left: 8px;
    position: absolute;
    top: 2607px;
    width: 270px;
}

.home-mobile .craft-your-perfect {
    color: #162e16;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 25px;
    font-weight: 500;
    height: 101px;
    left: 35px;
    letter-spacing: -0.08px;
    line-height: 33px;
    position: absolute;
    top: 3784px;
    width: 332px;
}

.home-mobile .text-wrapper-11 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 35px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    top: 3852px;
    width: 343px;
}

.home-mobile .group-8 {
    height: 367px;
    left: 8px;
    position: absolute;
    top: 3991px;
    width: 390px;
}

.home-mobile .create-and-modify {
    color: #162e16;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 25px;
    font-weight: 500;
    height: 101px;
    left: 35px;
    letter-spacing: -0.08px;
    line-height: 33px;
    position: absolute;
    top: 4405px;
    width: 332px;
}

.home-mobile .create-dishes-that {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 14.5px;
    font-weight: 400;
    left: 35px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    top: 4480px;
    width: 343px;
}

.home-mobile .group-9 {
    height: 384px;
    left: 8px;
    position: absolute;
    top: 4716px;
    width: 390px;
}

.home-mobile .text-wrapper-12 {
    color: #000000;
    font-family: "Quicksand", Helvetica;
    font-size: 12.6px;
    font-weight: 600;
    left: 169px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    text-align: center;
    top: 394px;
}


.landing-mobile-appstore-badge {
    height: 33px;
    left: 74px;
    position: absolute;
    top: 282px;
  }
  
  .landing-mobile-playstore-badge {
    height: 33px;
    left: 200px;
    position: absolute;
    top: 282px;
  }

.home-mobile .group-10 {
    height: 26px;
    left: 74px;
    position: absolute;
    top: 426px;
    width: 258px;
}

.home-mobile .rectangle-2 {
    height: 35px;
    left: 135px;
    object-fit: cover;
    position: absolute;
    top: 465px;
    width: 135px;
}

.home-mobile .text-wrapper-13 {
    color: #162e16;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 25px;
    font-weight: 500;
    height: 101px;
    left: 35px;
    letter-spacing: -0.08px;
    line-height: 33px;
    position: absolute;
    top: 5151px;
    width: 332px;
}

.home-mobile .as-soon-as-you-craft {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 14.5px;
    font-weight: 400;
    left: 35px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    top: 5220px;
    width: 347px;
}

.home-mobile .capa {
    height: 21px;
    left: 35px;
    position: absolute;
    top: 5415px;
    width: 100px;
}

.home-mobile .text {
    height: 14px;
    left: 147px;
    position: absolute;
    top: 5416px;
    width: 92px;
}

.home-mobile .group-11 {
    height: 450px;
    left: 8px;
    position: absolute;
    top: 5461px;
    width: 390px;
}

.home-mobile .rectangle-3 {
    height: 26px;
    left: 249px;
    object-fit: cover;
    position: absolute;
    top: 5412px;
    width: 98px;
}

.home-mobile .rectangle-4 {
    height: 332px;
    left: 41px;
    position: absolute;
    top: 3381px;
    width: 324px;
}

.home-mobile .text-wrapper-14 {
    color: #494949;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 25px;
    font-weight: 500;
    height: 33px;
    left: 93px;
    letter-spacing: -0.08px;
    line-height: 33px;
    position: absolute;
    top: 3535px;
    white-space: nowrap;
}

.home-mobile .frame {
    height: 118px;
    left: 98px;
    position: absolute;
    top: 10385px;
    width: 113px;
}

.home-mobile .overlap {
    background:#3C8241 ;
    background-size: 100% 100%;
    height: 111px;
    position: absolute;
    top: 6700px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.home-mobile .text-wrapper-15 {
    color: #ffffff;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 11px;
    font-weight: 400;
    height: 26px;
    letter-spacing: 0;
    text-align: center;
    width: 379px;
}

.home-mobile .landing-mobile-contact-us {
    color: #ffffff;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 26px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    margin-top: 20px;

}

.home-mobile .vector {
    height: 25px;
    left: 186px;
    position: absolute;
    top: 16px;
    width: 24px;
}

.home-mobile .vector-2 {
    height: 24px;
    left: 138px;
    position: absolute;
    top: 16px;
    width: 23px;
}

.home-mobile .vector-3 {
    height: 27px;
    left: 237px;
    position: absolute;
    top: 15px;
    width: 26px;
}

.home-mobile .a {
    height: 67px;
    left: 84px;
    position: absolute;
    top: 10292px;
    width: 65px;
}

.home-mobile .text-wrapper-16 {
    color: #939393;
    font-family: "Gotham-Medium", Helvetica;
    font-size: 12.6px;
    font-weight: 500;
    left: 104px;
    letter-spacing: 1.26px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 5968px;
    white-space: nowrap;
    width: 182px;
}

.home-mobile .text-wrapper-17 {
    color: #162e16;
    font-family: "Montserrat", Helvetica;
    font-size: 25.3px;
    font-weight: 600;
    left: 12px;
    letter-spacing: 0;
    line-height: 33px;
    position: absolute;
    text-align: center;
    top: 6005px;
    width: 366px;
}

.home-mobile .grilled-salmon {
    height: 147px;
    left: 36px;
    object-fit: cover;
    position: absolute;
    top: 6096px;
    width: 146px;
}

.home-mobile .cauliflower-puree {
    height: 147px;
    left: 212px;
    object-fit: cover;
    position: absolute;
    top: 6096px;
    width: 146px;
}

.home-mobile .BT-shiitake {
    height: 147px;
    left: 36px;
    object-fit: cover;
    position: absolute;
    top: 6251px;
    width: 146px;
}

.home-mobile .sweet-potato-fries {
    height: 147px;
    left: 212px;
    object-fit: cover;
    position: absolute;
    top: 6251px;
    width: 146px;
}

.home-mobile .grilled-chicken {
    height: 147px;
    left: 36px;
    object-fit: cover;
    position: absolute;
    top: 6411px;
    width: 146px;
}

.home-mobile .oatmeal-pancakes {
    height: 147px;
    left: 213px;
    object-fit: cover;
    position: absolute;
    top: 6411px;
    width: 146px;
}

.home-mobile .landing-mobile-video{
    position:absolute;
    top:580px;
    background-color: transparent !important;
    clip-path: inset(1px 1px);


}
