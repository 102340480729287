@media(max-width:500px) {
    .modify-recipe-book-modal{
        width: calc(100vw - 20px);
        height: calc(100vh - 20px);
        height: calc(100dvh - 20px);

    }
}



.modify-recipe-book-top-column{
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 10px;
}


.modify-recipe-row {
    display: flex;
    align-items: center;
    width:99% !important;
    text-align: center !important;
}

.modify-recipe-row-button {
    min-width: 99% !important;
    justify-content: left !important;
}


.modify-recipe-row-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:70%;
}




.modify-recipe-row-button {
    /* margin: 5px !important; */
}

.empty-recipe-book-message{
    text-align: center;
    width: 345px;
    word-break: break-all;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    color:grey;
}

.add-new-recipe-button {
    height: 50px;
    text-transform: uppercase;
}



@media(min-width:501px) {
    .modify-recipe-book-top{
        display:flex;
        flex-direction: row;
    }
    .modify-recipe-book-picture {
        width: 230px;
        object-fit: cover;
        background-position: center center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-size: auto 50px;

    }
    .modify-recipe-row-picture {
        height: 110px;
        width: 110px;
        object-fit: cover;
    }

    .modify-recipe-book-filter-textfield{
        min-width:350px !important;
        margin-bottom: 10px !important;
    }

    .modify-recipe-book-filter-textfield input{
        font-size: 16px !important;
    }

    .modify-recipe-row-name {
        padding: 5px;
        text-transform: none;
    }
    .modify-recipe-row-caloric-info {
        font-size: 10px;
        padding: 5px;
    }
    
}

@media(max-width:500px) {
    .modify-recipe-book-top{
        display:flex;
        flex-direction: row;
        width:300px;
    }

    .modify-recipe-book-picture {
        width: 170px;
        object-fit: cover;
        background-position: center center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-size: auto 50px;
    }
    .modify-recipe-row-picture {
        height: 80px;
        width: 80px;
        object-fit: cover;
    }

    .modify-recipe-book-filter-textfield{
        min-width:300px !important;
        margin-bottom: 10px !important;
    }
    .modify-recipe-row-name {
        padding: 5px;
        font-size: 11px;
    }
    .modify-recipe-row-caloric-info {
        font-size: 8px;
        padding: 5px;
    }
    
}
