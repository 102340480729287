.calorie-box{
    text-align: center;
    
}
.calorie-box-header .MuiInputBase-root{
  margin-left: 5px;
}

.calorie-box .MuiSelect-select.MuiSelect-select{
}

.calorie-box .calorie-box-header, .calorie-box .calorie-meal-suggestion, .calorie-box .MuiFormLabel-root, .calorie-box .MuiInputBase-input, .calorie-box .MuiToggleButton-root.Mui-selected, .calorie-box .MuiTypography-root {
    color:rgb(0, 0, 0) !important;
    
  }
  .calorie-box .MuiToggleButton-root{
  color:rgb(0 0 0 / 58%)
  }
  
.calorie-box-inner{
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}

.calorie-box-header{
margin-top:15px;
    margin-bottom:20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat-SemiBold';
    font-size: 18px;
}

  .calorie-box-text-field{
    width:110px !important;
    margin-bottom: 10px !important;
}


.calorie-box-text-field-wide{
  width:200px !important;
  margin-bottom: 10px !important;
}

.calorie-box-text-field-medium{
  width:160px !important;
  margin-bottom: 10px !important;
}


.calorie-box-label{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding:10.5px;
  width:90px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color:white;
  border-radius: 0px 10px 10px 0px;
  font-family: 'Montserrat-SemiBold';
}

.calorie-box-label-narrow{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding:10.5px;
  width:50px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color:white;
  border-radius: 0px 10px 10px 0px;
  font-family: 'Montserrat-SemiBold';
}


.calorie-box-label-medium{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding:10.5px;
  width:90px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color:white;
  border-radius: 0px 10px 10px 0px;
  font-family: 'Montserrat-SemiBold';
}




.calorie-box .MuiToggleButton-root.Mui-selected{
    background-color: rgba(0, 0, 0, 0.25);
  }

.calorie-box input{
  padding: 12px 12px 10px !important;
}


.calorie-meal-suggestion{
    margin-left: auto;
    margin-right: auto;
    margin-top:15px;
    margin-right: 10px;
    margin-left:10px;
    text-align: center;
    font-size: 14px;
}
.calorie-meal-suggestion-calculator-message{
    padding-top:7px;
    font-size:0.85em;
    justify-self: flex-end;
    color: #666666;

}


  .calorie-meal-suggestion-message-button{
    align-items: center;
    background: #F9F9F9;
    border: 0 solid #E2E8F0;
    /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
    box-sizing: border-box;
    color: #1A202C;
    display: inline-flex;
    font-size: 12px;
    /* font-weight: 300; */
    justify-content: center;
    line-height: 24px;
    overflow-wrap: break-word;
    padding: 4px 8px;
    text-decoration: none;
    width: auto;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 6px;
    font-size: 12px;
    line-height: 20px;
    background: #2D7933;
    color: white;
    font-family: 'Montserrat-Medium';
  }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.macro-breakdown-label{
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  margin-top: -5px;
}

.macro-breakdown-label-lower{
  font-family: 'Montserrat-SemiBold';
  color:grey;
  margin-top: 3px;
}