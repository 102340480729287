.home-page-version-simple {
  background-color: #F7F8F7;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.home-page-version-simple .div {
  background-color: #F7F8F7;
  height: 1032px;
  overflow: hidden;
  position: relative;
  width: 1512px;
}

.home-page-version-simple .page {
  height: 152px;
  left: -7968px;
  position: absolute;
  top: 16317px;
  width: 110px;
}

.home-page-version-simple .text-wrapper {
  color: #162e16;
  font-family: "Montserrat", Helvetica;
  font-size: 50px;
  font-weight: 500;
  left: 611px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  text-align: center;
  top: 946px;
}

.home-page-version-simple .text-wrapper-2 {
  color: #939393;
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-weight: 600;
  left: 610px;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 895px;
  white-space: nowrap;
  width: 289px;
}

.home-page-version-simple .prepare-and-enjoy {
  color: #162e16;
  font-family: "Montserrat", Helvetica;
  font-size: 35px;
  font-weight: 500;
  height: 90px;
  left: 261px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  top: 2183px;
}

.home-page-version-simple .p {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 261px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 2302px;
  width: 420px;
}

.home-page-version-simple .text-wrapper-3 {
  color: #162e16;
  font-family: "Montserrat", Helvetica;
  font-size: 35px;
  font-weight: 500;
  height: 45px;
  left: 894px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  top: 4962px;
  white-space: nowrap;
}

.home-page-version-simple .our-platform-allows {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 894px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 5036px;
  width: 444px;
}

.home-page-version-simple .text-wrapper-4 {
  color: #162e16;
  font-family: "Montserrat", Helvetica;
  font-size: 35px;
  font-weight: 500;
  height: 45px;
  left: 179px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  top: 5561px;
  white-space: nowrap;
}

.home-page-version-simple .as-soon-as-you-ve {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 179px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 5628px;
  width: 440px;
}

.home-page-version-simple .group {
  height: 240px;
  left: 286px;
  position: absolute;
  top: 1070px;
  width: 945px;
}

.home-page-version-simple .text-wrapper-5 {
  color: #162e16;
  font-family: "Montserrat", Helvetica;
  font-size: 35px;
  font-weight: 500;
  height: 124px;
  left: 521px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  top: 21px;
  width: 407px;
}

.home-page-version-simple .text-wrapper-6 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 521px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 100px;
  width: 420px;
}

.home-page-version-simple .img {
  height: 232px;
  left: 0;
  position: absolute;
  top: 0;
  width: 381px;
}

.home-page-version-simple .text-wrapper-7 {
  color: #162e16;
  font-family: "Montserrat", Helvetica;
  font-size: 40px;
  font-weight: 500;
  left: 482px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  text-align: center;
  top: 6040px;
  white-space: nowrap;
}

.home-page-version-simple .text-wrapper-8 {
  color: #939393;
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-weight: 600;
  left: 614px;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 6010px;
  white-space: nowrap;
  width: 289px;
}

.home-page-version-simple .group-2 {
  height: 226px;
  left: 259px;
  position: absolute;
  top: 1452px;
  width: 1027px;
}

.home-page-version-simple .plan-your-week {
  color: #162e16;
  font-family: "Montserrat", Helvetica;
  font-size: 35px;
  font-weight: 500;
  height: 124px;
  left: 0;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  top: 0;
  width: 505px;
}

.home-page-version-simple .text-wrapper-9 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 90px;
  width: 420px;
}

.home-page-version-simple .group-3 {
  height: 219px;
  left: 582px;
  position: absolute;
  top: 7px;
  width: 441px;
}

.home-page-version-simple .element {
  height: 339px;
  left: 765px;
  object-fit: cover;
  position: absolute;
  top: 2150px;
  width: 536px;
}

.home-page-version-simple .frame {
  align-items: center;
  display: inline-flex;
  gap: 16px;
  left: 614px;
  position: absolute;
  top: 7387px;
}

.home-page-version-simple .group-wrapper {
  box-shadow: 0px 8px 24px #121b351a;
  height: 40px;
  position: relative;
  transform: rotate(-180deg);
  width: 40px;
}

.home-page-version-simple .arrow-right-wrapper {
  background-color: #ffffff;
  border-radius: 20px;
  height: 40px;
  position: relative;
}

.home-page-version-simple .arrow-right {
  height: 19px;
  left: 10px;
  position: absolute;
  top: 11px;
  transform: rotate(180deg);
  width: 19px;
}

.home-page-version-simple .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.home-page-version-simple .ellipse {
  background-color: #e7e7e7;
  border-radius: 6px;
  height: 12px;
  position: relative;
  width: 12px;
}

.home-page-version-simple .ellipse-2 {
  background-color: #3c8241;
  border-radius: 6px;
  height: 12px;
  position: relative;
  width: 12px;
}

.home-page-version-simple .div-wrapper {
  box-shadow: 0px 8px 24px #121b351a;
  height: 40px;
  position: relative;
  width: 40px;
}

.home-page-version-simple .arrow-right-2 {
  height: 19px;
  left: 10px;
  position: absolute;
  top: 11px;
  width: 19px;
}

.home-page-version-simple .overlap {
  height: 757px;
  left: 145px;
  position: absolute;
  top: 158px;
  width: 1303px;
}

.home-page-version-simple .plan-your-meals-for {
  color: #162e16;
  font-family: "Montserrat", Helvetica;
  font-size: 55px;
  font-weight: 500;
  height: 130px;
  left: 0;
  letter-spacing: -2px;
  line-height: 65px;
  position: absolute;
  top: 0px;
  width: 420px;
}

.home-page-version-simple .overlap-group {
  height: 757px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1303px;
}

.home-page-version-simple .text-wrapper-10 {
  color: #020e27;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 128px;
  left: 0;
  letter-spacing: 0;
  line-height: 31px;
  position: absolute;
  top: 252px;
  width: 488px;
}

.home-page-version-simple .overlap-2 {
  height: 757px;
  left: 421px;
  position: absolute;
  top: 0;
  width: 882px;
}

.home-page-version-simple .overlap-wrapper {
  height: 133px;
  left: 0;
  position: absolute;
  top: 37px;
  width: 138px;
}

.home-page-version-simple .element-free-wrapper {
  background-image: url(https://c.animaapp.com/DVqGB5xQ/img/vector-6.svg);
  background-size: 100% 100%;
  height: 143px;
  left: -5px;
  position: relative;
  top: -1px;
  width: 145px;
}

.home-page-version-simple .element-free {
  color: #ffffff;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 28.7px;
  font-weight: 500;
  height: 50px;
  left: 35px;
  letter-spacing: -0.99px;
  line-height: 24.7px;
  position: absolute;
  text-align: center;
  top: 46px;
  transform: rotate(4.28deg);
}

.home-page-version-simple .image {
  height: 81px;
  left: 285px;
  object-fit: cover;
  position: absolute;
  top: 341px;
  width: 81px;
}

.home-page-version-simple .image-2 {
  height: 64px;
  left: 688px;
  object-fit: cover;
  position: absolute;
  top: 105px;
  width: 64px;
}

.home-page-version-simple .image-3 {
  height: 52px;
  left: 294px;
  object-fit: cover;
  position: absolute;
  top: 595px;
  width: 52px;
}

.home-page-version-simple .image-4 {
  height: 45px;
  left: 282px;
  object-fit: cover;
  position: absolute;
  top: 50px;
  width: 47px;
}

.home-page-version-simple .image-5 {
  height: 58px;
  left: 311px;
  object-fit: cover;
  position: absolute;
  top: 486px;
  width: 57px;
}

.home-page-version-simple .image-6 {
  height: 84px;
  left: 639px;
  object-fit: cover;
  position: absolute;
  top: 85px;
  width: 58px;
}

.home-page-version-simple .set-of-slice {
  height: 64px;
  left: 745px;
  object-fit: cover;
  position: absolute;
  top: 414px;
  width: 64px;
}

.home-page-version-simple .image-7 {
  height: 104px;
  left: 736px;
  position: absolute;
  top: 145px;
  width: 82px;
}

.home-page-version-simple .image-8 {
  height: 316px;
  left: 607px;
  object-fit: cover;
  position: absolute;
  top: 169px;
  width: 275px;
}

.home-page-version-simple .image-9 {
  height: 303px;
  left: 108px;
  object-fit: cover;
  position: absolute;
  top: 164px;
  width: 221px;
}

.home-page-version-simple .clip-path-group {
  height: 130px;
  left: 674px;
  position: absolute;
  top: 494px;
  width: 99px;
}

.home-page-version-simple .clip-path-group-2 {
  height: 174px;
  left: 113px;
  position: absolute;
  top: 259px;
  width: 94px;
}

.home-page-version-simple .mariana-medvedeva {
  height: 757px;
  left: 95px;
  position: absolute;
  top: 0;
  width: 734px;
}

.home-page-version-simple .group-4 {
  height: 24px;
  left: 300px;
  position: absolute;
  top: 577px;
  width: 149px;
}

.home-page-version-simple .landing-whole-foods {
  height: 40px;
  left: 470px;
  position: absolute;
  top: 573px;
  width: 159px;
}


.home-page-version-simple .landing-appstore-badge {
  height: 42px;
  left: 0px;
  position: absolute;
  top: 486px;
}

.home-page-version-simple .landing-playstore-badge {
  height: 42px;
  left: 170px;
  position: absolute;
  top: 486px;
}

.home-page-version-simple .text-wrapper-11 {
  color: #000000;
  font-family: "Quicksand", Helvetica;
  font-size: 18.4px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 32.1px;
  position: absolute;
  text-align: center;
  top: 575px;
}

.home-page-version-simple .capa {
  height: 38px;
  left: 118px;
  position: absolute;
  top: 576px;
  width: 164px;
}

.home-page-version-simple .overlap-group-wrapper {
  height: 43px;
  left: 0;
  position: absolute;
  top: 495px;
  width: 168px;
}

.home-page-version-simple .overlap-3 {
  height: 43px;
  position: relative;
  width: 170px;
}

.home-page-version-simple .rectangle {
  height: 43px;
  left: 0;
  position: absolute;
  top: 0;
  width: 152px;
}

.home-page-version-simple .group-5 {
  height: 34px;
  left: 21px;
  position: absolute;
  top: 4px;
  width: 149px;
}

.home-page-version-simple .overlap-group-2 {
  height: 34px;
  position: relative;
  width: 147px;
}

.home-page-version-simple .text-wrapper-12 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 500;
  height: 34px;
  left: 0;
  letter-spacing: 0;
  line-height: 35px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 147px;
}

.home-page-version-simple .arrow {
  height: 15px;
  left: 97px;
  position: absolute;
  top: 10px;
  width: 22px;
}

.home-page-version-simple .group-6 {
  height: 43px;
  left: 1054px;
  position: absolute;
  top: 65px;
  width: 176px;
}

.home-page-version-simple .overlap-4 {
  height: 43px;
  position: relative;
}

.home-page-version-simple .rectangle-2 {
  height: 43px;
  left: 4px;
  position: absolute;
  top: 0;
  width: 172px;
}

.home-page-version-simple .group-7 {
  height: 34px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 158px;
}

.home-page-version-simple .overlap-group-3 {
  height: 34px;
  position: relative;
  width: 157px;
}

.home-page-version-simple .text-wrapper-13 {
  color: #3c8241;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 500;
  height: 34px;
  left: 0;
  letter-spacing: 0;
  line-height: 35px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 147px;
}

.home-page-version-simple .arrow-2 {
  height: 15px;
  left: 135px;
  position: absolute;
  top: 10px;
  width: 22px;
}

.home-page-version-simple .group-8 {
  background-image: url(https://c.animaapp.com/DVqGB5xQ/img/rectangle-73.svg);
  background-size: 100% 100%;
  height: 43px;
  left: 1249px;
  position: absolute;
  top: 65px;
  width: 118px;
}

.home-page-version-simple .group-8-account {
  background-image: url(https://c.animaapp.com/DVqGB5xQ/img/rectangle-73-1.svg);
  background-size: 100% 100%;
  height: 43px;
  left: 1249px;
  position: absolute;
  top: 65px;
  width: 148px;
}

.home-page-version-simple .text-wrapper-14-account {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 500;
  height: 35px;
  left: 0;
  letter-spacing: 0;
  line-height: 35px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 109px;
}

.home-page-version-simple .arrow-3-account {
  height: 15px;
  left: 85px;
  position: absolute;
  top: 11px;
  width: 19px;
}


.home-page-version-simple .group-9 {
  height: 35px;
  left: 23px;
  position: relative;
  top: 4px;
  width: 80px;
}


.home-page-version-simple .group-9-account {
  height: 35px;
  left: 23px;
  position: relative;
  top: 4px;
  width: 120px;
}

.home-page-version-simple .text-wrapper-14 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 500;
  height: 35px;
  left: 0;
  letter-spacing: 0;
  line-height: 35px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 49px;
}


.home-page-version-simple .arrow-3 {
  height: 15px;
  left: 60px;
  position: absolute;
  top: 11px;
  width: 19px;
}

.home-page-version-simple .group-10 {
  height: 413px;
  left: 238px;
  position: absolute;
  top: 1678px;
  width: 1004px;
}

.home-page-version-simple .order-ingredients {
  color: #162e16;
  font-family: "Montserrat", Helvetica;
  font-size: 33px;
  font-weight: 500;
  height: 124px;
  left: 568px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  top: 124px;
  width: 432px;
}

.home-page-version-simple .text-wrapper-15 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 208px;
  width: 420px;
}

.home-page-version-simple .group-11 {
  height: 413px;
  left: 0;
  position: absolute;
  top: 0;
  width: 443px;
}

.home-page-version-simple .overlap-5 {
  height: 564px;
  left: 735px;
  position: absolute;
  top: 5487px;
  width: 777px;
}

.home-page-version-simple .overlap-6 {
  height: 564px;
  left: 0;
  position: absolute;
  top: 0;
  width: 777px;
}

.home-page-version-simple .clip-path-group-3 {
  height: 118px;
  left: 694px;
  position: absolute;
  top: 278px;
  width: 64px;
}

.home-page-version-simple .clip-path-group-4 {
  height: 70px;
  left: 603px;
  position: absolute;
  top: 148px;
  width: 52px;
}

.home-page-version-simple .image-10 {
  height: 373px;
  left: 491px;
  object-fit: cover;
  position: absolute;
  top: 63px;
  width: 286px;
}

.home-page-version-simple .image-11 {
  height: 58px;
  left: 629px;
  object-fit: cover;
  position: absolute;
  top: 234px;
  width: 75px;
}

.home-page-version-simple .image-12 {
  height: 89px;
  left: 699px;
  object-fit: cover;
  position: absolute;
  top: 474px;
  width: 78px;
}

.home-page-version-simple .image-13 {
  height: 49px;
  left: 582px;
  object-fit: cover;
  position: absolute;
  top: 19px;
  width: 53px;
}

.home-page-version-simple .image-14 {
  height: 77px;
  left: 705px;
  object-fit: cover;
  position: absolute;
  top: 231px;
  width: 72px;
}

.home-page-version-simple .image-15 {
  height: 63px;
  left: 619px;
  object-fit: cover;
  position: absolute;
  top: 373px;
  width: 67px;
}

.home-page-version-simple .image-16 {
  height: 65px;
  left: 711px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 64px;
}

.home-page-version-simple .group-12 {
  height: 404px;
  left: 0;
  position: absolute;
  top: 32px;
  width: 567px;
}

.home-page-version-simple .overlap-7 {
  border-radius: 15px;
  height: 404px;
  left: 0;
  position: absolute;
  top: 0;
  width: 567px;
}

.home-page-version-simple .group-13 {
  background-color: #f9f9f9;
  background-image: url(https://c.animaapp.com/DVqGB5xQ/img/image-2-1.png);
  background-size: 100% 100%;
  border-radius: 15px;
  box-shadow: 8.96px 6.4px 38.38px #0000001a;
  height: 404px;
  left: 0;
  position: absolute;
  top: 0;
  width: 567px;
}

.home-page-version-simple .group-14 {
  height: 264px;
  left: 129px;
  position: relative;
  top: 48px;
  transform: rotate(-45deg);
  width: 80px;
}

.home-page-version-simple .overlap-group-4 {
  background: linear-gradient(180deg, rgba(255, 122.4, 0, 0.5) 0%, rgba(255, 0, 0, 0) 100%);
  border-radius: 17.7px/16.24px;
  height: 32px;
  width: 35px;
}

.home-page-version-simple .mask-group-wrapper {
  background-color: #ffffff;
  border-radius: 8.38px/7.69px;
  box-shadow: 0px 2.65px 16.55px #0000001a;
  height: 15px;
  left: 9px;
  position: relative;
  top: 10px;
  width: 17px;
}

.home-page-version-simple .mask-group {
  background: linear-gradient(180deg, rgb(255, 0, 0) 0%, rgb(255, 137.7, 0) 100%);
  border-radius: 66.22px;
  height: 7px;
  left: 5px;
  position: relative;
  top: 4px;
  width: 7px;
}

.home-page-version-simple .group-15 {
  background-color: #ffffff;
  height: 130px;
  left: 112px;
  position: absolute;
  top: 274px;
  width: 315px;
}

.home-page-version-simple .group-16 {
  height: 84px;
  left: 21px;
  position: relative;
  top: 13px;
  width: 273px;
}

.home-page-version-simple .group-17 {
  height: 84px;
}

.home-page-version-simple .group-18 {
  height: 84px;
  position: relative;
  width: 273px;
}

.home-page-version-simple .group-19 {
  height: 34px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 273px;
}

.home-page-version-simple .frame-wrapper {
  align-items: center;
  background-color: #ffffff;
  border: 0.82px solid;
  border-color: #f1f1f5;
  border-radius: 54.81px;
  display: inline-flex;
  flex-direction: column;
  gap: 6.54px;
  justify-content: center;
  left: 198px;
  padding: 9.82px 13.09px;
  position: absolute;
  top: 0;
}

.home-page-version-simple .frame-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.54px;
  position: relative;
}

.home-page-version-simple .vector {
  height: 14.72px;
  position: relative;
  width: 14.72px;
}

.home-page-version-simple .text-wrapper-16 {
  color: #05161b;
  font-family: "DM Sans", Helvetica;
  font-size: 9.8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.home-page-version-simple .text-wrapper-17 {
  color: #05161b;
  font-family: "DM Sans", Helvetica;
  font-size: 19.6px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4px;
}

.home-page-version-simple .group-20 {
  height: 25px;
  left: 0;
  position: absolute;
  top: 59px;
  width: 279px;
}

.home-page-version-simple .frame-4 {
  align-items: flex-start;
  display: inline-flex;
  gap: 6.54px;
  left: 0;
  position: absolute;
  top: 21px;
}

.home-page-version-simple .rectangle-3 {
  background-color: #3b9f43;
  border-radius: 81.81px;
  height: 3.27px;
  position: relative;
  width: 86.71px;
}

.home-page-version-simple .rectangle-4 {
  background: linear-gradient(180deg, rgb(35.42, 170, 73.1) 0%, rgb(224, 224, 224) 61.98%, rgb(224, 224, 224) 100%);
  border-radius: 81.81px;
  height: 3.27px;
  position: relative;
  width: 86.71px;
}

.home-page-version-simple .rectangle-5 {
  background-color: #e0e0e0;
  border-radius: 81.81px;
  height: 3.27px;
  position: relative;
  width: 86.71px;
}

.home-page-version-simple .text-wrapper-18 {
  color: #3b9f43;
  font-family: "DM Sans", Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  left: 7px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.home-page-version-simple .text-wrapper-19 {
  color: #969899;
  font-family: "DM Sans", Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  left: 106px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.home-page-version-simple .text-wrapper-20 {
  color: #969899;
  font-family: "DM Sans", Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  left: 205px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.home-page-version-simple .rectangle-6 {
  height: 88px;
  left: -8001px;
  position: absolute;
  top: 3501px;
  width: 272px;
}

.home-page-version-simple .vector-2 {
  height: 90px;
  left: 70px;
  position: absolute;
  top: 162px;
  width: 187px;
}

.home-page-version-simple .sliced-mushroom {
  height: 105px;
  left: 662px;
  object-fit: cover;
  position: absolute;
  top: 394px;
  width: 115px;
}

.home-page-version-simple .mushroom-slice {
  height: 89px;
  left: 683px;
  object-fit: cover;
  position: absolute;
  top: 135px;
  width: 94px;
}

.home-page-version-simple .ellipse-3 {
  background-color: #ffffff;
  border-radius: 22.97px/21.09px;
  height: 42px;
  left: 236px;
  position: absolute;
  top: 250px;
  transform: rotate(-45deg);
  width: 46px;
}

.home-page-version-simple .image-17 {
  height: 32px;
  left: 242px;
  object-fit: cover;
  position: absolute;
  top: 255px;
  width: 32px;
}

.home-page-version-simple .image-18 {
  height: 55px;
  left: 547px;
  object-fit: cover;
  position: absolute;
  top: 467px;
  width: 62px;
}

.home-page-version-simple .eat-healthier {
  color: #162e16;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 17px;
  font-weight: 500;
  height: 69px;
  left: 310px;
  letter-spacing: 0;
  line-height: 23px;
  position: absolute;
  text-align: right;
  top: 2792px;
  width: 195px;
}

.home-page-version-simple .clip-path-group-5 {
  height: 105px;
  left: 279px;
  position: absolute;
  top: 2548px;
  width: 78px;
}

.home-page-version-simple .overlap-8 {
  height: 331px;
  left: 1232px;
  position: absolute;
  top: 2697px;
  width: 280px;
}

.home-page-version-simple .clip-path-group-6 {
  height: 75px;
  left: 86px;
  position: absolute;
  top: 244px;
  width: 55px;
}

.home-page-version-simple .clip-path-group-7 {
  height: 78px;
  left: 47px;
  position: absolute;
  top: 35px;
  width: 58px;
}

.home-page-version-simple .set-of-slice-2 {
  height: 83px;
  left: 171px;
  object-fit: cover;
  position: absolute;
  top: 215px;
  width: 83px;
}

.home-page-version-simple .image-19 {
  height: 64px;
  left: 134px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 64px;
}

.home-page-version-simple .image-20 {
  height: 48px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 143px;
  width: 48px;
}

.home-page-version-simple .image-21 {
  height: 331px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 269px;
}

.home-page-version-simple .set-of-slice-3 {
  height: 96px;
  left: 198px;
  object-fit: cover;
  position: absolute;
  top: 95px;
  width: 82px;
}

.home-page-version-simple .clip-path-group-8 {
  height: 78px;
  left: 1425px;
  position: absolute;
  top: 3161px;
  width: 58px;
}

.home-page-version-simple .overlap-9 {
  height: 484px;
  left: 0;
  position: absolute;
  top: 2529px;
  width: 258px;
}

.home-page-version-simple .overlap-10 {
  height: 454px;
  left: 0;
  position: absolute;
  top: 0;
  width: 252px;
}

.home-page-version-simple .clip-path-group-9 {
  height: 78px;
  left: 94px;
  position: absolute;
  top: 375px;
  width: 58px;
}

.home-page-version-simple .image-22 {
  height: 55px;
  left: 195px;
  object-fit: cover;
  position: absolute;
  top: 254px;
  width: 55px;
}

.home-page-version-simple .set-of-slice-4 {
  height: 83px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 42px;
}

.home-page-version-simple .image-23 {
  height: 137px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 253px;
  width: 109px;
}

.home-page-version-simple .set-of-slice-5 {
  height: 96px;
  left: 60px;
  object-fit: cover;
  position: absolute;
  top: 97px;
  width: 96px;
}

.home-page-version-simple .image-24 {
  height: 48px;
  left: 182px;
  object-fit: cover;
  position: absolute;
  top: 45px;
  width: 48px;
}

.home-page-version-simple .image-25 {
  height: 375px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 252px;
}

.home-page-version-simple .image-26 {
  height: 35px;
  left: 30px;
  object-fit: cover;
  position: absolute;
  top: 449px;
  width: 35px;
}

.home-page-version-simple .image-27 {
  height: 89px;
  left: 190px;
  object-fit: cover;
  position: absolute;
  top: 388px;
  width: 68px;
}

.home-page-version-simple .image-28 {
  height: 64px;
  left: 420px;
  object-fit: cover;
  position: absolute;
  top: 2536px;
  width: 64px;
}

.home-page-version-simple .set-of-slice-6 {
  height: 83px;
  left: 1398px;
  object-fit: cover;
  position: absolute;
  top: 2611px;
  width: 83px;
}

.home-page-version-simple .image-29 {
  height: 48px;
  left: 964px;
  object-fit: cover;
  position: absolute;
  top: 2540px;
  width: 48px;
}

.home-page-version-simple .image-30 {
  height: 134px;
  left: 1300px;
  object-fit: cover;
  position: absolute;
  top: 2540px;
  width: 92px;
}

.home-page-version-simple .overlap-11 {
  height: 1357px;
  left: 23px;
  position: absolute;
  top: 3306px;
  width: 1489px;
}

.home-page-version-simple .overlap-12 {
  height: 1357px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1489px;
}

.home-page-version-simple .overlap-13 {
  height: 499px;
  left: 685px;
  position: absolute;
  top: 858px;
  width: 801px;
}

.home-page-version-simple .clip-path-group-10 {
  height: 78px;
  left: 561px;
  position: absolute;
  top: 139px;
  width: 58px;
}

.home-page-version-simple .image-31 {
  height: 48px;
  left: 514px;
  object-fit: cover;
  position: absolute;
  top: 247px;
  width: 48px;
}

.home-page-version-simple .image-32 {
  height: 331px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 149px;
  width: 283px;
}

.home-page-version-simple .image-33 {
  height: 117px;
  left: 680px;
  object-fit: cover;
  position: absolute;
  top: 45px;
  width: 121px;
}

.home-page-version-simple .image-34 {
  height: 151px;
  left: 630px;
  position: absolute;
  top: 348px;
  width: 154px;
}

.home-page-version-simple .image-35 {
  height: 88px;
  left: 104px;
  object-fit: cover;
  position: absolute;
  top: 310px;
  width: 47px;
}

.home-page-version-simple .image-36 {
  height: 94px;
  left: 546px;
  object-fit: cover;
  position: absolute;
  top: 271px;
  width: 113px;
}

.home-page-version-simple .group-21 {
  height: 492px;
  left: 190px;
  position: absolute;
  top: 0;
  width: 430px;
}

.home-page-version-simple .overlap-14 {
  background-color: #f9f9f9;
  border-radius: 9.85px;
  box-shadow: 6.89px 4.92px 29.54px #0000001a;
  height: 492px;
  position: relative;
  width: 424px;
}

.home-page-version-simple .overlap-15 {
  height: 168px;
  left: 27px;
  position: absolute;
  top: 301px;
  width: 377px;
}

.home-page-version-simple .text-wrapper-21 {
  color: #b7b7b7;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 5.4px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 22px;
  width: 56px;
}

.home-page-version-simple .group-22 {
  height: 15px;
  left: 88px;
  position: absolute;
  top: 1px;
  width: 81px;
}

.home-page-version-simple .overlap-16 {
  background-color: #2d7933;
  border-radius: 2.31px;
  height: 15px;
  position: relative;
  width: 79px;
}

.home-page-version-simple .text-wrapper-22 {
  color: #ffffff;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 5.3px;
  font-weight: 500;
  left: 6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4px;
  width: 68px;
}

.home-page-version-simple .group-23 {
  height: 135px;
  left: 0;
  position: absolute;
  top: 0;
  width: 377px;
}

.home-page-version-simple .text-wrapper-23 {
  color: #000000;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 9.9px;
  font-weight: 500;
  left: 208px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 81px;
}

.home-page-version-simple .text-wrapper-24 {
  color: #000000;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 9.9px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 81px;
}

.home-page-version-simple .overlap-17 {
  height: 55px;
  left: 207px;
  position: absolute;
  top: 45px;
  width: 171px;
}

.home-page-version-simple .group-24 {
  height: 43px;
  left: 0;
  position: absolute;
  top: 0;
  width: 166px;
}

.home-page-version-simple .div-2 {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 172px;
}

.home-page-version-simple .text-wrapper-25 {
  color: #000000;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 7px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2px;
  width: 28px;
}

.home-page-version-simple .text-wrapper-26 {
  color: #999999;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 7.5px;
  font-weight: 500;
  left: 112px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1px;
  width: 23px;
}

.home-page-version-simple .text-wrapper-27 {
  color: #000000;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 7.5px;
  font-weight: 500;
  left: 145px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 21px;
}

.home-page-version-simple .rectangle-wrapper {
  background-color: #f1f1f1;
  border-radius: 1.24px;
  height: 3px;
  left: 1px;
  position: absolute;
  top: 16px;
  width: 164px;
}

.home-page-version-simple .rectangle-7 {
  background-color: #175f85;
  border-radius: 1.24px;
  height: 3px;
  width: 122px;
}

.home-page-version-simple .ellipse-4 {
  background-color: #9edb1c;
  border-radius: 1.98px/1.9px;
  height: 4px;
  left: 137px;
  position: absolute;
  top: 4px;
  width: 4px;
}

.home-page-version-simple .ellipse-5 {
  background-color: #9edb1c;
  border-radius: 1.98px/1.9px;
  height: 4px;
  left: 137px;
  position: absolute;
  top: 39px;
  width: 4px;
}

.home-page-version-simple .group-25 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 35px;
  width: 171px;
}

.home-page-version-simple .text-wrapper-28 {
  color: #000000;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 7px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3px;
  width: 35px;
}

.home-page-version-simple .text-wrapper-29 {
  color: #999999;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 7.5px;
  font-weight: 500;
  left: 119px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1px;
  width: 14px;
}

.home-page-version-simple .text-wrapper-30 {
  color: #000000;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 7.5px;
  font-weight: 500;
  left: 145px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 19px;
}

.home-page-version-simple .overlap-18 {
  background-color: #f1f1f1;
  border-radius: 1.24px;
  height: 3px;
  left: 0;
  position: absolute;
  top: 17px;
  width: 164px;
}

.home-page-version-simple .rectangle-8 {
  background-color: #81c524;
  border-radius: 1.24px;
  height: 3px;
  width: 39px;
}

.home-page-version-simple .group-26 {
  height: 20px;
  left: 207px;
  position: absolute;
  top: 115px;
  width: 165px;
}

.home-page-version-simple .overlap-19 {
  height: 20px;
  position: relative;
  width: 171px;
}

.home-page-version-simple .group-27 {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 171px;
}

.home-page-version-simple .text-wrapper-31 {
  color: #000000;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 7px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2px;
  width: 16px;
}

.home-page-version-simple .text-wrapper-32 {
  color: #999999;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 7.5px;
  font-weight: 500;
  left: 120px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1px;
  width: 14px;
}

.home-page-version-simple .text-wrapper-33 {
  color: #000000;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 7.5px;
  font-weight: 500;
  left: 148px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 17px;
}

.home-page-version-simple .overlap-group-5 {
  background-color: #f1f1f1;
  border-radius: 1.24px;
  height: 3px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 165px;
}

.home-page-version-simple .rectangle-9 {
  background-color: #ee681c;
  border-radius: 1.24px;
  height: 3px;
  width: 23px;
}

.home-page-version-simple .group-28 {
  height: 120px;
  left: 0;
  position: absolute;
  top: 48px;
  width: 181px;
}

.home-page-version-simple .group-29 {
  height: 120px;
  position: relative;
  width: 193px;
}

.home-page-version-simple .text-wrapper-34 {
  color: #000000;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 6.2px;
  font-weight: 500;
  left: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 5px;
  width: 46px;
}

.home-page-version-simple .text-wrapper-35 {
  color: #82867e;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 6.2px;
  font-weight: 500;
  left: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  width: 80px;
}

.home-page-version-simple .text-wrapper-36 {
  color: #82867e;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 6.2px;
  font-weight: 500;
  left: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 64px;
  width: 93px;
}

.home-page-version-simple .ellipse-6 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 27px;
}

.home-page-version-simple .text-wrapper-37 {
  color: #000000;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 6.2px;
  font-weight: 500;
  left: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 93px;
  width: 41px;
}

.home-page-version-simple .text-wrapper-38 {
  color: #82867e;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 6.2px;
  font-weight: 500;
  left: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 108px;
  width: 105px;
}

.home-page-version-simple .ellipse-7 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 93px;
  width: 27px;
}

.home-page-version-simple .x {
  height: 7px;
  left: 174px;
  position: absolute;
  top: 0;
  width: 7px;
}

.home-page-version-simple .text-wrapper-39 {
  color: #000000;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 6.2px;
  font-weight: 500;
  left: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 49px;
  width: 69px;
}

.home-page-version-simple .ellipse-8 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 49px;
  width: 27px;
}

.home-page-version-simple .x-2 {
  height: 7px;
  left: 174px;
  position: absolute;
  top: 45px;
  width: 7px;
}

.home-page-version-simple .x-3 {
  height: 7px;
  left: 174px;
  position: absolute;
  top: 92px;
  width: 7px;
}

.home-page-version-simple .line {
  height: 1px;
  left: 0;
  position: absolute;
  top: 38px;
  width: 180px;
}

.home-page-version-simple .line-2 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 84px;
  width: 180px;
}

.home-page-version-simple .rectangle-10 {
  height: 175px;
  left: 128px;
  object-fit: cover;
  position: absolute;
  top: 66px;
  width: 172px;
}

.home-page-version-simple .text-wrapper-40 {
  color: #000000;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 9.5px;
  font-weight: 500;
  left: 176px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 23px;
  width: 75px;
}

.home-page-version-simple .text-wrapper-41 {
  color: #000000;
  font-family: "Gotham Rounded-Book", Helvetica;
  font-size: 7.5px;
  font-weight: 400;
  left: 136px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 46px;
  width: 156px;
}

.home-page-version-simple .group-30 {
  height: 15px;
  left: 315px;
  position: absolute;
  top: 248px;
  width: 58px;
}

.home-page-version-simple .overlap-group-6 {
  background-color: #e6efda;
  border-radius: 2.31px;
  height: 15px;
  position: relative;
  width: 56px;
}

.home-page-version-simple .text-wrapper-42 {
  color: #5d8b20;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 5.3px;
  font-weight: 500;
  left: 9px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4px;
  width: 38px;
}

.home-page-version-simple .group-31 {
  height: 15px;
  left: 255px;
  position: absolute;
  top: 248px;
  width: 58px;
}

.home-page-version-simple .text-wrapper-43 {
  color: #5d8b20;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 5.3px;
  font-weight: 500;
  left: 10px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4px;
  width: 35px;
}

.home-page-version-simple .group-32 {
  height: 15px;
  left: 56px;
  position: absolute;
  top: 248px;
  width: 50px;
}

.home-page-version-simple .overlap-20 {
  background-color: #e6efda;
  border-radius: 2.31px;
  height: 15px;
  position: relative;
  width: 48px;
}

.home-page-version-simple .text-wrapper-44 {
  color: #5d8b20;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 5.3px;
  font-weight: 500;
  left: 10px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4px;
  width: 27px;
}

.home-page-version-simple .group-33 {
  height: 15px;
  left: 109px;
  position: absolute;
  top: 248px;
  width: 73px;
}

.home-page-version-simple .overlap-21 {
  background-color: #e6efda;
  border-radius: 2.31px;
  height: 15px;
  position: relative;
  width: 71px;
}

.home-page-version-simple .text-wrapper-45 {
  color: #5d8b20;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 5.3px;
  font-weight: 500;
  left: 10px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4px;
  width: 53px;
}

.home-page-version-simple .group-34 {
  height: 15px;
  left: 184px;
  position: absolute;
  top: 248px;
  width: 68px;
}

.home-page-version-simple .overlap-22 {
  background-color: #e6efda;
  border-radius: 2.31px;
  height: 15px;
  position: relative;
  width: 66px;
}

.home-page-version-simple .text-wrapper-46 {
  color: #5d8b20;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 5.3px;
  font-weight: 500;
  left: 10px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4px;
  width: 45px;
}

.home-page-version-simple .group-35 {
  background-color: #f9f9f9;
  border-radius: 12.79px;
  box-shadow: 8.96px 6.4px 38.38px #0000001a;
  height: 208px;
  left: 37px;
  position: absolute;
  top: 79px;
  width: 263px;
}

.home-page-version-simple .group-36 {
  height: 154px;
  left: 28px;
  position: relative;
  top: 26px;
  width: 209px;
}

.home-page-version-simple .input-field-with {
  height: 26px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 207px;
}

.home-page-version-simple .overlap-23 {
  height: 26px;
  position: relative;
  width: 209px;
}

.home-page-version-simple .dropdown-filled {
  height: 26px;
  left: 0;
  position: absolute;
  top: 0;
  width: 147px;
}

.home-page-version-simple .overlap-group-7 {
  background-color: #ffffff;
  border-radius: 6.1px 0px 0px 6.1px;
  height: 26px;
  position: relative;
  width: 145px;
}

.home-page-version-simple .text-wrapper-47 {
  color: #23262a;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 11.4px;
  font-weight: 500;
  left: 9px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 8px;
  width: 30px;
}

.home-page-version-simple .dropdown-filled-2 {
  height: 26px;
  left: 125px;
  position: absolute;
  top: 0;
  width: 84px;
}

.home-page-version-simple .overlap-24 {
  background-color: #42a34a;
  border-radius: 0px 6.1px 6.1px 0px;
  height: 26px;
  position: relative;
  width: 82px;
}

.home-page-version-simple .text-wrapper-48 {
  color: #ffffff;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 11.4px;
  font-weight: 500;
  left: 6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 9px;
  width: 68px;
}

.home-page-version-simple .overlap-25 {
  height: 26px;
  left: 0;
  position: absolute;
  top: 58px;
  width: 209px;
}

.home-page-version-simple .text-wrapper-49 {
  color: #23262a;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 11.4px;
  font-weight: 500;
  left: 9px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 8px;
  width: 29px;
}

.home-page-version-simple .overlap-26 {
  background-color: #81c524;
  border-radius: 0px 6.1px 6.1px 0px;
  height: 26px;
  position: relative;
  width: 82px;
}

.home-page-version-simple .overlap-27 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 93px;
  width: 209px;
}

.home-page-version-simple .overlap-28 {
  background-color: #25ABFF;
  border-radius: 0px 6.1px 6.1px 0px;
  height: 26px;
  position: relative;
  width: 82px;
}

.home-page-version-simple .input-field-with-2 {
  height: 26px;
  left: 0;
  position: absolute;
  top: 128px;
  width: 207px;
}

.home-page-version-simple .text-wrapper-50 {
  color: #23262a;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 11.4px;
  font-weight: 500;
  left: 9px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 8px;
  width: 24px;
}

.home-page-version-simple .overlap-29 {
  background-color: #ee681c;
  border-radius: 0px 6.1px 6.1px 0px;
  height: 26px;
  position: relative;
  width: 82px;
}

.home-page-version-simple .text-wrapper-51 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 11.4px;
  font-weight: 700;
  left: 27px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.home-page-version-simple .image-37 {
  height: 115px;
  left: 611px;
  object-fit: cover;
  position: absolute;
  top: 129px;
  width: 106px;
}

.home-page-version-simple .overlap-30 {
  height: 826px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1468px;
}

.home-page-version-simple .NUEVA-VERSION {
  height: 826px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1468px;
}

.home-page-version-simple .text-wrapper-52 {
  color: #162e16;
  font-family: "Montserrat", Helvetica;
  font-size: 35px;
  font-weight: 500;
  height: 45px;
  left: 524px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  top: 16px;
  white-space: nowrap;
}

.home-page-version-simple .text-wrapper-53 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 45px;
  left: 428px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  top: 71px;
  white-space: nowrap;
}

.home-page-version-simple .image-38 {
  height: 73px;
  left: 1319px;
  object-fit: cover;
  position: absolute;
  top: 823px;
  width: 100px;
}

.home-page-version-simple .overlap-31 {
  height: 133px;
  left: 1349px;
  position: absolute;
  top: 1071px;
  width: 140px;
}

.home-page-version-simple .image-39 {
  height: 59px;
  left: 82px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 58px;
}

.home-page-version-simple .image-40 {
  height: 98px;
  left: 12px;
  object-fit: cover;
  position: absolute;
  top: 35px;
  width: 108px;
}

.home-page-version-simple .image-41 {
  height: 18px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 31px;
  width: 31px;
}

.home-page-version-simple .text-wrapper-54 {
  color: #162e16;
  font-family: "Montserrat", Helvetica;
  font-size: 35px;
  font-weight: 500;
  height: 45px;
  left: 235px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  top: 1002px;
  white-space: nowrap;
}

.home-page-version-simple .manage-your-meals-by {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 235px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1093px;
  width: 444px;
}

.home-page-version-simple .image-42 {
  height: 89px;
  left: 1165px;
  object-fit: cover;
  position: absolute;
  top: 2570px;
  width: 68px;
}

.home-page-version-simple .image-43 {
  height: 77px;
  left: 1339px;
  object-fit: cover;
  position: absolute;
  top: 3064px;
  width: 95px;
}

.home-page-version-simple .overlap-32 {
  height: 475px;
  left: 520px;
  position: absolute;
  top: 2709px;
  width: 472px;
}

.home-page-version-simple .image-44 {
  height: 390px;
  left: 41px;
  object-fit: cover;
  position: absolute;
  top: 51px;
  width: 389px;
}

.home-page-version-simple .feta-omelette {
  height: 409px;
  left: 33px;
  position: absolute;
  top: 36px;
  width: 406px;
}

.home-page-version-simple .vector-3 {
  height: 475px;
  left: 0;
  position: absolute;
  top: 0;
  width: 472px;
}

.home-page-version-simple .ellipse-9 {
  background-color: #3c8241;
  border-radius: 14.5px;
  height: 29px;
  left: 430px;
  position: absolute;
  top: 110px;
  width: 29px;
}

.home-page-version-simple .ellipse-10 {
  background-color: #3c8241;
  border-radius: 14.5px;
  height: 29px;
  left: 430px;
  position: absolute;
  top: 334px;
  width: 29px;
}

.home-page-version-simple .ellipse-11 {
  background-color: #3c8241;
  border-radius: 14.5px;
  height: 29px;
  left: 12px;
  position: absolute;
  top: 110px;
  width: 29px;
}

.home-page-version-simple .ellipse-12 {
  background-color: #3c8241;
  border-radius: 14.5px;
  height: 29px;
  left: 12px;
  position: absolute;
  top: 334px;
  width: 29px;
}

.home-page-version-simple .save-time-on {
  color: #162e16;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 17px;
  font-weight: 500;
  height: 46px;
  left: 332px;
  letter-spacing: 0;
  line-height: 23px;
  position: absolute;
  text-align: right;
  top: 3034px;
}

.home-page-version-simple .reduce-your-food {
  color: #162e16;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 17px;
  font-weight: 500;
  height: 46px;
  left: 1007px;
  letter-spacing: 0;
  line-height: 23px;
  position: absolute;
  top: 2808px;
}

.home-page-version-simple .text-wrapper-55 {
  color: #162e16;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 17px;
  font-weight: 500;
  height: 66px;
  left: 1015px;
  letter-spacing: 0;
  line-height: 23px;
  position: absolute;
  top: 3029px;
  width: 213px;
}

.home-page-version-simple .vector-4 {
  height: 63px;
  left: -7665px;
  position: absolute;
  top: 5992px;
  width: 84px;
}

.home-page-version-simple .set-of-slice-7 {
  height: 83px;
  left: 6px;
  object-fit: cover;
  position: absolute;
  top: 3074px;
  width: 83px;
}

.home-page-version-simple .text-wrapper-56 {
  color: #162e16;
  font-family: "Montserrat", Helvetica;
  font-size: 40px;
  font-weight: 500;
  left: 527px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  text-align: center;
  top: 2611px;
  white-space: nowrap;
}

.home-page-version-simple .overlap-33 {
  height: 740px;
  left: 0;
  position: absolute;
  top: 7609px;
  width: 1519px;
}

.home-page-version-simple .rectangle-11 {
  background-color: #494949;
  height: 446px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 1512px;
}

.home-page-version-simple .group-37 {
  height: 285px;
  left: 0;
  position: absolute;
  top: 455px;
  width: 1519px;
}

.home-page-version-simple .overlap-34 {
  background-color: #292d26;
  height: 285px;
  position: relative;
  width: 1513px;
}

.home-page-version-simple .form {
  align-items: flex-start;
  display: flex;
  height: 41px;
  left: 983px;
  position: absolute;
  top: 116px;
  width: 387px;
}

.home-page-version-simple .input {
  align-items: flex-start;
  border: 1px solid;
  border-color: #a2d057;
  border-radius: 9999px 0px 0px 9999px;
  display: inline-flex;
  flex: 0 0 auto;
  margin-bottom: -1px;
  padding: 9px 17px 9px 21px;
  position: relative;
}

.home-page-version-simple .div-placeholder {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 2px 120px 3px 0px;
  position: relative;
}

.home-page-version-simple .text-wrapper-57 {
  color: #e5e7eb;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-page-version-simple .button {
  all: unset;
  align-items: center;
  background-color: #a2d057;
  border-radius: 0px 9999px 9999px 0px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.61px;
  margin-bottom: -1px;
  padding: 9px 13px 9px 15px;
  position: relative;
}

.home-page-version-simple .text-wrapper-58 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.home-page-version-simple .svg {
  height: 20px;
  position: relative;
  width: 33px;
}

.home-page-version-simple .text-wrapper-59 {
  color: #ffffff;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 983px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 72px;
  white-space: nowrap;
  width: 250px;
}

.home-page-version-simple .frame-5 {
  align-items: flex-start;
  display: inline-flex;
  gap: 39px;
  left: 460px;
  position: absolute;
  top: 68px;
}

.home-page-version-simple .text-wrapper-60 {
  color: #ffffff;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.home-page-version-simple .text-wrapper-61 {
  color: #ffffff;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 28px;
  left: 715px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-align: center;
  top: 68px;
  white-space: nowrap;
}

.home-page-version-simple .text-wrapper-62 {
  color: #ffffff;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 28px;
  left: 586px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-align: center;
  top: 68px;
  white-space: nowrap;
}

.home-page-version-simple .get-the-freshest {
  color: #ffffff;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 17px;
  font-weight: 500;
  left: 150px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 195px;
}

.home-page-version-simple .group-38 {
  height: 42px;
  left: 150px;
  position: absolute;
  top: 299px;
  width: 440px;
}

.home-page-version-simple .overlap-35 {
  height: 42px;
  position: relative;
}

.home-page-version-simple .div-placeholder-wrapper {
  align-items: flex-start;
  border: 1px solid;
  border-color: #a2d057;
  border-radius: 9999px 0px 0px 9999px;
  display: flex;
  left: 0;
  padding: 9px 17px 9px 21px;
  position: absolute;
  top: 0;
  width: 299px;
}

.home-page-version-simple .text-wrapper-63 {
  color: #e5e7eb;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-page-version-simple .button-2 {
  all: unset;
  background-color: #a2d057;
  border-radius: 0px 9999px 9999px 0px;
  box-sizing: border-box;
  height: 42px;
  left: 297px;
  position: absolute;
  top: 0;
  width: 143px;
}

.home-page-version-simple .text-wrapper-64 {
  color: #ffffff;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 24px;
  left: 10px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.home-page-version-simple .svg-2 {
  height: 20px;
  left: 97px;
  position: absolute;
  top: 11px;
  width: 33px;
}

.home-page-version-simple .text-wrapper-65 {
  color: #f9f9f9;
  font-family: "Montserrat", Helvetica;
  font-size: 40px;
  font-weight: 500;
  height: 65px;
  left: 150px;
  letter-spacing: -1px;
  line-height: 65px;
  position: absolute;
  top: 119px;
  width: 537px;
}

.home-page-version-simple .mask-group-2 {
  height: 467px;
  left: 751px;
  position: absolute;
  top: 0;
  width: 761px;
}

.home-page-version-simple .overlap-36 {
  background-color: #a2d057;
  height: 53px;
  left: 0;
  position: absolute;
  top: 6779px;
  width: 100vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page-version-simple .group-39 {
  text-align: center;
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;
}

.home-page-version-simple .terms-conditions {
  color: #000000;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 160px;
  margin-right:20px;
}

.home-page-version-simple .text-wrapper-66 {
  color: #000000;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 176px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 118px;
  margin-left:40px;
  margin-right:35px;
}

.home-page-version-simple .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  gap: 39px;
  left: 511px;
  position: absolute;
  top: 68px;
}

.home-page-version-simple .text-wrapper-67 {
  color: #3c8241;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.home-page-version-simple .text-wrapper-68 {
  color: #162e16;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  height: 28px;
  left: 760px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-align: center;
  top: 67px;
  white-space: nowrap;
}

.home-page-version-simple .text-wrapper-69 {
  color: #162e16;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  height: 28px;
  left: 633px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-align: center;
  top: 67px;
  white-space: nowrap;
}

.home-page-version-simple .ellipse-13 {
  background-color: #3c8241;
  border-radius: 4px;
  height: 8px;
  left: 547px;
  position: absolute;
  top: 97px;
  width: 8px;
}

.home-page-version-simple .text-wrapper-70 {
  color: #162e16;
  font-family: "Montserrat", Helvetica;
  font-size: 40px;
  font-weight: 500;
  left: 538px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  text-align: center;
  top: 6194px;
  white-space: nowrap;
}

.home-page-version-simple .text-wrapper-71 {
  color: #939393;
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-weight: 600;
  left: 614px;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 6163px;
  white-space: nowrap;
  width: 289px;
}

.home-page-version-simple .frame-7 {
  align-items: center;
  display: inline-flex;
  gap: 16px;
  left: 616px;
  position: absolute;
  top: 6545px;
}

.landing-recipes img {}

.home-page-version-simple .grilled-salmon {
  height: 177px;
  left: 135px;
  object-fit: cover;
  position: absolute;
  top: 6322px;
  width: 176px;
}

.home-page-version-simple .overlap-37 {
  border-radius: 97.67px;
  height: 195px;
  left: 339px;
  position: absolute;
  top: 6310px;
  width: 195px;
}

.home-page-version-simple .oatmeal-pancakes {
  height: 177px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 12px;
  width: 176px;
}

.home-page-version-simple .rectangle-12 {
  background-color: #001301;
  border-radius: 97.67px;
  height: 195px;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 195px;
}

.home-page-version-simple .oat-meal-pancakes {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 24.9px;
  font-weight: 500;
  height: 50px;
  left: 45px;
  letter-spacing: 0;
  line-height: 24.9px;
  position: absolute;
  text-align: center;
  top: 72px;
}

.home-page-version-simple .zucchini-wedges {
  height: 177px;
  left: 563px;
  object-fit: cover;
  position: absolute;
  top: 6322px;
  width: 176px;
}

.home-page-version-simple .grilled-chicken {
  height: 177px;
  left: 777px;
  object-fit: cover;
  position: absolute;
  top: 6322px;
  width: 176px;
}

.home-page-version-simple .steak-saltado {
  height: 177px;
  left: 991px;
  object-fit: cover;
  position: absolute;
  top: 6322px;
  width: 176px;
}

.home-page-version-simple .grilled-asparagus {
  height: 177px;
  left: 1206px;
  object-fit: cover;
  position: absolute;
  top: 6322px;
  width: 176px;
}

.home-page-version-simple .grilled-salmon-2 {
  height: 236px;
  left: 216px;
  object-fit: cover;
  position: absolute;
  top: 6138px;
  width: 234px;
}

.home-page-version-simple .grilled-salmon-2-hover {
  height: 215px;
  left: 226px;
  object-fit: cover;
  position: absolute;
  top: 6146px;
  width: 216px;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  z-index: 100;
  background:#0000005c;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-size: 24px;
  font-family: "Montserrat", Helvetica;

}

.grilled-salmon-2-hover:hover {
  opacity: 1;
}

.home-page-version-simple .cauliflower-puree-hover {
  height: 215px;
  left: 508px;
  object-fit: cover;
  position: absolute;
  top: 6146px;
  width: 216px;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  z-index: 100;
  background:#0000005c;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-size: 24px;
  font-family: "Montserrat", Helvetica;

}

.cauliflower-puree-hover:hover {
  opacity: 1;
}


.home-page-version-simple .oatmeal-pancakes-hover {
  height: 215px;
  left: 791px;
  object-fit: cover;
  position: absolute;
  top: 6146px;
  width: 216px;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  z-index: 100;
  background:#0000005c;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-size: 24px;
  font-family: "Montserrat", Helvetica;

}

.oatmeal-pancakes-hover:hover {
  opacity: 1;
}

.home-page-version-simple .zucchini-wedges-hover {
  height: 215px;
  left: 1075px;
  object-fit: cover;
  position: absolute;
  top: 6146px;
  width: 216px;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  z-index: 100;
  background:#0000005c;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-size: 24px;
  font-family: "Montserrat", Helvetica;

}

.zucchini-wedges-hover:hover {
  opacity: 1;
}

.home-page-version-simple .cauliflower-puree {
  height: 236px;
  left: 499px;
  object-fit: cover;
  position: absolute;
  top: 6138px;
  width: 234px;
}

.home-page-version-simple .overlap-38 {
  background-image: url(https://c.animaapp.com/DVqGB5xQ/img/oatmeal-pancakes@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 236px;
  left: 782px;
  position: absolute;
  top: 6138px;
  width: 234px;
}



.home-page-version-simple .oat-meal-pancakes-2 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 24.9px;
  font-weight: 500;
  height: 55px;
  left: 59px;
  letter-spacing: 0;
  line-height: 24.9px;
  position: absolute;
  text-align: center;
  top: 83px;
  width: 116px;
}


.home-page-version-simple .zucchini-wedges-2 {
  height: 236px;
  left: 1066px;
  object-fit: cover;
  position: absolute;
  top: 6138px;
  width: 234px;
}
.home-page-version-simple .BT-shiitake-hover {
  height: 215px;
  left: 225px;
  object-fit: cover;
  position: absolute;
  top: 6392px;
  width: 216px;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  z-index: 100;
  background:#0000005c;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-size: 24px;
  font-family: "Montserrat", Helvetica;

}

.BT-shiitake-hover:hover {
  opacity: 1;
}

.home-page-version-simple .sweet-potato-fries-hover {
  height: 215px;
  left: 508px;
  object-fit: cover;
  position: absolute;
  top: 6392px;
  width: 216px;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  z-index: 100;
  background:#0000005c;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-size: 24px;
  font-family: "Montserrat", Helvetica;

}

.sweet-potato-fries-hover:hover {
  opacity: 1;
}


.home-page-version-simple .sauteed-shrimp-hover {
  height: 215px;
  left: 791px;
  object-fit: cover;
  position: absolute;
  top: 6392px;
  width: 216px;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  z-index: 100;
  background:#0000005c;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-size: 24px;
  font-family: "Montserrat", Helvetica;

}

.sauteed-shrimp-hover:hover {
  opacity: 1;
}

.home-page-version-simple .feta-omelet-hover {
  height: 215px;
  left: 1075px;
  object-fit: cover;
  position: absolute;
  top: 6392px;
  width: 216px;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  z-index: 100;
  background:#0000005c;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-size: 24px;
  font-family: "Montserrat", Helvetica;

}

.feta-omelet-hover:hover {
  opacity: 1;
}

.home-page-version-simple .BT-shiitake {
  height: 236px;
  left: 216px;
  object-fit: cover;
  position: absolute;
  top: 6386px;
  width: 234px;
}

.home-page-version-simple .sweet-potato-fries {
  height: 236px;
  left: 499px;
  object-fit: cover;
  position: absolute;
  top: 6386px;
  width: 234px;
}

.home-page-version-simple .sauteed-shrimp {
  height: 236px;
  left: 782px;
  object-fit: cover;
  position: absolute;
  top: 6386px;
  width: 234px;
}

.home-page-version-simple .feta-omelette-2 {
  height: 236px;
  left: 1066px;
  object-fit: cover;
  position: absolute;
  top: 6386px;
  width: 234px;
}

.home-page-version-simple .set-of-slice-8 {
  height: 83px;
  left: -6633px;
  object-fit: cover;
  position: absolute;
  top: 7600px;
  width: 83px;
}

.home-page-version-simple .overlap-39 {
  height: 485px;
  left: 0;
  position: absolute;
  top: 4904px;
  width: 844px;
}

.home-page-version-simple .image-45 {
  height: 23px;
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 321px;
  width: 11px;
}

.home-page-version-simple .overlap-40 {
  height: 422px;
  left: 0;
  position: absolute;
  top: 0;
  width: 844px;
}

.home-page-version-simple .image-46 {
  height: 99px;
  left: 71px;
  object-fit: cover;
  position: absolute;
  top: 56px;
  width: 96px;
}

.home-page-version-simple .image-47 {
  height: 73px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 76px;
}

.home-page-version-simple .image-48 {
  height: 83px;
  left: 233px;
  object-fit: cover;
  position: absolute;
  top: 339px;
  width: 49px;
}

.home-page-version-simple .group-40 {
  background-image: url(https://c.animaapp.com/DVqGB5xQ/img/recipe-detail-page---tags@1.5x.png);
  background-size: 100% 100%;
  box-shadow: 7.92px 5.65px 33.89px #0000001a;
  height: 379px;
  left: 115px;
  position: absolute;
  top: 3px;
  width: 691px;
}

.home-page-version-simple .capa-2 {
  height: 83px;
  left: 701px;
  overflow: hidden;
  position: absolute;
  top: 53px;
  transform: rotate(-9.23deg);
  width: 137px;
}

.home-page-version-simple .overlap-41 {
  height: 96px;
  left: 2px;
  position: relative;
  top: -6px;
  width: 132px;
}

.home-page-version-simple .vector-5 {
  height: 75px;
  left: 7px;
  position: absolute;
  top: 10px;
  transform: rotate(9.23deg);
  width: 119px;
}

.home-page-version-simple .vector-6 {
  height: 9px;
  left: 99px;
  position: absolute;
  top: 43px;
  transform: rotate(9.23deg);
  width: 18px;
}

.home-page-version-simple .vector-7 {
  height: 9px;
  left: 15px;
  position: absolute;
  top: 43px;
  transform: rotate(9.23deg);
  width: 18px;
}

.home-page-version-simple .mix-match-wrapper {
  height: 35px;
  left: 41px;
  position: absolute;
  top: 32px;
  width: 52px;
}

.home-page-version-simple .mix-match {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 17.3px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 17px;
  position: absolute;
  text-align: center;
  top: 0;
}

.home-page-version-simple .vector-8 {
  height: 78px;
  left: 5px;
  position: absolute;
  top: 9px;
  transform: rotate(9.23deg);
  width: 121px;
}

.home-page-version-simple .image-49 {
  height: 35px;
  left: 21px;
  object-fit: cover;
  position: absolute;
  top: 153px;
  width: 43px;
}

.home-page-version-simple .image-50 {
  height: 81px;
  left: 37px;
  object-fit: cover;
  position: absolute;
  top: 228px;
  width: 68px;
}

.home-page-version-simple .image-51 {
  height: 41px;
  left: 64px;
  object-fit: cover;
  position: absolute;
  top: 341px;
  width: 40px;
}

.home-page-version-simple .image-52 {
  height: 21px;
  left: 71px;
  object-fit: cover;
  position: absolute;
  top: 182px;
  width: 34px;
}

.home-page-version-simple .image-53 {
  height: 77px;
  left: 27px;
  object-fit: cover;
  position: absolute;
  top: 408px;
  width: 80px;
}

.home-page-version-simple .image-54 {
  height: 82px;
  left: 130px;
  object-fit: cover;
  position: absolute;
  top: 400px;
  width: 78px;
}

.home-page-version-simple .image-55 {
  height: 76px;
  left: 144px;
  object-fit: cover;
  position: absolute;
  top: 4811px;
  width: 84px;
}

.home-page-version-simple .image-56 {
  height: 48px;
  left: 43px;
  object-fit: cover;
  position: absolute;
  top: 4826px;
  width: 50px;
}

.home-page-version-simple .image-57 {
  height: 52px;
  left: 1410px;
  position: absolute;
  top: 5432px;
  width: 82px;
}

.home-page-version-simple .image-58 {
  height: 42px;
  left: 158px;
  object-fit: cover;
  position: absolute;
  top: 66px;
  width: 228px;
}

.home-page-version-simple .capa-3 {
  height: 34px;
  left: 179px;
  position: absolute;
  top: 5841px;
  width: 164px;
}

.home-page-version-simple .group-41 {
  height: 24px;
  left: 366px;
  position: absolute;
  top: 5841px;
  width: 149px;
}

.home-page-version-simple .landing-whole-foods-2 {
  height: 38px;
  left: 543px;
  position: absolute;
  top: 5838px;
  width: 149px;
}

.home-page-version-simple .try-it-now-bottom {
    height: 43px;
    left: 680px;
    position: absolute;
    top: 6685px;
    width: 168px;

}

.landing-desktop-video{
  clip-path: inset(1px 1px);
}