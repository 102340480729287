.calendar-meal-carousel {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.calendar-meal-carousel .swiper-container {
  margin-top: 33px !important;
  /* height: 220px; */
}

/* .calendar-meal-carousel .swiper-slide {
  max-width: 188px;
} */

.calendar-meal-carousel-header {
  align-self: flex-start;
}

.calendar-meal-carousel-swiper-container {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar-meal-picker-expand-button {
  display: inline-block;
  font-size: 14px !important;
  letter-spacing: 0.06em !important;
  font-weight: 300 !important;
  text-shadow: 0 1px 1px #FFFFFF;
  text-transform: none !important;
}

.calendar-meal-carousel-top {
  z-index: 200;
  font-size: 16px !important;
  letter-spacing: 0.06em !important;
  font-weight: 300 !important;
  text-shadow: 0 1px 1px #ffffff;
  text-transform: none !important;
  align-self: baseline;
  left: 275px;
  position: absolute !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.calendar-meal-carousel-top-filters-collapsed {
  z-index: 200;
  font-size: 16px !important;
  letter-spacing: 0.06em !important;
  font-weight: 300 !important;
  text-shadow: 0 1px 1px #ffffff;
  text-transform: none !important;
  align-self: baseline;
  margin-left: 5px !important;
  min-width: 200px !important;
  position: absolute !important;
  left: 40px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.calendar-meal-carousel-top-filters-removed {
  z-index: 200;
  font-size: 16px !important;
  letter-spacing: 0.06em !important;
  font-weight: 300 !important;
  text-shadow: 0 1px 1px #ffffff;
  text-transform: none !important;
  align-self: baseline;
  margin-left: 5px !important;
  margin-top: 2px !important;
  min-width: 200px !important;
  position: absolute !important;
  left: 0px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media(min-width:804px) {
  .calendar-meal-carousel-filter-textfield {
    width: 260px !important;
    margin-right: 3px !important;
  }
}

@media(max-width:803px) {
  .calendar-meal-carousel-filter-textfield {
    width: calc(35vw + 40px) !important;
    margin-right: 10px !important;

  }

  .calendar-meal-carousel-filter-textfield input {
    font-size: 16px !important;
    padding: 4px 0px !important;
  }

  .calendar-meal-carousel-collapse-button {
    font-size: 14px !important;
    padding: 0px 3px !important;
    margin-right: 3px !important;

  }
}


.calendar-meal-carousel-collapse-button {
  min-width: 30px !important;
}

.calendar-meal-carousel-collapsed-filters {
  min-height: 230px;
  min-width: 45px;
  border-right: #e7e7e7 6px solid;
}

.calendar-meal-picker-like {
  position: absolute !important;
  right: 4px;
  top: 132px;
  /* font-size: 10px !important; */
  min-width: 24px !important;
  min-height: 24px !important;
  padding: 0px !important;
  background: rgb(255 255 255 / 75%) !important;
}

.calendar-meal-carousel-previous {
  margin-right: 15px;
}

.swiper-lazy-preloader-semicircle{
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  height: 100px; 
  width: 200px; 
  border-radius: 150px 150px 0 0; 
  background-color: green; 
}

.calendar-meal-picker-item {
  width: 155px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar-meal-picker-picture {
  border-radius: 6px;
  height: 145px;
  width: 145px;
  object-fit: cover;
}

.calendar-meal-picker-name {
  padding: 0px 2px 2px 2px;
  font-size: 11px;
  overflow: hidden;
  max-width: 150px;
}

.calendar-meal-picker-caloric-info {
  font-size: 9.5px;
  margin-bottom: 7px;
  margin-top: -2px;
}

@media(max-width:803px) {

  .calendar-meal-picker-item {
    width: 140px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .calendar-meal-picker-picture {
    border-radius: 5px;
    height: 110px;
    width: 110px;
    object-fit: cover;
  }

  .calendar-meal-picker-name {
    padding: 0px 2px 2px 2px;
    font-size: 11px;
    overflow: hidden;
    max-width: 140px;
  }

  .calendar-meal-picker-caloric-info {
    font-size: 8.5px;
  }
}



@media(min-width:804px) {
  .calendar-meal-picker-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px auto;
    font-size: 14px;
  }
}

@media(max-width:803px) {
  .calendar-meal-picker-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto;
    font-size: 14px;
  }
}