/* goal_setting_step.css */

.bt-goal-form {
    padding: 30px 15px;
    max-width: 500px;
    width: calc(100vw - 30px);
    margin: 0 auto;
}

.bt-goal-title {
    font-size: 18px;
    margin-top: -10px;
    margin-bottom: 20px;
    font-family: 'Montserrat-SemiBold';
    text-align: center;
}

.bt-goal-section {
    margin-bottom: 24px;
}

.bt-goal-calories {
    padding: 0 12px;
    margin-top: 12px;
}

.bt-goal-macro-tips {
    margin: 12px 0;
    padding: 12px;
    background: rgba(76, 184, 133, 0.05);
    border-radius: 8px;
}

.bt-goal-macro-tip {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.bt-goal-macro-tip:before {
    content: "•";
    color: rgb(76, 184, 133);
    font-size: 18px;
    margin-right: 8px;
}

.bt-goal-macro-tip:last-child {
    margin-bottom: 0;
}

/* Updated warning styles */
.bt-goal-warning {
    margin: 16px 0;
    padding: 16px;
    background: rgba(255, 152, 0, 0.05);
    border-radius: 8px;
    color: #666;
    font-size: 14px;
}

.bt-goal-warning-title {
    color: rgb(255, 152, 0);
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 15px;
}

.bt-goal-warning-content {
    line-height: 1.5;
}

.bt-goal-warning-item {
    margin: 8px 0;
    padding: 8px 12px;
    border-radius: 6px;
    display: flex;
    align-items: flex-start;
}

.bt-goal-warning-item:before {
    content: "•";
    color: rgb(255, 152, 0);
    font-size: 18px;
    margin-right: 8px;
    line-height: 1.2;
}
/* New recommendations styles */
.bt-goal-recommendations {
    margin: 16px 0;
    padding: 16px;
    background: rgba(76, 184, 133, 0.05);
    border-radius: 8px;
    color: #666;
    font-size: 14px;
}

.bt-goal-recommendations-title {
    color: rgb(76, 184, 133);
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 15px;
}

.bt-goal-recommendations-content {
    white-space: pre-wrap;
    line-height: 1.5;
}

.bt-goal-recommendation-item {
    margin: 8px 0;
    padding: 8px 12px;
    border-radius: 6px;
    display: flex;
    align-items: flex-start;
}

.bt-goal-recommendation-item:before {
    content: "•";
    color: rgb(76, 184, 133);
    font-size: 18px;
    margin-right: 8px;
    line-height: 1.2;
}

.bt-goal-projection {
    margin-top: 24px;
}

.bt-goal-projection-date {
    text-align: center;
    margin-bottom: 16px !important;
    padding: 12px;
    background: rgba(76, 184, 133, 0.05);
    border-radius: 8px;
    font-size: 14px;
    color: #666;
}

.bt-goal-projection-date b {
    color: rgb(76, 184, 133);
}

.bt-goal-buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
}

.bt-goal-back-button {
    padding: 10px 20px;
    border: 2px solid rgb(76, 184, 133);
    background: white;
    color: rgb(76, 184, 133);
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-family: 'Montserrat', sans-serif;
}

.bt-goal-back-button:hover {
    background: rgba(76, 184, 133, 0.05);
}

.bt-goal-submit-button {
    padding: 10px 20px;
    border: none;
    background: rgb(76, 184, 133);
    color: white;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-family: 'Montserrat', sans-serif;
}

.bt-goal-submit-button:hover {
    background: rgba(76, 184, 133, 0.9);
}