.ipad-pro {
  background-color: #F7F8F7;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.ipad-pro .div {
  background-color: #F7F8F7;
  height: 5132px;
  overflow: hidden;
  position: relative;
  width: 1024px;
}

.ipad-pro .text-wrapper {
  color: #162e16;
  font-family: "Recoleta-Medium", Helvetica;
  font-size: 35.4px;
  font-weight: 500;
  left: 409px;
  letter-spacing: 0;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 668px;
}

.ipad-pro .overlap {
  height: 614px;
  left: 78px;
  position: absolute;
  top: 33px;
  width: 923px;
}

.ipad-pro .text-wrapper-2 {
  color: #939393;
  font-family: "Inter", Helvetica;
  font-size: 14.2px;
  font-weight: 600;
  left: 331px;
  letter-spacing: 1.42px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 600px;
  white-space: nowrap;
  width: 205px;
}

.ipad-pro .overlap-group {
  height: 611px;
  left: 0;
  position: absolute;
  top: 0;
  width: 923px;
}

.ipad-pro .plan-your-meals-for {
  color: #162e16;
  font-family: "Montserrat", Helvetica;
  font-size: 35.4px;
  font-weight: 600;
  height: 86px;
  left: 0;
  letter-spacing: -1.42px;
  line-height: normal;
  position: absolute;
  top: 129px;
  width: 283px;
}

.ipad-pro .p {
  color: #020e27;
  font-family: "Montserrat", Helvetica;
  font-size: 14.2px;
  font-weight: 400;
  height: 91px;
  left: 0;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 244px;
  width: 346px;
}

.ipad-pro .group {
  height: 30px;
  left: 644px;
  position: absolute;
  top: 11px;
  width: 125px;
}

.ipad-pro .img {
  height: 30px;
  left: 782px;
  position: absolute;
  top: 11px;
  width: 84px;
}

.ipad-pro .group-2 {
  height: 101px;
  left: 335px;
  position: absolute;
  top: 96px;
  width: 103px;
}

.ipad-pro .image {
  height: 57px;
  left: 500px;
  object-fit: cover;
  position: absolute;
  top: 291px;
  width: 57px;
}

.ipad-pro .image-2 {
  height: 45px;
  left: 786px;
  object-fit: cover;
  position: absolute;
  top: 123px;
  width: 45px;
}

.ipad-pro .image-3 {
  height: 37px;
  left: 507px;
  object-fit: cover;
  position: absolute;
  top: 471px;
  width: 37px;
}

.ipad-pro .image-4 {
  height: 32px;
  left: 499px;
  object-fit: cover;
  position: absolute;
  top: 84px;
  width: 33px;
}

.ipad-pro .image-5 {
  height: 41px;
  left: 519px;
  object-fit: cover;
  position: absolute;
  top: 393px;
  width: 40px;
}

.ipad-pro .image-6 {
  height: 60px;
  left: 751px;
  object-fit: cover;
  position: absolute;
  top: 109px;
  width: 41px;
}

.ipad-pro .set-of-slice {
  height: 45px;
  left: 826px;
  object-fit: cover;
  position: absolute;
  top: 342px;
  width: 45px;
}

.ipad-pro .image-7 {
  height: 73px;
  left: 820px;
  position: absolute;
  top: 152px;
  width: 58px;
}

.ipad-pro .image-8 {
  height: 224px;
  left: 729px;
  object-fit: cover;
  position: absolute;
  top: 169px;
  width: 195px;
}

.ipad-pro .image-9 {
  height: 215px;
  left: 375px;
  object-fit: cover;
  position: absolute;
  top: 165px;
  width: 157px;
}

.ipad-pro .clip-path-group {
  height: 332px;
  left: 774px;
  position: absolute;
  top: 279px;
  width: 75px;
}

.ipad-pro .clip-path-group-2 {
  height: 479px;
  left: 377px;
  position: absolute;
  top: 54px;
  width: 70px;
}

.ipad-pro .mariana-medvedeva {
  height: 536px;
  left: 351px;
  position: absolute;
  top: 40px;
  width: 520px;
}

.ipad-pro .group-3 {
  height: 19px;
  left: 230px;
  position: absolute;
  top: 422px;
  width: 120px;
}

.ipad-pro .text-wrapper-3 {
  color: #000000;
  font-family: "Quicksand", Helvetica;
  font-size: 13px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 22.7px;
  position: absolute;
  text-align: center;
  top: 421px;
}

.ipad-pro .capa {
  height: 27px;
  left: 84px;
  position: absolute;
  top: 422px;
  width: 131px;
}

.ipad-pro .group-4 {
  height: 30px;
  left: 0;
  position: absolute;
  top: 357px;
  width: 119px;
}

.ipad-pro .image-10 {
  height: 30px;
  left: 9px;
  object-fit: cover;
  position: absolute;
  top: 12px;
  width: 162px;
}

.ipad-pro .prepare-and-enjoy {
  color: #162e16;
  font-family: "Recoleta-Medium", Helvetica;
  font-size: 24.8px;
  font-weight: 500;
  height: 64px;
  left: 160px;
  letter-spacing: 0;
  line-height: 31.9px;
  position: absolute;
  top: 1545px;
}

.ipad-pro .overlap-2 {
  height: 1422px;
  left: 0;
  position: absolute;
  top: 1505px;
  width: 1024px;
}

.ipad-pro .overlap-3 {
  height: 1293px;
  left: 0;
  position: absolute;
  top: 129px;
  width: 1024px;
}

.ipad-pro .overlap-4 {
  height: 392px;
  left: 160px;
  position: absolute;
  top: 0;
  width: 294px;
}

.ipad-pro .follow-our-easy-to {
  height: 73px;
  left: 0;
  position: absolute;
  top: 0;
  width: 294px;
}

.ipad-pro .eat-healthier {
  color: #162e16;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  height: 49px;
  left: 35px;
  letter-spacing: 0;
  line-height: 16.3px;
  position: absolute;
  text-align: right;
  top: 343px;
  width: 138px;
}

.ipad-pro .clip-path-group-3 {
  height: 274px;
  left: 10px;
  position: absolute;
  top: 71px;
  width: 60px;
}

.ipad-pro .overlap-5 {
  height: 1128px;
  left: 0;
  position: absolute;
  top: 165px;
  width: 1024px;
}

.ipad-pro .clip-path-group-4 {
  height: 196px;
  left: 908px;
  position: absolute;
  top: 212px;
  width: 43px;
}

.ipad-pro .clip-path-group-5 {
  height: 168px;
  left: 879px;
  position: absolute;
  top: 80px;
  width: 47px;
}

.ipad-pro .clip-path-group-6 {
  height: 168px;
  left: 982px;
  position: absolute;
  top: 384px;
  width: 42px;
}

.ipad-pro .set-of-slice-2 {
  height: 59px;
  left: 970px;
  object-fit: cover;
  position: absolute;
  top: 264px;
  width: 54px;
}

.ipad-pro .image-11 {
  height: 45px;
  left: 944px;
  object-fit: cover;
  position: absolute;
  top: 129px;
  width: 45px;
}

.ipad-pro .image-12 {
  height: 34px;
  left: 849px;
  object-fit: cover;
  position: absolute;
  top: 213px;
  width: 34px;
}

.ipad-pro .image-13 {
  height: 95px;
  left: 897px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 65px;
}

.ipad-pro .image-14 {
  height: 234px;
  left: 856px;
  object-fit: cover;
  position: absolute;
  top: 111px;
  width: 168px;
}

.ipad-pro .set-of-slice-3 {
  height: 68px;
  left: 989px;
  object-fit: cover;
  position: absolute;
  top: 179px;
  width: 35px;
}

.ipad-pro .NUEVA-VERSION {
  height: 585px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 543px;
  width: 1024px;
}

.ipad-pro .image-15 {
  height: 54px;
  left: 925px;
  object-fit: cover;
  position: absolute;
  top: 371px;
  width: 68px;
}

.ipad-pro .text-wrapper-4 {
  color: #162e16;
  font-family: "Recoleta-Medium", Helvetica;
  font-size: 24.8px;
  font-weight: 500;
  height: 32px;
  left: 363px;
  letter-spacing: 0;
  line-height: 31.9px;
  position: absolute;
  top: 554px;
  white-space: nowrap;
}

.ipad-pro .text-wrapper-5 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 11.3px;
  font-weight: 400;
  height: 32px;
  left: 295px;
  letter-spacing: 0;
  line-height: 31.9px;
  position: absolute;
  top: 593px;
  white-space: nowrap;
}

.ipad-pro .overlap-6 {
  height: 378px;
  left: 0;
  position: absolute;
  top: 157px;
  width: 154px;
}

.ipad-pro .clip-path-group-7 {
  height: 168px;
  left: 39px;
  position: absolute;
  top: 210px;
  width: 47px;
}

.ipad-pro .image-16 {
  height: 39px;
  left: 114px;
  object-fit: cover;
  position: absolute;
  top: 180px;
  width: 39px;
}

.ipad-pro .set-of-slice-4 {
  height: 59px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 5px;
}

.ipad-pro .image-17 {
  height: 97px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 179px;
  width: 53px;
}

.ipad-pro .set-of-slice-5 {
  height: 68px;
  left: 18px;
  object-fit: cover;
  position: absolute;
  top: 69px;
  width: 68px;
}

.ipad-pro .image-18 {
  height: 34px;
  left: 104px;
  object-fit: cover;
  position: absolute;
  top: 32px;
  width: 34px;
}

.ipad-pro .image-19 {
  height: 266px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 5px;
  width: 154px;
}

.ipad-pro .image-20 {
  height: 25px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 475px;
  width: 21px;
}

.ipad-pro .image-21 {
  height: 45px;
  left: 273px;
  object-fit: cover;
  position: absolute;
  top: 162px;
  width: 45px;
}

.ipad-pro .overlap-7 {
  height: 118px;
  left: 966px;
  position: absolute;
  top: 156px;
  width: 58px;
}

.ipad-pro .set-of-slice-6 {
  height: 59px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 60px;
  width: 58px;
}

.ipad-pro .frame {
  height: 71px;
  left: 26px;
  position: absolute;
  top: 0;
  width: 32px;
}

.ipad-pro .image-22 {
  height: 34px;
  left: 659px;
  object-fit: cover;
  position: absolute;
  top: 165px;
  width: 34px;
}

.ipad-pro .image-23 {
  height: 63px;
  left: 110px;
  object-fit: cover;
  position: absolute;
  top: 432px;
  width: 48px;
}

.ipad-pro .image-24 {
  height: 63px;
  left: 801px;
  object-fit: cover;
  position: absolute;
  top: 186px;
  width: 48px;
}

.ipad-pro .overlap-8 {
  height: 337px;
  left: 344px;
  position: absolute;
  top: 285px;
  width: 334px;
}

.ipad-pro .image-25 {
  height: 276px;
  left: 29px;
  object-fit: cover;
  position: absolute;
  top: 36px;
  width: 276px;
}

.ipad-pro .feta-omelette {
  height: 290px;
  left: 23px;
  position: absolute;
  top: 25px;
  width: 288px;
}

.ipad-pro .vector {
  height: 337px;
  left: 0;
  position: absolute;
  top: 0;
  width: 334px;
}

.ipad-pro .ellipse {
  height: 21px;
  left: 305px;
  position: absolute;
  top: 78px;
  width: 21px;
}

.ipad-pro .ellipse-2 {
  height: 21px;
  left: 305px;
  position: absolute;
  top: 236px;
  width: 21px;
}

.ipad-pro .ellipse-3 {
  height: 21px;
  left: 8px;
  position: absolute;
  top: 78px;
  width: 21px;
}

.ipad-pro .ellipse-4 {
  height: 21px;
  left: 8px;
  position: absolute;
  top: 236px;
  width: 21px;
}

.ipad-pro .save-time-on {
  color: #162e16;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  height: 33px;
  left: 211px;
  letter-spacing: 0;
  line-height: 16.3px;
  position: absolute;
  text-align: right;
  top: 515px;
}

.ipad-pro .reduce-your-food {
  color: #162e16;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  height: 33px;
  left: 689px;
  letter-spacing: 0;
  line-height: 16.3px;
  position: absolute;
  top: 355px;
}

.ipad-pro .text-wrapper-6 {
  color: #162e16;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  height: 47px;
  left: 695px;
  letter-spacing: 0;
  line-height: 16.3px;
  position: absolute;
  top: 511px;
  width: 151px;
}

.ipad-pro .set-of-slice-7 {
  height: 59px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 543px;
  width: 39px;
}

.ipad-pro .text-wrapper-7 {
  color: #162e16;
  font-family: "Recoleta-Medium", Helvetica;
  font-size: 28.3px;
  font-weight: 500;
  left: 349px;
  letter-spacing: 0;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 215px;
  white-space: nowrap;
}

.ipad-pro .element {
  height: 240px;
  left: 518px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 380px;
}

.ipad-pro .text-wrapper-8 {
  color: #162e16;
  font-family: "Recoleta-Medium", Helvetica;
  font-size: 24.8px;
  font-weight: 500;
  height: 32px;
  left: 608px;
  letter-spacing: 0;
  line-height: 31.9px;
  position: absolute;
  top: 3586px;
  white-space: nowrap;
}

.ipad-pro .our-platform-allows {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 11.3px;
  font-weight: 400;
  left: 608px;
  letter-spacing: 0;
  line-height: 15.6px;
  position: absolute;
  top: 3638px;
  width: 314px;
}

.ipad-pro .overlap-9 {
  height: 1452px;
  left: 0;
  position: absolute;
  top: 3958px;
  width: 1024px;
}

.ipad-pro .text-wrapper-9 {
  color: #162e16;
  font-family: "Recoleta-Medium", Helvetica;
  font-size: 24.8px;
  font-weight: 500;
  height: 32px;
  left: 101px;
  letter-spacing: 0;
  line-height: 31.9px;
  position: absolute;
  top: 74px;
  white-space: nowrap;
}

.ipad-pro .as-soon-as-you-ve {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 11.3px;
  font-weight: 400;
  left: 101px;
  letter-spacing: 0;
  line-height: 15.6px;
  position: absolute;
  top: 125px;
  width: 298px;
}

.ipad-pro .overlap-group-2 {
  height: 1452px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1024px;
}

.ipad-pro .text-wrapper-10 {
  color: #162e16;
  font-family: "Recoleta-Medium", Helvetica;
  font-size: 28.3px;
  font-weight: 500;
  left: 356px;
  letter-spacing: 0;
  line-height: 35.4px;
  position: absolute;
  text-align: center;
  top: 502px;
  white-space: nowrap;
}

.ipad-pro .text-wrapper-11 {
  color: #939393;
  font-family: "Inter", Helvetica;
  font-size: 14.2px;
  font-weight: 600;
  left: 409px;
  letter-spacing: 1.42px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 496px;
  white-space: nowrap;
  width: 205px;
}

.ipad-pro .clip-path-group-8 {
  height: 326px;
  left: 986px;
  position: absolute;
  top: 76px;
  width: 38px;
}

.ipad-pro .clip-path-group-9 {
  height: 151px;
  left: 920px;
  position: absolute;
  top: 54px;
  width: 42px;
}

.ipad-pro .image-26 {
  height: 264px;
  left: 843px;
  object-fit: cover;
  position: absolute;
  top: 45px;
  width: 181px;
}

.ipad-pro .rectangle {
  height: 38px;
  left: 0;
  position: absolute;
  top: 1136px;
  width: 1024px;
}

.ipad-pro .group-5 {
  height: 13px;
  left: 406px;
  position: absolute;
  top: 1148px;
  width: 212px;
}

.ipad-pro .text-wrapper-12 {
  color: #000000;
  font-family: "Gotham Rounded-Medium", Helvetica;
  font-size: 11.3px;
  font-weight: 500;
  height: 13px;
  left: 125px;
  letter-spacing: 0;
  line-height: 19.8px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 84px;
}





.ipad-pro .cauliflower-puree {
  height: 167px;
  left: 328px;
  object-fit: cover;
  position: absolute;
  top: 586px;
  width: 166px;
}

.ipad-pro .oatmeal-pancakes {
  height: 167px;
  left: 529px;
  object-fit: cover;
  position: absolute;
  top: 586px;
  width: 166px;
}

.ipad-pro .zucchini-wedges {
  height: 167px;
  left: 730px;
  object-fit: cover;
  position: absolute;
  top: 586px;
  width: 166px;
}

.ipad-pro .sweet-potato-fries {
  height: 167px;
  left: 328px;
  object-fit: cover;
  position: absolute;
  top: 764px;
  width: 166px;
}

.ipad-pro .sauteed-shrimp {
  height: 167px;
  left: 529px;
  object-fit: cover;
  position: absolute;
  top: 764px;
  width: 166px;
}

.ipad-pro .feta-omelette-2 {
  height: 167px;
  left: 730px;
  object-fit: cover;
  position: absolute;
  top: 764px;
  width: 166px;
}

.ipad-pro .image-27 {
  height: 41px;
  left: 941px;
  object-fit: cover;
  position: absolute;
  top: 166px;
  width: 53px;
}

.ipad-pro .image-28 {
  height: 63px;
  left: 991px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 33px;
}

.ipad-pro .image-29 {
  height: 35px;
  left: 908px;
  object-fit: cover;
  position: absolute;
  top: 14px;
  width: 38px;
}

.ipad-pro .image-30 {
  height: 39px;
  left: 883px;
  object-fit: cover;
  position: absolute;
  top: 331px;
  width: 44px;
}

.ipad-pro .image-31 {
  height: 55px;
  left: 995px;
  object-fit: cover;
  position: absolute;
  top: 164px;
  width: 29px;
}

.ipad-pro .image-32 {
  height: 45px;
  left: 934px;
  object-fit: cover;
  position: absolute;
  top: 265px;
  width: 47px;
}

.ipad-pro .image-33 {
  height: 46px;
  left: 999px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 25px;
}

.ipad-pro .group-6 {
  height: 1452px;
  left: 474px;
  position: absolute;
  top: 0;
  width: 456px;
}

.ipad-pro .vector-2 {
  height: 64px;
  left: 545px;
  position: absolute;
  top: 115px;
  width: 132px;
}

.ipad-pro .sliced-mushroom {
  height: 75px;
  left: 964px;
  object-fit: cover;
  position: absolute;
  top: 279px;
  width: 60px;
}

.ipad-pro .mushroom-slice {
  height: 63px;
  left: 979px;
  object-fit: cover;
  position: absolute;
  top: 96px;
  width: 45px;
}

.ipad-pro .ellipse-5 {
  height: 31px;
  left: 663px;
  position: absolute;
  top: 177px;
  width: 31px;
}

.ipad-pro .image-34 {
  height: 23px;
  left: 667px;
  object-fit: cover;
  position: absolute;
  top: 180px;
  width: 23px;
}

.ipad-pro .group-7 {
  height: 43px;
  left: 433px;
  position: absolute;
  top: 1023px;
  width: 168px;
}

.ipad-pro .grilled-salmon {
  height: 167px;
  left: 128px;
  object-fit: cover;
  position: absolute;
  top: 586px;
  width: 166px;
}

.ipad-pro .BT-shiitake {
  height: 167px;
  left: 128px;
  object-fit: cover;
  position: absolute;
  top: 764px;
  width: 166px;
}

.ipad-pro .capa-2 {
  height: 27px;
  left: 101px;
  position: absolute;
  top: 251px;
  width: 131px;
}

.ipad-pro .group-8 {
  height: 19px;
  left: 248px;
  position: absolute;
  top: 251px;
  width: 120px;
}

.ipad-pro .text-wrapper-13 {
  color: #162e16;
  font-family: "Recoleta-Medium", Helvetica;
  font-size: 24.8px;
  font-weight: 500;
  height: 88px;
  left: 547px;
  letter-spacing: 0;
  line-height: 31.9px;
  position: absolute;
  top: 771px;
  width: 288px;
}

.ipad-pro .text-wrapper-14 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 11.3px;
  font-weight: 400;
  left: 547px;
  letter-spacing: 0;
  line-height: 15.6px;
  position: absolute;
  top: 862px;
  width: 298px;
}

.ipad-pro .group-9 {
  height: 164px;
  left: 178px;
  position: absolute;
  top: 757px;
  width: 270px;
}

.ipad-pro .plan-your-week {
  color: #162e16;
  font-family: "Recoleta-Medium", Helvetica;
  font-size: 24.8px;
  font-weight: 500;
  height: 88px;
  left: 159px;
  letter-spacing: 0;
  line-height: 31.9px;
  position: absolute;
  top: 1027px;
  width: 308px;
}

.ipad-pro .text-wrapper-15 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 11.3px;
  font-weight: 400;
  left: 159px;
  letter-spacing: 0;
  line-height: 15.6px;
  position: absolute;
  top: 1119px;
  width: 298px;
}

.ipad-pro .group-10 {
  height: 155px;
  left: 572px;
  position: absolute;
  top: 1032px;
  width: 312px;
}

.ipad-pro .order-ingredients {
  color: #162e16;
  font-family: "Recoleta-Medium", Helvetica;
  font-size: 24.8px;
  font-weight: 500;
  height: 88px;
  left: 547px;
  letter-spacing: 0;
  line-height: 31.9px;
  position: absolute;
  top: 1275px;
  width: 306px;
}

.ipad-pro .text-wrapper-16 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 11.3px;
  font-weight: 400;
  left: 548px;
  letter-spacing: 0;
  line-height: 15.6px;
  position: absolute;
  top: 1366px;
  width: 298px;
}


.ipad-pro .capa-wrapper {
  background-image: url(images/take-away-rafiki-2.png); 
  background-size: 100% 100%;
  height: 292px;
  left: 144px;
  position: absolute;
  top: 1188px;
  width: 314px;
}

.ipad-pro .capa-3 {
  height: 8px;
  left: 80px;
  position: absolute;
  top: 123px;
  width: 38px;
}

.ipad-pro .overlap-10 {
  height: 398px;
  left: 476px;
  position: absolute;
  top: 2996px;
  width: 548px;
}

.ipad-pro .overlap-11 {
  height: 398px;
  left: 0;
  position: absolute;
  top: 0;
  width: 548px;
}

.ipad-pro .clip-path-group-10 {
  height: 168px;
  left: 395px;
  position: absolute;
  top: 68px;
  width: 47px;
}

.ipad-pro .image-35 {
  height: 34px;
  left: 364px;
  object-fit: cover;
  position: absolute;
  top: 200px;
  width: 34px;
}

.ipad-pro .image-36 {
  height: 234px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 130px;
  width: 201px;
}

.ipad-pro .image-37 {
  height: 83px;
  left: 482px;
  object-fit: cover;
  position: absolute;
  top: 57px;
  width: 66px;
}

.ipad-pro .image-38 {
  height: 107px;
  left: 446px;
  position: absolute;
  top: 271px;
  width: 101px;
}

.ipad-pro .image-39 {
  height: 62px;
  left: 74px;
  object-fit: cover;
  position: absolute;
  top: 245px;
  width: 33px;
}

.ipad-pro .image-40 {
  height: 67px;
  left: 387px;
  object-fit: cover;
  position: absolute;
  top: 217px;
  width: 80px;
}

.ipad-pro .group-11 {
  height: 391px;
  left: 119px;
  position: absolute;
  top: 7px;
  width: 342px;
}

.ipad-pro .group-12 {
  height: 202px;
  left: 5px;
  position: absolute;
  top: 58px;
  width: 241px;
}

.ipad-pro .image-41 {
  height: 81px;
  left: 433px;
  object-fit: cover;
  position: absolute;
  top: 116px;
  width: 75px;
}

.ipad-pro .image-42 {
  height: 52px;
  left: 449px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 71px;
}

.ipad-pro .overlap-12 {
  height: 94px;
  left: 471px;
  position: absolute;
  top: 176px;
  width: 77px;
}

.ipad-pro .image-43 {
  height: 42px;
  left: 58px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 19px;
}

.ipad-pro .image-44 {
  height: 70px;
  left: 9px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 69px;
}

.ipad-pro .image-45 {
  height: 13px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 22px;
  width: 22px;
}

.ipad-pro .vector-3 {
  height: 45px;
  left: -9679px;
  position: absolute;
  top: 4590px;
  width: 60px;
}

.ipad-pro .drag-drop-from {
  height: 755px;
  left: -9996px;
  position: absolute;
  top: 4928px;
  width: 1008px;
}

.ipad-pro .text-wrapper-17 {
  color: #162e16;
  font-family: "Recoleta-Medium", Helvetica;
  font-size: 24.8px;
  font-weight: 500;
  height: 32px;
  left: 157px;
  letter-spacing: 0;
  line-height: 31.9px;
  position: absolute;
  top: 3122px;
  white-space: nowrap;
}

.ipad-pro .manage-your-meals-by {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 11.3px;
  font-weight: 400;
  left: 157px;
  letter-spacing: 0;
  line-height: 15.6px;
  position: absolute;
  top: 3187px;
  width: 314px;
}

.ipad-pro .set-of-slice-8 {
  height: 59px;
  left: -8949px;
  object-fit: cover;
  position: absolute;
  top: 5800px;
  width: 59px;
}

.ipad-pro .overlap-13 {
  height: 410px;
  left: 0;
  position: absolute;
  top: 3479px;
  width: 575px;
}

.ipad-pro .group-13 {
  height: 316px;
  left: 38px;
  position: absolute;
  top: 48px;
  width: 537px;
}

.ipad-pro .group-14 {
  height: 410px;
  left: 0;
  position: absolute;
  top: 0;
  width: 175px;
}

.ipad-pro .image-46 {
  height: 58px;
  left: 67px;
  object-fit: cover;
  position: absolute;
  top: 349px;
  width: 55px;
}

.ipad-pro .image-47 {
  height: 37px;
  left: 974px;
  position: absolute;
  top: 3919px;
  width: 50px;
}

.ipad-pro-top-button .overlap-group-wrapper {
  height: 43px;
  left: 0;
  position: absolute;
  top: 210px;
  width: 168px;
}

.ipad-pro-top-button .overlap-group-wrapper-bottom {
  height: 43px;
  left: 440px;
  position: absolute;
  top: 4850px;
  width: 168px;
}

.ipad-pro-top-button .overlap-3 {
  height: 43px;
  position: relative;
  width: 170px;
}

.ipad-pro-top-button .rectangle {
  height: 43px;
  left: 0;
  position: absolute;
  top: 0;
  width: 132px;
}

.ipad-pro-top-button .group-5 {
  height: 34px;
  left: 21px;
  position: absolute;
  top: 4px;
  width: 129px;
}

.ipad-pro-top-button .overlap-group-2 {
  height: 34px;
  position: relative;
  width: 127px;
}

.ipad-pro-top-button .text-wrapper-12 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 13px;
  font-weight: 500;
  height: 34px;
  left: -7px;
  letter-spacing: 0;
  line-height: 35px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.ipad-pro-top-button  .arrow {
  height: 13px;
  left: 82px;
  position: absolute;
  top: 11px;
  width: 20px;
}

.landing-tablet-appstore-badge {
  height: 36px;
  left: 146px;
  position: absolute;
  top: 343px;
}

.landing-tablet-playstore-badge {
  height: 36px;
  left: 282px;
  position: absolute;
  top: 343px;
}


.ipad-pro-top-right .group-6 {
  height: 43px;
  left: 400px;
  position: absolute;
  top: 0px;
  width: 176px;
}

.ipad-pro-top-right .overlap-4 {
  height: 43px;
  position: relative;
}

.ipad-pro-top-right .rectangle-2 {
  height: 43px;
  left: 4px;
  position: absolute;
  top: 0;
  width: 172px;
}

.ipad-pro-top-right .group-7 {
  height: 34px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 158px;
}

.ipad-pro-top-right .overlap-group-3 {
  height: 34px;
  position: relative;
  width: 157px;
}

.ipad-pro-top-right .overlap-group-3 a {
  height: 34px;
  position: relative;
  width: 157px;
  display: block;
  z-index: 300;
}


.ipad-pro-top-right .text-wrapper-13 {
  color: #3c8241;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 500;
  height: 34px;
  left: 0;
  letter-spacing: 0;
  line-height: 35px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 147px;
}

.ipad-pro-top-right .arrow-2 {
  height: 15px;
  left: 135px;
  position: absolute;
  top: 10px;
  width: 22px;
}

.ipad-pro-top-right .group-8 {
  background-image: url(https://c.animaapp.com/DVqGB5xQ/img/rectangle-73.svg);
  background-size: 100% 100%;
  height: 43px;
  left: 760px;
  position: absolute;
  top: 0px;
  width: 118px;
}

.ipad-pro-top-right .group-8-account {
  background-image: url(https://c.animaapp.com/DVqGB5xQ/img/rectangle-73-1.svg);
  background-size: 100% 100%;
  height: 43px;
  left: 760px;
  position: absolute;
  top: 0px;
  width: 148px;
}

.ipad-pro-top-right .text-wrapper-14-account {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 500;
  height: 35px;
  left: 0;
  letter-spacing: 0;
  line-height: 35px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 109px;
}

.ipad-pro-top-right .arrow-3-account {
  height: 15px;
  left: 85px;
  position: absolute;
  top: 11px;
  width: 19px;
}


.ipad-pro-top-right .group-9 {
  height: 35px;
  left: 23px;
  position: relative;
  top: 4px;
  width: 80px;
}


.ipad-pro-top-right .group-9-account {
  height: 35px;
  left: 23px;
  position: relative;
  top: 4px;
  width: 120px;
}

.ipad-pro-top-right .text-wrapper-14 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 500;
  height: 35px;
  left: 0;
  letter-spacing: 0;
  line-height: 35px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 49px;
}


.ipad-pro-top-right .arrow-3 {
  height: 15px;
  left: 60px;
  position: absolute;
  top: 11px;
  width: 19px;
}

.landing-tablet-video{
  top:250px;
  position: absolute;
  clip-path: inset(1px 1px);
}

.landing-tablet-footer{
  position:absolute;
  top:5070px;
  height:100px;
  background-color: #a2d057;
  width:100%;
  display: flex;
}
.landing-tablet-footer-inner{
  text-align: center;
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;

}

.landing-tablet-footer .terms-conditions{
  color: #000000;
  font-family: "Gotham-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 160px;
  margin-right:20px;
  margin-bottom: 30px;
}