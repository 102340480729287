.navigation {
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    z-index: 1000;
    position: sticky;
    top: 0;
    box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, .2);
    background: #f9f9f9;
  }
  
  .navigation-logo {
    width: 163px;
  }
  
  .navigation-logo-mobile {
    width: 35px;
    margin-left: -2px;
    margin-top: 0px !important;
  }
  
  .navigation-calendar-image {
    width: 18px;
    margin-right: 8px;
  }
  
  .navigation-link {
    text-decoration: none;
    padding: 10px;
    color: #494949;
    letter-spacing: 0.015em !important;
    margin-right: 4px !important;
    font-size: 17px !important;
    display: flex;
    align-items: center;
    font-family: 'Montserrat-Medium';
  }
  
  .navigation-right {
    margin-left: auto;
    display: flex;
  }
  
  .navigation-sign-out-button {
    color: rgb(184, 28, 28) !important;
    margin-left: 10px !important;
  }
  
  .delete-account-confirmation-button {
    background: rgb(196, 0, 0) !important;
    color: rgb(248, 248, 248) !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
    margin-left: 10px !important;
  }
  
  .delete-account-cancel-button {
    background: rgb(230, 230, 230) !important;
    color: rgb(78, 78, 78) !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
    margin-left: 10px !important;
  }
  
  .navigation-button {
    text-decoration: none;
    padding: 10px;
    color: #494949;
    background: transparent;
    border: none;
    letter-spacing: 0.015em !important;
    margin-right: 4px !important;
    font-size: 17px !important;
    display: flex;
    align-items: center;
    font-family: 'Montserrat-Medium';
  }
  
  .navigation-cart {
    margin-left: auto;
    font-size: 16px;
    margin-top: 8px;
  }
  
  .navigation-cart-hover-meal-row {
    display: flex;
    align-items: center;
    padding: 5px 5px 3px 5px;
  }
  
  .navigation-cart-hover-meal-name {
    width: 200px;
    font-size: 14px;
    padding: 10px;
  }
  
  .navigation-cart-hover-meal-picture {
    height: 60px;
    width: 60px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .navigation-cart-hover-meal-quantity {
    margin-right: 10px;
    margin-left: auto;
  }
  
  .navigation-cart-hover-meal-cart-total {
    text-align: end;
    margin-top: -9px;
    padding-right: 15px;
    margin-bottom: 5px;
  }
  
  @media (max-width: 700px) {
    .navigation-link {
      font-size: 13px !important;
      padding: 5px;
    }
  
    .navigation-button {
      font-size: 13px !important;
      padding: 5px;
    }
  
    .navigation-cart {
      font-size: 14px !important;
    }
  
    .navigation-logo {
      width: 106px;
    }
  
    .navigation-calendar-image {
      width: 17px;
      margin-right: 5px;
    }
  }
  
  .navigation-app {
    display: flex;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: #1E6A46;
    z-index: 1000;
    height: 45px;
    box-shadow: -1px -1px 4px -2px rgba(0, 0, 0, .2);
  }
  
  .navigation-app-section {
    width: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  
  .navigation-app-section .MuiBadge-badge {
    background-color: rgb(223, 0, 0);
  }
  
  /* Base styles for navigation-app-link */
  .navigation-app-link {
    text-decoration: none;
    color: white;
    letter-spacing: 0.015em !important;
    font-size: 10px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-family: 'Montserrat-Medium';
    width:100%;
  }
  
  /* Label styles */
  .navigation-label {
    opacity: 0;
    transform: scale(1);
    transition: opacity 0.5s ease, transform 0.1s ease;
    height: 0;
    overflow: hidden;
  }
  
  /* Active label styles */
  .navigation-label.active {
    opacity: 1;
    transform: scale(1.1);
    height: auto;
  }
  
  /* Image styles */
  .navigation-app-calendar-image,
  .navigation-app-calendar-image-inactive {
    width: 18px;
    margin-bottom: 4px;
  }
  
  /* Inactive image filter */
  .navigation-app-calendar-image-inactive {
    filter: grayscale(1) brightness(2);
  }
  