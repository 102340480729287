.modify-recipe-ingredients-search-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: start;
    margin-bottom: 15px !important;
    margin-top:-20px !important;
}


.add-ingredient-dialog .MuiTab-wrapper {
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold' !important;
    margin:0px 0px !important;
    padding: 6px 1px !important;
  }
  

.add-ingredient-dialog{
    width:100vw;
    max-width: 500px;
    height: calc(100vh);
    height: calc(100dvh);
    max-height: 1000px;
    text-align: center;
}

.close-ingredients-dialog-button {
    padding: 0;
}

.recipe-search-ingredient-row{
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
}

.search-ingredient-image-div{
    width: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-right: 10px;
    
}

.search-ingredient-image {
    height: 40px;
    width: auto;
}

.search-ingredient-name{
    width:140px;
    margin-right: 10px;
}


.ingredient-bulk-textfield {
    width: 300px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
