
.extract-recipe-dialog-form {
    margin: 20px;
  }
  
  .extract-recipe-dialog-form-line-item {
      padding: 5px;
      overflow: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .extract-recipe-dialog-form-input-narrow{
      width:120px !important;
    }
    .extract-recipe-dialog-form-submit-button {
      display: flex;
      justify-content: center;
      margin: 10px 0px 15px 0px;
    } 