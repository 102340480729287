
.react-calendar__month-view__days__day {
    color: black !important;
}
.react-calendar__tile:disabled{
    color: #ababab !important;
}

.react-calendar__navigation__label__labelText{
    color: black !important;
}

.react-calendar {
    width: 300px !important;
    color:black !important;
}

.react-calendar__tile {
    border-radius: 50%;
    font-family: 'Montserrat-SemiBold' !important;
}

.react-calendar__tile--now {
    background: white !important;
    color: green !important
}

.react-calendar__tile--active {
    background: #2D7933 !important;
    color: white !important
}


.copy-meals-to-day-button {

    background-color: #2D7933 !important;
    color: white !important;
    border-radius: 3px;
    border: none;
    padding: 6px 30px;
    margin: 15px 0px 0px 0px;
    margin: 5px 5px 5px 0px !important;
    border: 0 solid #E2E8F0;
    /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
    box-sizing: border-box;
    display: inline-flex;
    font-family: Inter, sans-serif;
    font-size: 14px;
    /* font-weight: 300; */
    justify-content: center;
    line-height: 24px;
    overflow-wrap: break-word;
    padding: 5px 18px;
    text-decoration: none;
    width: auto;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 6px;
    line-height: 20px;
    font-family: 'Montserrat-Medium';
    text-transform: none !important;
}
