.meal-picker-filters {
  min-width: 275px;
  max-width: 275px;
  align-self: flex-start;
  justify-self: flex-end;
  padding-top: 5px;
  height: 220px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 10px;
}

.calendar-meal-carousel .meal-picker-filters {

  box-shadow: 3px -2px 5px -2px rgb(0 0 0 / 20%);
  
}

.meal-picker-filters .MuiToggleButton-label{
height:15px;
}

.filters-heading{
  color: #424242;
  text-align: left;
  font-size: 14px !important;
  letter-spacing: 0.06em !important;
  font-weight: 500 !important;
  font-family: 'Montserrat-SemiBold';
  margin: 16px 0px 8px 2px;
}



.meal-picker-primary-filters,
.meal-picker-secondary-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 12px;
}

.meal-picker-filter-button{
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 6px 12px !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  font-family: 'Montserrat-SemiBold' !important;
  transition: all 0.2s ease-in-out !important;
}
