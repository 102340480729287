.calorie-card {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: white;
    border-radius: 10px;
    position: relative;
    width: calc(100% - 10px);
    max-width: 800px;
    overflow: hidden;
    margin-left: 5px;
    margin-right: 5px;
    margin-top:10px;
}

.pie-chart-container {
    width: 150px;
    height: 150px;
    text-align: center;
}

.pie-chart-container .calorie-text {
    font-size: 16px;
    font-weight: bold;
}

.custom-carousel {
    width: 190px;
    position: relative;
    overflow: hidden; /* Hide overflow to keep slides contained */
    max-width: 500px; /* Prevent overflow */
    padding-bottom:12px;
    max-width:40vw;
}

.slide-content {
    display: flex; /* Display slides in a row */
    transition: transform 0.5s ease-in-out; /* Smooth transition */
    width: 100%; /* Adjust width based on the number of slides */

}

.slide {
    width: calc(100% - 20px); /* Each slide takes full width */
    flex-shrink: 0; /* Prevent slides from shrinking */
    display: flex;
    flex-direction: column; /* Align content vertically */
    margin-left:10px;
    margin-right:10px;

}

.progress-bar-container {
    margin-bottom: 10px;
}

.progress-bar-label {
    font-size: 12px;
    color: #333;
}

.progress-bar-value {
    font-size: 11px;
    color: #999;
    margin-left: auto;
    display: flex;
    align-items: center;
}

.calorie-card .progress{
    height:6px;
    width:170px;
    max-width: 100%;
}


.macro-totals-bar {
    text-shadow: none;
    text-transform: none;
    /* width: 30%; */
    height: 10px !important;
    background: #fafafa !important;
    border-radius: 1rem !important;
  
  
  }
  
  .macro-totals-bar-protein {
    background-color: #24C581 !important;
    overflow: visible;
  }
  
  
  
  
  .macro-totals-bar-carbs {
    background-color: #25ABFF !important;
  
  }
  

  
  
  .macro-totals-bar-fats {
    background-color: #FF802C !important;
  }
  

  
  .macro-totals-bar-sodium {
    background-color: #9F48E2 !important;
    overflow: visible;
  }
  

  .macro-totals-bar-sugar {
    background-color: #86D1FF !important;
    overflow: visible;
  }
  

  
  .macro-totals-bar-cholesterol {
    background-color: #E751CF !important;
    overflow: visible;
  }
  

  .macro-totals-bar-fiber {
    background-color: #8D9FFF !important;
    overflow: visible;
  }

  
  
  .macro-totals-bar-net-carbs {
    background-color: #2344BB !important;
    overflow: visible;
  }
  

  
  
  .macro-totals-bar-saturated-fat {
    background-color: #C72100 !important;
    overflow: visible;
  }
  

/* Navigation Controls */
.custom-control-prev,
.custom-control-next {
    position: absolute;
    bottom: -8px; /* Position below the indicators */
    background: none;
    border: none;
    cursor: pointer;
    width: 23px; /* Adjust width for better clickability */
    height: 23px; /* Adjust height for better clickability */
    z-index: 2; /* Ensure buttons are above the content */
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.custom-control-prev:hover,
.custom-control-next:hover {
    opacity: 1;
}

.custom-control-prev {
    left: 5px; /* Position left button */
}

.custom-control-next {
    right: 5px; /* Position right button */
}

/* Navigation Icons */
.custom-control-prev-icon,
.custom-control-next-icon {
    display: inline-block;
    width: 15px; /* Adjust icon size */
    height: 15px; /* Adjust icon size */
    background-size: 100%;
    background-repeat: no-repeat;
}

.custom-control-prev-icon {
    background-image: url('images/previous-arrow.svg'); /* Update with actual path */
    filter: brightness(0.75);
}

.custom-control-next-icon {
    background-image: url('images/next-arrow.svg'); /* Update with actual path */
    filter: brightness(0.75);
}

/* Indicators */
.carousel-indicators {
    position: absolute;
    bottom: 0; /* Position indicators at the bottom */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin: 0;
    list-style: none;
    z-index: 15; /* Ensure indicators are above the slides */
}

.indicator {
    width: 6px; /* Smaller size */
    height: 6px; /* Smaller size */
    background-color: #868686 !important;
    border-radius: 50%;
    margin: 0 3px; /* Spacing between indicators */
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s;
}

.indicator.active {
    opacity: 1;
    background-color: #333; /* Active indicator color */
}

.nutrient-input {
    width: 50px;
    margin-right: 5px;
    text-align: right;
    /* Add any other styling you need */
  }