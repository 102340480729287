/* src/Chat.css */

.ai-chat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 12px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
}

.ai-chat-header {
    text-align: center;
    margin: 24px auto 8px;
    width: 280px;
    font-size: 18px;
    font-family: 'Montserrat-SemiBold';
    color: #2c3e50;
    letter-spacing: 0.5px;
}

.ai-chat-subheader {
    text-align: center;
    margin: 0 auto 20px;
    width: 330px;
    font-size: 14px;
    color: #7f8c8d;
    line-height: 1.5;
}

.ai-chat-threads-wrapper {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #eef2f7;
    margin-bottom: 16px;
    justify-content: space-between;
    background-color: #f8fafc;
}

.ai-chat-threads-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    flex-grow: 1;
    margin-right: 12px;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.ai-chat-threads-container::-webkit-scrollbar {
    display: none;
}

.ai-chat-thread-item {
    display: inline-block;
    margin-right: 8px;
}

.ai-chat-thread {
    display: inline-flex;
    align-items: center;
    background-color: #f1f5f9;
    border: 1px solid #e2e8f0;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 13px;
    transition: all 0.2s ease;
    color: #64748b;
    gap: 8px;
}

.ai-chat-thread:hover {
    background-color: #e2e8f0;
    transform: translateY(-1px);
    border-color: #cbd5e1;
}

.ai-chat-thread.active {
    background-color: rgb(76, 184, 133);
    color: #ffffff;
    border-color: rgb(76, 184, 133);
    box-shadow: 0 2px 4px rgba(76, 184, 133, 0.2);
    font-weight: 500;
}

.ai-chat-thread button {
    padding: 0;
    margin: 0;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
}

.ai-chat-thread button:last-child {
    opacity: 0.7;
    padding: 2px 6px;
    border-radius: 50%;
    transition: all 0.2s ease;
}

.ai-chat-thread button:last-child:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.1);
}

.ai-chat-thread.active button:last-child:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.ai-chat-new-thread-button {
    background-color: rgb(76, 184, 133);
    color: #ffffff;
    border: none;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(76, 184, 133, 0.2);
}

.ai-chat-new-thread-button:hover {
    background-color: #5eb465;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(76, 184, 133, 0.3);
}

.ai-chat-messages {
    flex-grow: 1;
    padding: 16px;
    height: calc(100dvh - 285px);
    max-height: 700px;
    overflow-y: auto;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin: 0;
    scrollbar-width: thin;
    scrollbar-color: #cbd5e1 #f1f5f9;
}

.ai-chat-messages::-webkit-scrollbar {
    width: 6px;
}

.ai-chat-messages::-webkit-scrollbar-track {
    background: #f1f5f9;
    border-radius: 3px;
}

.ai-chat-messages::-webkit-scrollbar-thumb {
    background-color: #cbd5e1;
    border-radius: 3px;
}

.ai-chat-message {
    margin-bottom: 16px;
    padding: 12px 16px;
    border-radius: 16px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    max-width: 85%;
    line-height: 1.5;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.ai-chat-message p {
    margin: 4px 0;
}

.ai-chat-message.user {
    background-color: rgb(76, 184, 133);
    color: #ffffff;
    align-self: flex-end;
    border-radius: 16px 16px 4px 16px;
}

.ai-chat-message.assistant {
    background-color: #f1f5f9;
    color: #1e293b;
    align-self: flex-start;
    border-radius: 16px 16px 16px 4px;
}

.ai-chat-input-wrapper {
    display: flex;
    background-color: #ffffff;
    padding: 16px;
    border-top: 1px solid #eef2f7;
    gap: 12px;
}

.ai-chat-input {
    flex-grow: 1;
    border: 1px solid #e2e8f0;
    padding: 12px 16px;
    font-size: 14px;
    border-radius: 24px;
    transition: all 0.2s ease;
    background-color: #f8fafc;
}

.ai-chat-input:focus {
    outline: none;
    border-color: rgb(76, 184, 133);
    background-color: #ffffff;
    box-shadow: 0 0 0 3px rgba(76, 184, 133, 0.1);
}

.ai-chat-send-button {
    background-color: rgb(76, 184, 133);
    color: #ffffff;
    border: none;
    padding: 12px 24px;
    border-radius: 24px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0 2px 4px rgba(76, 184, 133, 0.2);
}

.ai-chat-send-button:hover {
    background-color: #5eb465;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(76, 184, 133, 0.3);
}

.ai-chat-send-button:disabled {
    background-color: #94a3b8;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

.ai-chat-error {
    color: #ef4444;
    background-color: #fee2e2;
    padding: 12px 16px;
    border-radius: 8px;
    margin: 8px 0;
    font-size: 14px;
    text-align: center;
}

/* Mobile Optimizations */
@media (max-width: 768px) {
    .ai-chat-container {
        border-radius: 0;
        box-shadow: none;
    }

    .ai-chat-header {
        font-size: 16px;
        margin: 16px auto 4px;
    }

    .ai-chat-subheader {
        font-size: 13px;
        margin: 0 auto 8px;
    }

    .ai-chat-threads-wrapper {
        padding: 8px 12px;
    }

    .ai-chat-thread {
        padding: 6px 12px;
        font-size: 12px;
    }

    .ai-chat-new-thread-button {
        width: 32px;
        height: 32px;
        font-size: 18px;
    }

    .ai-chat-messages {
        padding: 12px;
    }

    .ai-chat-message {
        max-width: 90%;
        padding: 10px 14px;
        font-size: 13px;
    }

    .ai-chat-input-wrapper {
        padding: 12px;
    }

    .ai-chat-input {
        padding: 10px 14px;
        font-size: 13px;
    }

    .ai-chat-send-button {
        padding: 10px 20px;
        font-size: 13px;
    }
}

.ai-chat-recipe-preview-row {
    display: flex;
    overflow-x: auto;
    padding: 10px;
    cursor: pointer;
    margin: 5px 0px 0px 0px;
}

.ai-chat-recipe-preview-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 4px;
}

.ai-chat-recipe-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.ai-chat-recipe-detail img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
}

.ai-chat-recipe-detail h3 {
    margin: 10px 0;
}
