.mobile-settings-button{
    position: absolute;
    top: 9px;
    left: 0px;
    z-index:97;
    min-width:50px;
    border:none;
    background:none;
    color:white;
}

.mobile-settings-dialog{
    padding:20px;
}

.mobile-settings-dialog-header{
    letter-spacing: 0.05em;
    font-weight: 350;
    margin-bottom: 5px;
    font-size: 22px;
    text-align: center;
}

.mobile-settings-dialog-selected-vendor{
    margin-top:35px;
    margin-bottom:-25px;
    text-align: center;
    font-size:14px;
}


.mobile-settings-dialog .select-food-vendor-header{
    margin-top:20px;
    font-size:18px;
}


.mobile-settings-dialog .select-food-vendor-warning {
 font-size: 12px;
}