.ai-tracking-add-custom-meal-caloric-info {
    font-size: 10px !important;
    padding: 5px 0px 5px 0px;
  }

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

