.home-mobile-simple-planning {
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.home-mobile-simple-planning .div-simple {
    background-color: #f9f9f9;
    height: 686px;
    overflow: hidden;
    position: relative;
    width: 390px;
}

.home-mobile-simple-planning .ellipse-simple {
    height: 842px;
    left: 945px;
    position: absolute;
    top: 5900px;
    width: 842px;
}

.home-mobile-simple-planning .overlap-group-simple {
    height: 5925px;
    left: -8px;
    position: absolute;
    top: 0;
    width: 398px;
}



.home-mobile-simple-planning .plan-your-meals-for-simple {
    color: #162e16;
    font-family: "Montserrat", Helvetica;
    font-size: 28px;
    font-weight: 600;
    left: 30px;
    letter-spacing: 0;
    line-height: 33px;
    position: absolute;
    text-align: center;
    top: 47px;
    width: 345px;
}

.home-mobile-simple-planning .text-wrapper-simple {
    color: #020e27;
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 110px;
    left: 50px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    text-align: center;
    top: 160px;
    width: 305px;
}

.home-mobile-simple-planning .group-simple {
    height: 65px;
    left: 324px;
    position: absolute;
    top: 10px;
    width: 66px;
}




.home-mobile-simple-planning .image-simple {
    height: 28px;
    left: 364px;
    object-fit: cover;
    position: absolute;
    top: 300px;
    width: 29px;
}

.home-mobile-simple-planning .group-2-simple {
    height: 183px;
    left: 329px;
    position: absolute;
    top: 114px;
    width: 72px;
}

.home-mobile-simple-planning .group-3-simple {
    height: 263px;
    left: 8px;
    position: absolute;
    top: 45px;
    width: 115px;
}


.home-mobile-simple-planning .text-wrapper-12-simple {
    color: #000000;
    font-family: "Quicksand", Helvetica;
    font-size: 12.6px;
    font-weight: 600;
    left: 169px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    text-align: center;
    top: 554px;
}


.home-mobile-simple-planning .landing-mobile-appstore-badge-simple {
    height: 33px;
    left: 79px;
    position: absolute;
    top: 485px;
  }
  
  .home-mobile-simple-planning .landing-mobile-playstore-badge-simple {
    height: 33px;
    left: 215px;
    position: absolute;
    top: 485px;
  }

.home-mobile-simple-planning .group-10-simple {
    height: 26px;
    left: 74px;
    position: absolute;
    top: 596px;
    width: 258px;
}

.home-mobile-simple-planning .rectangle-2-simple {
    height: 35px;
    left: 135px;
    object-fit: cover;
    position: absolute;
    top: 630px;
    width: 135px;
}
