.account-page {
    /* padding: 30px; */
}


.account-page-subheader {
    text-align: start;
    margin: 20px auto 0px auto;
    max-width: 350px;
    font-family: 'Montserrat-SemiBold';
}

.account-page-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 0px #0000000D;
    max-width: 350px;
    width: calc(100% - 30px);
    margin: 12px auto;
    padding: 10px;

}

.account-page-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgb(240, 240, 240);
    padding: 10px 5px;
    overflow-x: hidden;
    white-space:nowrap;
}




.account-page-header {
    text-align: center;
    font-size: 24px;
    margin-top: 12px;
}

.account-page-sign-out-button {
    color: rgb(184, 28, 28) !important;
    margin-left: 8px !important;
}

.account-page-columns {
    display: flex;
    justify-content: center;
}

.account-page-orders {
    text-align: center;
    margin: 15px;

}


.account-page-user-info {
    width: 500px;
    margin: 30px;

}

.account-page-subheading {
    text-align: center;
    font-size: 24px;
}

.account-page-user-info-inner {
    text-align: center;
    margin: 10px;
}



.recipe-book-picture {
    width: 190px;
    object-fit: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: auto 60px;
}

.recipe-book-row {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.recipe-book-name {
    padding: 5px;
    width: 110px;
}

.account-page-edit-dialog{
    padding:10px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.account-page-edit-dialog ul{
    display: flex;
    flex-direction: column;
}

.ap-activity-options {
    padding: 20px;
    max-height: 60vh;
    overflow-y: auto;
  }
  
  .ap-activity-option {
    transition: all 0.2s ease-in-out;
  }
  
  .ap-activity-option:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .ap-activity-option.ap-selected {
    border: 2px solid rgb(76, 184, 133) !important;
  }
  
  .ap-edit-info-dialog-container {
    max-height: 80vh;
    overflow-y: auto;
    padding-bottom: 20px;
  }
  
  .ap-example-tag {
    background-color: #f0f0f0;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    display: inline-block;
    margin: 2px;
  }
  
  .ap-activity-title {
    font-weight: 500;
    margin-bottom: 8px;
  }
  
  .ap-activity-description {
    font-size: 14px;
    color: #666;
  }