.create-new-recipe-link {

    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: 'Montserrat';
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
    font-size: 15px;

}

.create-new-recipe-button {
    align-items: center;
    background: #F9F9F9;
    border: 0 solid #E2E8F0;
    /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
    box-sizing: border-box;
    color: #1A202C;
    display: inline-flex;
    font-size: 12px;
    /* font-weight: 300; */
    justify-content: center;
    line-height: 24px;
    overflow-wrap: break-word;
    padding: 4px 8px;
    text-decoration: none;
    width: auto;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 6px;
    font-size: 11px;
    line-height: 20px;
    background: #2D7933;
    color: white;
    font-family: 'Montserrat-Medium';
}

@media(max-width:750px) {
    .create-new-recipe-button {
        font-size: 11px;
        padding: 3px 6px;
        line-height: 20px;
        background: #2D7933;
        color: white;
        font-family: 'Montserrat-Medium';
    }


}


.import-new-recipe-button {
    align-items: center;
    background: #F9F9F9;
    border: 0 solid #E2E8F0;
    /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
    box-sizing: border-box;
    color: #1A202C;
    display: inline-flex;
    font-size: 12px;
    /* font-weight: 300; */
    justify-content: center;
    line-height: 24px;
    overflow-wrap: break-word;
    padding: 4px 8px;
    text-decoration: none;
    width: auto;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 6px;
    font-size: 11px;
    line-height: 20px;
    background: rgb(230, 239, 218);
    color: rgb(94, 139, 32);
    font-family: 'Montserrat-Medium';
}

@media(max-width:750px) {
    .import-new-recipe-button {
        font-size: 11px;
        padding: 3px 6px;
        line-height: 20px;
        background: rgb(230, 239, 218);
        color: rgb(94, 139, 32);
        font-family: 'Montserrat-Medium';
    }


}
