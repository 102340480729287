.generate-settings-content {
  width: 255px;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.generate-settings-content-small {
  width: 75px;
  font-size: 14px;
  text-align: center;
}

.generate-settings-content-medium {
  width: 130px;
  font-size: 14px;
  text-align: center;
}

.generate-settings-header {
  text-align: center;
  letter-spacing: 0.03em;
  font-weight: 400;


}


.shopping-list-button {
  min-width: 25px !important;
  height: 40px;
  margin-top: 20px !important;
  margin-left: 0px !important;
  margin-bottom: 10px !important;
  width: 245px !important;
  font-size: 13px !important;
  border-radius: 4px 4px 4px 4px !important;
  cursor: pointer !important;
  padding-bottom: 2px !important;
  letter-spacing: -0.4px;
  border: none;
  background-color: #2D7933;
  color: white;
}

.order-in-instacart-button {
  min-width: 25px !important;
  height: 40px;
  margin-top: 4px !important;
  margin-left: 0px !important;
  margin-bottom: 10px !important;
  width: 235px !important;
  font-size: 13px !important;
  border-radius: 4px 4px 4px 4px !important;
  cursor: pointer !important;
  padding-bottom: 2px !important;
  letter-spacing: -0.4px;
  border: none;
  background-color: #013855;
  color: white;
}

.instacart-order-ingredients-button-image {
  width: 80px;
  margin-bottom: -1px;
  margin-left: 4px;
}


.order-in-amazon-button {
  min-width: 25px !important;
  height: 40px;
  margin-top: 4px !important;
  margin-left: 0px !important;
  margin-bottom: 10px !important;
  width: 235px !important;
  font-size: 13px !important;
  border-radius: 4px 4px 4px 4px !important;
  cursor: pointer !important;
  padding-bottom: 1px !important;
  letter-spacing: -0.4px;
  border: none;
  background-color: #81c524;
  color: white;
}

.amazon-order-ingredients-button-image {
  width: 85px;
  margin-bottom: -6px;
  margin-left: 4px;
}

.order-in-wholefoods-button {
  min-width: 25px !important;
  height: 40px;
  margin-top: 4px !important;
  margin-left: 0px !important;
  margin-bottom: 10px !important;
  width: 235px !important;
  font-size: 13px !important;
  border-radius: 4px 4px 4px 4px !important;
  cursor: pointer !important;
  padding-bottom: 6px !important;
  letter-spacing: -0.4px;
  border: none;
  background-color: #2D7933;
  color: white;
}

.whole-foods-order-ingredients-button-image {
  width: 85px;
  margin-bottom: -8px;
  margin-left: 4px;
}

.order-in-amazon-button:disabled,
.order-in-wholefoods-button:disabled,
.order-in-amazon-button[disabled],
.order-in-wholefoods-button[disabled],
.order-in-instacart-button:disabled,
.order-in-instacart-button[disabled] {
  background: #D6D6D6 !important;
  color: #666666 !important;

}

@media(max-width:595px) {
  .order-in-wholefoods-button {
    width: 245px !important;

  }

  .order-in-amazon-button {
    width: 245px !important;

  }

  .order-in-instacart-button {
    width: 245px !important;


  }
}

.order-recipe-ingredient-image-div {
  width: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-right: 15px;
}


.order-recipe-ingredient-image {
  height: 40px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 0px;
}

.shopping-list-content{
  height: calc(100vh - 86px);
  height: calc(100dvh - 86px);
  width: calc(100vw - 20px)
}

/* Shopping List Dialog Styles */
.shopping-list-dialog .MuiDialog-paper {
  border-radius: 8px;
  overflow: hidden;
  max-height: 90vh;
  max-height: 90dvh;
}

.shopping-list-title {
  background-color: #f9f9f9;
  border-bottom: 1px solid #eaeaea;
  padding: 16px 24px !important;
  position: relative;
}

.shopping-list-title h2 {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  text-align: center;
  margin: 0;
}

.shopping-list-close-button {
  position: absolute !important;
  right: 8px;
  top: 8px;
  min-width: 40px !important;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  color: #666 !important;
}

.shopping-list-close-button:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.shopping-list-content {
  padding: 0 !important;
  overflow-y: auto;
  max-height: calc(80vh - 120px);
  max-height: calc(80dvh - 120px);
}

.shopping-list-items {
  padding: 8px 16px;
}

.shopping-list-items > div {
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}

.shopping-list-items > div:last-child {
  border-bottom: none;
}

.shopping-list-empty {
  padding: 32px 16px;
  text-align: center;
  color: #666;
  font-style: italic;
}

.shopping-list-actions {
  border-top: 1px solid #eaeaea;
  padding: 12px 24px !important;
  background-color: #f9f9f9;
  justify-content: center !important;
}

.shopping-list-action-button {
  background-color: #2D7933 !important;
  color: white !important;
  padding: 8px 24px !important;
  border-radius: 4px !important;
  text-transform: none !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.shopping-list-action-button:hover {
  background-color: #236528 !important;
}

.order-recipe-ingredient-image-div {
  width: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-right: 15px;
  flex-shrink: 0;
}

.order-recipe-ingredient-image {
  height: 40px;
  width: auto;
  object-fit: contain;
  padding: 5px 0px;
}

/* Improve checkbox styling */
.ai-tracking-form-checkbox.Mui-checked {
  color: #2D7933 !important;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .shopping-list-dialog .MuiDialog-paper {
    width: 100%;
    margin: 16px;
    max-width: calc(100% - 32px) !important;
  }
  
  .shopping-list-content {
    max-height: calc(100vh - 180px);
    max-height: calc(100dvh - 180px);
  }
}