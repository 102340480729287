.log-weight-input{
    margin:0px 10px !important;
}
.log-weight-input input{
    font-size: 12px !important;
    max-width: 85px !important;
}

.log-weight-input label{
    font-size: 15px;
}