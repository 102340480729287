


.planning-page-top{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    margin-bottom: 3px;
    width:100%;
}

@media(min-width:800px) {
    .planning-page-top{
        justify-content: center;
    }
}

