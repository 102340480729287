/* exercise_tracker_card.css */

.exercise-tracker-card {
  font-family: 'Montserrat', sans-serif;
  background-color: white;
  border-radius: 10px;
  width: calc(100% - 10px);
  margin: 10px 5px;
  padding-top: 5px;
  padding-bottom: 7px;
  background-color: white;
}

.exercise-tracker-header {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 0 15px;
}

.exercise-tracker-header-left {
  display: flex;
  align-items: center;
}

.exercise-tracker-header-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.add-exercise-button {
  background-color: white;
  border: none;
  color: rgb(39, 39, 39);
  height: 20px;
  width:  20px;
  margin-bottom: 1px;
}

.exercise-list {
  overflow-y: auto;
}

.exercise-item {
  display: flex;
  align-items: center;
  padding: 8px 0;
  margin: 0px 5px 0px 10px;
}

.exercise-details {
  display: flex;
  align-items: center;
}

.exercise-activity {
  font-weight: bold;
  display: flex;
  font-size: 14px;
}

.exercise-info {
  font-size: 12px;
  color: gray;
  margin: 5px 0px;
}

.no-exercise {
  text-align: center;
  color: gray;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 5px;
}

.exercise-actions {
  display: flex;
  margin-left: auto;
}

.exercise-actions .MuiIconButton-root {
  padding: 5px;
}

.exercise-not-found-message {
  font-size: 13px;
  color: grey;
  margin-top: 0px;
  margin-bottom: 25px;
  margin-left: 5px;
}

.exercise-gauge-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.exercise-gauge-wrapper {
  flex: 1 0 50%;
  display: flex;
  justify-content: center;
}

.exercise-gauge-chart {
  text-align: center;
  width: 100px;
  margin-top: 10px;
}

.exercise-gauge-label {
  margin-top: -25px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  margin-left: auto;
  margin-right: auto;
}

.exercise-gauge-label span {
  margin-left: 4px;
}

.exercise-gauge-value {
  font-size: 16px;
  font-weight: bold;
  fill: #f1a092; /* Color of the text inside the chart */
}

.dialog-content {
  font-size: 14px;
}
