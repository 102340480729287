@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');


iframe#webpack-dev-server-client-overlay{display:none!important}

.calories-background{
  background-color: #2D7933;
}

.protein-background{
  background-color: #24C581;
}

.carbs-background{
  background-color: #25ABFF;
}

.fat-background{
  background-color: #EE681D;
}

@media screen and (max-device-width: 480px){
  body{
    -webkit-text-size-adjust: 100%;
  }
}

.App {
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html,
body {
  height: 100%;
  background-color: #F9F9F9;
}

.body-inner {
  margin: 0;
  font-family: 'Montserrat', 'Roboto',
    'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiDialog-container {
  font-family: 'Montserrat', 'Roboto',
    'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiFilledInput-underline:before {
  border-bottom: 2px solid black !important;
}

.calories .MuiFilledInput-underline:before {
  border-bottom: 2px solid #2D7933 !important;
}

.protein .MuiFilledInput-underline:before {
  border-bottom: 2px solid #24C581 !important;
}

.carbs .MuiFilledInput-underline:before {
  border-bottom: 2px solid #25ABFF !important;
}

.fat .MuiFilledInput-underline:before {
  border-bottom: 2px solid #EE681D !important;
}

.MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.70) !important;
}

/* .swiper-slide {
  width: auto !important;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media(max-width:600px) {
  .contact-us-bottom-right {
    visibility: hidden;
  }
}

.contact-us-bottom-right {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 100;
  width: 24px;
  height: 26px;
  border-radius: 4px;
  padding: 3px 3px 3px 2px;
  transition: height 0.5s, width 0.5s;
  text-align: center;
  overflow: hidden;
  background: #3C8241;
  display: flex;
  align-items: center;
}

.contact-us-bottom-right a {
  color: white;
}

.contact-us-bottom-right:hover {
  width: 120px;
  font-size: 14px;
}

.contact-us-bottom-right:hover a {
  color: white;

}

.MuiInput-root {
  font-size: 14px !important;
}

@media(max-width:601px) {


  .MuiInput-root {
    font-size: 13px !important;
  }

  .MuiDialog-paperScrollPaper {
    max-height: 100% !important;

  }

  .MuiDialog-paper{
    margin: 0px !important;
  }
  
}

.calendar-add-meal-modal .MuiTab-root {
  font-size: 12px !important;
  font-family: 'Montserrat-SemiBold' !important;
  margin-right: 5px;
  padding: 6px 6px !important;
}

.planning-page .MuiTab-root {
  /* font-size: 9px !important; */
  font-family: 'Montserrat-SemiBold' !important;
  font-size: 11px;
  /* margin:0px 0px !important;
  padding: 6px 1px !important; */
}


@media(min-width:412px) {
  .MuiTab-root {
    padding: 6px 10px !important;

  }
  .planning-page .MuiTab-root {
    font-size: 11px !important;

  }
}


@media(min-width:600px) {
  .MuiTab-root {
    min-width: 120px !important;
  }
}


.MuiTab-textColorInherit{
  text-transform: none !important;

}

.MuiTab-textColorInherit.Mui-selected{
  opacity:1 !important;
  color:#2D7933; 

}

.MuiInput-underline::before{
  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked{
  color:#2D7933 !important;
}

.order-ingredients-button-for-browser{
   
  background-color: #2D7933 !important;
  color: white !important;
  border-radius: 10px;
  border:none;
  padding: 6px 30px;
  margin: 10px 0px 10px 0px;
}

.submit-button{
  background: #F9F9F9;
  border: 0 solid #E2E8F0;
  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
  box-sizing: border-box;
  color: #1A202C;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: 14px;
  /* font-weight: 300; */
  justify-content: center;
  line-height: 24px;
  overflow-wrap: break-word;
  padding: 5px 18px;
  text-decoration: none;
  width: auto;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  line-height: 20px;
  background: #2D7933;
  color: white;
  font-family: 'Montserrat-Medium';
}

.cancel-button{
  background: #ebebeb;
  border: 0 solid #E2E8F0;
  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
  box-sizing: border-box;
  color: #4d4d4d;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: 14px;
  /* font-weight: 300; */
  justify-content: center;
  line-height: 24px;
  overflow-wrap: break-word;
  padding: 5px 18px;
  text-decoration: none;
  width: auto;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  line-height: 20px;
  font-family: 'Montserrat-Medium';
}

.disabled-button{
  background: #ebebeb;
  border: 0 solid #E2E8F0;
  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
  box-sizing: border-box;
  color: #969696;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: 14px;
  /* font-weight: 300; */
  justify-content: center;
  line-height: 24px;
  overflow-wrap: break-word;
  padding: 5px 18px;
  text-decoration: none;
  width: auto;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  line-height: 20px;
  font-family: 'Montserrat-Medium';
}

.mini-submit-button{
  border: 0 solid #E2E8F0;
  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
  box-sizing: border-box;
  color: #1A202C;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: 10px;
  /* font-weight: 300; */
  justify-content: center;
  line-height: 24px;
  overflow-wrap: break-word;
  padding: 2px 5px;
  text-decoration: none;
  width: auto;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  line-height: 20px;
  background: #19a124;
  color: white;
  font-family: 'Montserrat-Medium';
}


@media(max-width:800px)  {
  .bh-post-single{
    margin:15px !important;
  }
}
