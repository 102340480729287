.dashboard-page {
    background: url('images/frame-mobile-up-and-down.png');
    background-repeat: no-repeat;
    background-size: cover;

    background-position: center center;
    /* padding: 60px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-page-top .MuiSelect-select.MuiSelect-select{
    padding-top: 10px;
    padding-bottom: 10px;
}