html,
body {
    height: 100%;
}

* {
    scrollbar-width: thin;
}

.plan-recipes-column::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

*::-webkit-scrollbar-track {
    background: rgb(233, 233, 233);
}

*::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
    border: 2px solid rgb(233, 233, 233);
}

.plan-recipes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100vw);
}

.plan-recipes-top-image {
    background: url('images/recipe-search-desktop.png');
    height: 400px;
    width: 1090px;
    margin: 0px auto 10px auto;
 
}

.plan-recipes-top-image-text {
    color: white;
    font-size: 50px;
    margin-bottom: 40px;
    font-family: 'Recoleta-Medium';
}

@media(max-width:601px) {
    .plan-recipes-top-image {
        background: url('images/recipe-search-mobile.png');
        background-size: cover; /* Ensures the background image covers the entire div */
        height: 220px;
        width: 600px;
        margin: 0px auto 10px auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .plan-recipes-top-image-text {
  
        font-size: 36px;
    }
}

.plan-recipes-top-image-tint{
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    background: rgb(69,64,64,0.65);
    align-items: center;
    justify-content: center;
}





.plan-recipes-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100%);
    box-shadow: 0px 3px 4px -3px rgba(0, 0, 0, .2);
    z-index: 1000;
}


.plan-recipes-filter-button {
    background: none;
    border: none;
}

.plan-recipes-filter-input {
    width: 90%;
    margin: 3px 0px !important;
}

.plan-recipes-section {
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.plan-recipes-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px 0px 10px;
    font-family: 'Montserrat-SemiBold';
    font-size: 14px;
}

.plan-recipes-section-header .plan-recipes-collapse-button {
    min-width: 0px !important;
}

.plan-recipes-list {
    height: auto;
    width: 100%;
}

.plan-recipes-loading-message {
    text-align: center;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: grey;
    margin: 8px 10px 3px 10px;
}

.plan-recipes-no-recipes-message {
    font-size: 13px;
    color: grey;
    text-wrap: wrap;
    margin: 8px 10px 10px 10px;

}

.plan-recipes-row {
    display: inline-block;
    vertical-align: top;
    margin: 0px 5px;
    width: 180px !important;
    text-align: center;
    height: calc(100% - 12px) !important;
    width: 200px;

}

.plan-recipes-thumbnail {
    height: 180px;
    width: 180px;
    object-fit: cover;
    margin-bottom: 0px;
    border-radius: 50%;
}

.plan-recipes-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.plan-recipes-name-outer {
    height: 28px;
    display: flex;
    align-items: center;
    margin: 5px 0px;
}

.plan-recipes-name {
    padding: 5px 0px;
    font-size: 12px;
    width: 180px;
    /* overflow: auto; */
    display: flex;
    overflow-wrap: break-word;
    text-wrap: wrap;
    max-height: 35px;
    overflow: hidden;
    /* align-items: center; */
    justify-content: center;
}

.plan-recipes-likes {
    font-size: 12px;
    color: rgb(153, 153, 153);
    font-family: 'Montserrat-Medium';
    margin: 0px 0px 5px 0px;
}

.plan-recipes-nutrition {
    font-size: 10px;
    padding: 10px 0px;
}

.plan-recipes-add-button {
    margin: 5px 5px !important;
    background: #F9F9F9;
    border: 0 solid #E2E8F0;
    box-sizing: border-box;
    color: #1A202C;
    display: inline-flex;
    font-family: Inter, sans-serif;
    font-size: 14px;
    justify-content: center;
    line-height: 24px;
    overflow-wrap: break-word;
    padding: 5px 18px;
    text-decoration: none;
    width: auto;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    background: #2D7933;
    color: white;
    font-family: 'Montserrat-Medium';
}

.plan-recipes-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 4px;
}

@media(max-width:501px) {
    .plan-recipes-content {
        height: calc(100vh - 108px);
        height: calc(100dvh - 108px);
        overflow-y: auto;
    }

    .plan-recipes-filter-input input {
        font-size: 14px !important;
    }
}

@media(min-width:501px) {
    .plan-recipes-content {
        height: calc(100vh - 108px);
        height: calc(100dvh - 1058x);
        overflow-y: auto;
    }
}