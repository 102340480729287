/* activity_lifestyle_step.css */

.bt-activity-form {
    padding: 30px 15px 0px 15px;
    max-width: 500px;
    width: calc(100vw - 30px);
    margin: 0 auto;
}

.bt-activity-title {
    font-size: 18px;
    margin-top: -10px;
    margin-bottom: 20px;
    font-family: 'Montserrat-SemiBold';
    text-align: center;
}

.bt-activity-section {
    margin-bottom: 24px;
}

.bt-activity-section-title {
    font-weight: 600 !important;
    margin-bottom: 4px !important;
    
}

.bt-activity-section-subtitle {
    color: #666;
    margin-bottom: 12px !important;
}

.bt-activity-options {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.bt-activity-option {
    padding: 16px;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.bt-activity-option:hover {
    border-color: rgba(76, 184, 133, 0.5);
}

.bt-activity-option.selected {
    border-color: rgb(76, 184, 133);
    background-color: rgba(76, 184, 133, 0.05);
}

.bt-activity-option-header {
    margin-bottom: 12px;
}

.bt-activity-option-header h3 {
    margin: 0 0 4px 0;
    font-size: 16px;
    font-weight: 600;
}

.bt-activity-option-header span {
    font-size: 12px;
    color: #666;
}

.bt-activity-option-examples {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.bt-activity-example-tag {
    background: rgba(76, 184, 133, 0.1);
    color: rgb(45, 121, 51);
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 12px;
}

.bt-activity-frequency-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
}

.bt-activity-frequency-option {
    padding: 12px;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.bt-activity-frequency-option:hover {
    border-color: rgba(76, 184, 133, 0.5);
}

.bt-activity-frequency-option.selected {
    border-color: rgb(76, 184, 133);
    background-color: rgba(76, 184, 133, 0.05);
}

.bt-activity-frequency-label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
}

.bt-activity-frequency-description {
    font-size: 12px;
    color: #666;
}

.bt-activity-buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
}

.bt-activity-back-button {
    padding: 10px 20px;
    border: 2px solid rgb(76, 184, 133);
    background: white;
    color: rgb(76, 184, 133);
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-family: 'Montserrat', sans-serif;
}

.bt-activity-back-button:hover {
    background: rgba(76, 184, 133, 0.05);
}

.bt-activity-next-button {
    padding: 10px 20px;
    border: none;
    background: rgb(76, 184, 133);
    color: white;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-family: 'Montserrat', sans-serif;
}

.bt-activity-next-button:hover {
    background: rgba(76, 184, 133, 0.9);
}
