.date-circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    border-radius: 50%;
    margin: 0px 0px;
    cursor: pointer;
    background-color: none;
    color: white;
    border:1px solid white;
}




@media(min-width:600px){
    .date-circle{
        width:50px;
        height:50px;
    }
}


@media(max-width:600px){
    .date-circle{
        width:35px;
        height:35px;
    }
}

@media(max-width:400px){
    .date-circle{
        width:28px;
        height:28px;
    }
}



.date-circle.selected {
    background-color: #D2F1D2;
    color: #1B8E59;;
}

