
.calorie-chart-top-info{
    display: flex;
    align-items: center;
    margin-top: 6px;
}

.calorie-chart-top-info-right{
    margin-left: auto;
    display: flex;
    align-items: center;
}

.calorie-chart-green-dot{
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background-color: #9FDC1C;
    margin: 0px 8px;
}

.calorie-chart-top-info-label{
    font-family: "Montserrat-SemiBold";
}

.calorie-chart-top-info-percent{
    font-family: "Montserrat-SemiBold";
}


.recharts-cartesian-axis-tick{
    font-size: 13px;
}

.calorie-chart-top-info-grams{
    color: grey;
    height:38px;
    display: flex;
    align-items: center;
}



.calorie-chart-protein{
    max-width: 100%;
    height: 6px;
    margin-top: -3px;
}

.calorie-chart-carbs{
    max-width: 100%;
    height: 6px;
    margin-top: -3px;


}

.calorie-chart-fats{
    max-width: 100%;
    height: 6px;
    margin-top: -3px;


}

.calorie-chart-calories{
    max-width: 68px;
    height: 2px;
    margin-top: -4px;

}


.calorie-chart-sodium{
    max-width: 66px;

    height: 2px;
    margin-top: -4px;

}

.calorie-chart-sugar{
    max-width: 51px;

    height:2px;
    margin-top: -4px;

}


.calorie-chart-cholesterol{
    max-width: 95px;
    height: 2px;
    margin-top: -4px;


}


.calorie-chart-fiber{
    max-width: 44px;
    height: 2px;
    margin-top: -4px;


}


.calorie-chart-net-carbs{
    max-width: 82px;
    height: 2px;
    margin-top: -4px;


}

.calorie-chart-saturated-fat{
    max-width: 111px;
    height: 2px;
    margin-top: -4px;


}

.calorie-chart-protein div {
    background-color: #24C581 !important;
  }


  
.calorie-chart-fiber div {
    background-color: #8D9FFF !important;
  }
  
  .calorie-chart-net-carbs div {
    background-color: #2344BB !important;
  }

  .calorie-chart-saturated-fat div {
    background-color: #C72100 !important;
  }
  
  
  .calorie-chart-carbs div {
    background-color: #25ABFF !important;
  }
  
  
.calorie-chart-fats div {
    background-color: #EE681D !important;
  }
  
  .calorie-chart-calories div{
    background-color: #2D7933;
  }


  .calorie-chart-sodium div {
    background-color: #9F48E2 !important;
  }
  
  
.calorie-chart-sugar div {
    background-color: #86D1FF !important;
  }
  
  .calorie-chart-cholesterol div{
    background-color: #E751CF;
  }