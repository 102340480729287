.expanded-recipes-container {
    padding: 0px 0px 0px 0px;
    max-width: 1600px;
    margin: 0 auto;
    margin-top: -59px;
    z-index: 98;
    width: 100%;
}

.expanded-recipes-header {
    height: 250px;
    background: url('images/green-background.jpg');
    background-size: cover;
    /* Ensures the background image covers the entire div */

}

.expanded-recipes-header-tint {
    background: rgb(69,64,64,0.45);
    width: 100%;
    height: 100%;
}

.expanded-recipes-title {
    font-size: 24px;
    font-family: 'Recoleta-Medium';
    color: white;
    margin-left: calc(50% - 166.3px);
    margin-top: 10px;
    margin-bottom: -20px;
}

.expanded-recipes-search {
    display: flex;
    background: white;
    width: 300px;
    border-radius: 20px;
    padding: 0px 5px;
    border: 1px solid #e0e0e0;
    margin: auto;
}

.expanded-recipes-search-container {
    margin:77px auto;
}

.expanded-recipes-filter-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0px 8px;
}

.expanded-recipes-filter-button img {
    width: 18px;
    filter: invert(47%) sepia(3%) saturate(6%) hue-rotate(344deg) brightness(100%) contrast(88%);
}

.expanded-recipes-filter-input {
    flex-grow: 1;
}

.expanded-recipes-grid-container {
    height: calc(100vh - 295px);
    height: calc(100dvh - 295px);
    overflow-y: auto;
    padding: 0px 10px 0px 10px;
    width: calc(100% - 20px);
}

.expanded-recipes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 0px 8px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.expanded-recipes-card {
    display: flex;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top:8px;
}

.expanded-recipes-thumbnail {
    width: 130px;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
}

.expanded-recipes-info {
    flex: 1;
    padding: 12px;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.expanded-recipes-name-outer {
    margin-bottom: 9px;
}

.expanded-recipes-name {
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}

.expanded-recipes-likes {
    font-size: 12px;
    color: #666;
    margin-bottom: 8px;
}

.expanded-recipes-nutrition {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
    flex-wrap: wrap;
    font-size: 10px;
}


.plan-recipes-add-button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    margin-right: 8px;
    font-family: inherit;
}

.expanded-recipes-buttons {
    display: flex;
    gap: 8px;
}

.expanded-recipes-buttons button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.expanded-recipes-buttons button:first-child {
    background: #4CAF50;
    color: white;
}

.expanded-recipes-buttons button:last-child {
    background: #E8F5E9;
    color: #4A7018;
}

.expanded-recipes-active-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}

.filter-chip {
    background: #E8F5E9 !important;
    color: #4A7018 !important;
    border-radius: 16px !important;
    font-size: 12px !important;
    height: 28px !important;
}

@media (max-width: 768px) {
    .expanded-recipes-grid {
        grid-template-columns: 1fr;
 
    }
    .expanded-recipes-grid-container{
        height: calc(100vh - 185px);
        height: calc(100dvh - 185px);
    }

    .expanded-recipes-header {
        height: 140px;
        width: 100%;
    }    

    
    .expanded-recipes-search-container {
        margin:40px auto;
    }
 
    
    
}

.expanded-recipes-title-row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.expanded-recipes-back-button {
    background: none;
    border: none;
    padding: 0px 8px;
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    color: #ffffff;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.expanded-recipes-back-button:hover {
    background-color: #E8F5E9;
}