.barcode-scanner-active{
    opacity: 0;
}
body{
    opacity: 1;
}
.barcode-scanner-modal {
    visibility: visible;
}

.food-search-box-inner-row{
    display: flex;
    flex-direction: row;
}

.food-search-meal-picture {
    object-fit: cover;
    width: 110px;
    height: 110px;
}


.recipe-search-ingredient-row {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
}

.search-ingredient-image-div {
    width: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-right: 10px;

}

.search-ingredient-image {
    height: 40px;
    width: auto;
}

.search-ingredient-name {
    width: 140px;
    margin-right: 10px;
}

.branded-product-search-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.branded-product-search-textfield-parent {
    width: calc(100% - 30px) !important;
    max-width: 350px !important;
    text-align: center;
}

.branded-product-search-textfield {
    margin: 15px 5px 10px 0px !important;
    width: calc(100% - 10px) !important;
}

.branded-product-search-button {
    min-width: 30px !important;
    margin-top: 15px !important;
    margin-bottom: 10px !important;


}


@media(max-width:501px) {
    .branded-product-search-textfield input {
        font-size: 14px !important;
    }

    .branded-product-search-textfield label {
        font-size: 12px !important;
    }

    .food-search-meal-picture {
        object-fit: cover;
        width: 90px;
        height: 90px;
    }
    .branded-product-search-results{
        height:calc(100vh - 142px);
        height:calc(100dvh - 142px);
        width: calc(100vw - 10px);
        overflow-y:auto;
    }


}


@media(max-width:430px) {
    .food-search-meal-picture {
        object-fit: cover;
        width: 70px;
        height: 70px;
    }

    .calendar-add-menu-meal-button {
        min-width: 50px !important;
    }

    .calendar-add-menu-meal-button span {
        font-size: 12px !important;
    }

    .MuiMenuItem-root {
        font-size: 13px !important;
    }
}

.branded-product-search-brand {
    margin: 10px !important;
    font-size: 11px;
    color: grey;
}


.branded-product-search-button {
    align-items: center;
    background: #F9F9F9;
    border: 0 solid #E2E8F0;
    box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    color: #1A202C;
    display: inline-flex;
    font-family: Inter, sans-serif;
    font-size: 13px;
    /* font-weight: 300; */
    justify-content: center;
    line-height: 25px;
    overflow-wrap: break-word;
    padding: 5px 20px;
    text-decoration: none;
    width: auto;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 6px;
    margin-bottom: 10px;
}

.branded-product-search-button:hover {
    background: rgb(219, 219, 219);
}

.barcode-scanner video{
    max-width: 100%;
}