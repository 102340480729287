.swipeable-item {
    position: relative;
    overflow:visible;
    transition: transform 0.3s ease-out;
}
  
   .calendar-meal-extra-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-out;
    z-index: 100;
    background: white;

  }
  
  .calendar-meal-actions-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    z-index: 99;
    pointer-events: none;
  }
  
  .calendar-meal-move-content {
    position: relative;
    width: 53px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4caf50;
    border: none;
    padding: 0;
    cursor: pointer;
    pointer-events: auto;
  }
  
  .calendar-meal-side-content {
    position: relative;
    width: 53px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 119, 119);
    border: none;
    padding: 0;
    cursor: pointer;
    pointer-events: auto;
  }
  
