/* AddRecipeToPlan.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 20px;
    font-family: Arial, sans-serif;
  }
  

  .select-container {
    position: relative;
    display: inline-block;
    width: 300px;
    margin-bottom: 20px;
  }
  
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 15px;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="#000" d="M2 0L0 2h4zM0 3l2 2 2-2z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 10px 10px;
  }
  
