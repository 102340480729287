.step-tracker-card {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  background-color: white;
  border-radius: 10px;
  width: calc(100% - 10px);
  margin: 10px 5px;
  padding-top: 3px;
  padding-bottom: 7px;
}

.step-tracker-header {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 0 15px;
}

.header-left {
  flex: 1;
  text-align: left;
}

.header-center {
  flex: 1;
  text-align: center;
  font-size: 12px;
  color: rgb(131, 131, 131);
  font-family: 'Montserrat';
  font-weight: 300;
}

.header-right {
  flex: 1;
  text-align: right;
}

.step-progress-container {
  position: relative;
  height: 37px;
  margin: 0 15px;
  background-color: #f5f5f5;
  border-radius: 20px;
  overflow: hidden;
}

.step-progress-bar {
  position: absolute;
  height: 100%;
  background-color: rgba(241, 160, 146, 0.2);
  transition: width 0.3s ease;
  border-radius: 20px;
  overflow: hidden;
}

.step-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.3s ease;
  font-size: 24px !important;
  z-index: 1;
}

.settings-content {
  min-width: 300px;
}

.settings-item {
  margin: 10px 0;
} 