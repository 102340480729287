.instacart-page-top {
    background: url('images/thymeless-short.jpg');
    min-height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .instacart-page-top-header {
    font-size: 54px;
    text-align: center;
    margin-bottom: 15px;
    font-weight: 350;
    line-height: 1.2;
    margin-top: -240px;
    
  }
  
  .instacart-page-top-header-secondary {
    font-size: 24px;
    text-align: center;
    margin-top: 30px;
    font-weight: 350;
    line-height: 1.2;
  }
  
  .instacart-page-top-header-description {
    font-size: 16px;
    text-align: center;
    max-width: 480px;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 10px;

  }
  
  @media(min-width:1542px) {
    .instacart-page-top {
      background: url('images/thymeless-wide-short.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      
    }
    .instacart-page-top-header {
        margin-top: -380px;
        
      }
  }
  
  @media(max-width:742px) {
    .instacart-page-top {
      background: url('images/thymeless-mobile.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding:60px;
      padding-top:180px;
    }
    .instacart-page-top-header {
      font-size: 36px;
      text-align: center;
      margin-bottom: 15px;
      font-weight: 350;
      line-height: 1.2;
      margin-top: -100px;
      
    }
    
    .instacart-page-top-header-secondary {
      font-size: 18px;
      text-align: center;
      margin-top: 20px;
      font-weight: 350;
      line-height: 1.2;
    }
    
    .instacart-page-top-header-description {
      font-size: 14px;
      text-align: center;
      max-width: 400px;
      margin-left: 20px;
      margin-right: 20px;
      font-weight: 400;
      line-height: 1.2;
    }
  }