/* General Styles */

/* Add to onboarding_wizard.css */
.onboarding-wizard-container {
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  max-width: 400px;
  width: calc(100vw - 20px);
  margin: 0 auto;
  position: relative;
}

.onboarding-wizard-base-container {
  min-height: 100vh;
  min-height: 100dvh;
}

.onboarding-wizard-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.onboarding-wizard-footer {
  margin-top: auto;
  padding: 20px 0;
}

.onboarding-wizard-title-first {
    text-align: center;
    font-size: 28px;
    color: #07490D;
    font-family: 'Montserrat-SemiBold';
    margin-bottom: 4px;
  }
  
  .onboarding-wizard-title-first-next {
    text-align: center;
    font-size: 28px;
    color: #162E16;
    font-family: 'Montserrat-SemiBold';
  }
  
  .onboarding-wizard-title {
    text-align: center;
    font-size: 15px;
    margin: 20px 0px;
    font-family: 'Montserrat-SemiBold';
  }
  
  /* Upsell Rows */
  
  .onboarding-wizard-upsell-row {
    display: flex;
    align-items: center;
    padding: 12px 0;
    margin-bottom: 6px;
  }
  
  .onboarding-wizard-upsell-row-icon {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 50%;
    box-shadow: 0 2px 12px rgba(0,0,0,0.08);
    margin-right: 16px;
  }
  
  .onboarding-wizard-upsell-row-icon-inner {
    width: 28px;
    height: 28px;
    object-fit: contain;
  }
  
  .onboarding-wizard-upsell-row-text-first {
    flex: 1;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
  }
  
  .onboarding-wizard-upsell-row-inner {
    flex-shrink: 0;
  }
  
  .onboarding-wizard-upsell-row-image {
    width: 140px;
    max-height: 80px;
    object-fit: contain;
  }
  
  .onboarding-wizard-upsell-row-text {
    font-size: 13px;
    margin-bottom: 10px;
    margin-left: 20px;
    max-width: 220px;
  }
  
  .onboarding-wizard-upsell-row-number {
    color: green;
    font-family: 'Montserrat-SemiBold';
    font-size: 24px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  /* Onboarding Wizard Buttons */
  
  .onboarding-wizard-button {
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: none !important;
    padding: 18px !important;
    margin: 4px !important;
    margin-bottom: 10px !important;
    position: relative;
    cursor: pointer;
    border: 2px solid white !important;
  }

  .onboarding-wizard-notification-button {
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: white;
    border: none !important;
    padding: 18px !important;
    margin: 4px !important;
    margin-bottom: 10px !important;
    position: relative;
    cursor: pointer;
    border: 2px solid white !important;
  }
  
  .onboarding-wizard-button.selected {
    border: 2px solid #2d793379 !important;
  }
  
  .onboarding-wizard-notification-button.selected {
    border: 2px solid #2d793379 !important;
  }
  

  .onboarding-wizard-button-image {
    flex-shrink: 0;
  }
  
  .onboarding-wizard-option-image {
    width: 150px;
    max-width: 35vw;
    height: auto;
  }
  
  .onboarding-wizard-button-text {
    margin-left: 15px !important;
    flex: 1;
  }
  
  .onboarding-wizard-button-title {
    text-transform: none !important;
    font-family: 'Montserrat-SemiBold';
    font-size: 16px;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .onboarding-wizard-button-content {
    color: rgb(69, 69, 69) !important;
    text-transform: none !important;
    text-align: left !important;
    font-size: 14px;
  }
  
  /* Checkmark Icon */
  
  .onboarding-wizard-checkmark-icon {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 24px;
    height: 24px;
  }
  
  /* Helper Text */
  
  .onboarding-wizard-helper-text {
    text-align: center;
    font-family: 'Montserrat-SemiBold';
    color: #2D7933;
    font-size: 14px;
    margin-top: 15px;
  }
  
  /* Form Submit Button */
  
  .onboarding-wizard-form-submit-button {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    align-items: center;
  }
  
  /* Calorie Chart Dots */
  
  .calorie-chart-green-dot {
    width: 10px;
    height: 10px;
    background-color: #259B36;
    border-radius: 50%;
    display: inline-block;
  }
  
@keyframes ribbonFloat {
  0% {
    transform: translateX(-50px) rotate(-5deg) scaleY(0.9);
    opacity: 0.1;
  }
  50% {
    transform: translateX(0) rotate(0deg) scaleY(1);
    opacity: 0.3;
  }
  100% {
    transform: translateX(-50px) rotate(-5deg) scaleY(0.9);
    opacity: 0.1;
  }
}

@keyframes ribbonFloatRight {
  0% {
    transform: translateX(50px) rotate(5deg) scaleY(0.9);
    opacity: 0.1;
  }
  50% {
    transform: translateX(0) rotate(0deg) scaleY(1);
    opacity: 0.3;
  }
  100% {
    transform: translateX(50px) rotate(5deg) scaleY(0.9);
    opacity: 0.1;
  }
}

/* Medium height screens */
@media screen and (min-height: 700px) {
  .onboarding-wizard-content {
    padding: 30px 0;
  }

  .onboarding-wizard-title {
    font-size: 17px;
    margin: 20px 0px;
  }

  .onboarding-wizard-upsell-row {
    padding: 15px 0;
    margin-bottom: 10px;
  }

  .onboarding-wizard-upsell-row-text-first {
    font-size: 15px;
  }

  .onboarding-wizard-upsell-row-text {
    font-size: 14px;
  }

  .onboarding-wizard-upsell-row-number {
    font-size: 26px;
  }
}

/* Taller screens */
@media screen and (min-height: 800px) {
  .onboarding-wizard-content {
    padding: 40px 0;
  }

  .onboarding-wizard-title {
    font-size: 19px;
    margin: 30px 0px;
  }

  .onboarding-wizard-upsell-row {
    padding: 20px 0;
    margin-bottom: 12px;
  }

  .onboarding-wizard-upsell-row-image {
    width: 160px;
    max-height: 90px;
  }

  .onboarding-wizard-button {
    padding: 24px !important;
    margin-bottom: 16px !important;
  }

  .onboarding-wizard-button-title {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .onboarding-wizard-button-content {
    font-size: 15px;
  }

  .onboarding-wizard-upsell-row-text-first {
    font-size: 15px;
  }

  .onboarding-wizard-upsell-row-text {
    font-size: 15px;
  }

  .onboarding-wizard-upsell-row-number {
    font-size: 28px;
  }

  .onboarding-wizard-title-first,
  .onboarding-wizard-title-first-next {
    font-size: 32px;
  }
}

/* Extra tall screens */
@media screen and (min-height: 900px) {
  .onboarding-wizard-content {
    padding: 50px 0;
  }

  .onboarding-wizard-title {
    font-size: 21px;
    margin: 50px 0px;
  }

  .onboarding-wizard-upsell-row {
    padding: 20px 0;
    margin-bottom: 20px;
  }

  .onboarding-wizard-upsell-row-text-first {
    font-size: 16px;
  }

  .onboarding-wizard-upsell-row-text {
    font-size: 16px;
  }

  .onboarding-wizard-upsell-row-number {
    font-size: 30px;
  }

  .onboarding-wizard-title-first,
  .onboarding-wizard-title-first-next {
    font-size: 36px;
  }

  .onboarding-wizard-button-title {
    font-size: 20px;
  }

  .onboarding-wizard-button-content {
    font-size: 16px;
  }

  .onboarding-wizard-upsell-row-image {
    width: 180px;
    max-height: 100px;
  }
}