.calendar-page-outer-frame {
    display:flex;
    height: calc(100% - 45px);
    flex-direction: column;
}

.calendar-page-middle-frame {
    display: flex;
    flex: 1;
    height:100%;
}


.calendar-page-inner-frame {
    display: flex;
    flex-direction: column;
    height:100%;
    background: linear-gradient(#1E6A46, #B2FFDC);

}

.calendar-add-meal-modal .MuiAppBar-root{
    max-width: calc(100% - 30px);

}

.calendar-page-inner-nav-button {
    padding: 10px 20px !important;
}

.calendar-meal-counter {
    background-color: rgb(228, 129, 0);
    padding: 2px 4px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    margin-left: 4px;
    font-size: 10px;
    margin-bottom: 3px;
    color: white;

}

.calendar-page-content {
    height: calc(100% - 50px);
}

.input-calories-popover {
    margin: 30px;
}

.input-calories-popover-header {
    margin: 10px;
    text-align: center;
}
