.select-food-vendor {
    min-height: 150px;
    max-height: 100vh;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.select-food-vendor-warning {
    margin-top: -14px;
    color: #c50000;
    margin-bottom: 12px;
}

.select-food-vendor-header {
    letter-spacing: 0.05em;
    font-weight: 350;
    margin-bottom: 20px;
    font-size: 22px;
}

.food-vendor-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    position: relative;
}

.food-vendor-disabled-overlay {
    z-index: 1;
    height: 100%;
    width: 100%;
    position: absolute;
    /* overflow: auto; */
    top: 0px;
    left: 0px;
    background: rgba(255, 255, 255, 0.75);
    /*can be anything, of course*/
    font-size: 14px;
    padding: 8px;
    font-weight: 600;
    color: rgb(59, 59, 59);
}

@media(max-width:500px) {
    .food-vendor-picture {
        height: 150px;
        width: 150px;
        object-fit: cover;
        background-position: center center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .food-vendor-column {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        width: 170px;
    }
    .food-vendor-name {
        font-size: 14px;
        margin-bottom: 3px;
    }
    .food-vendor-rating {
        font-size: 12px;
        display: flex;
        align-items:center;
        margin-bottom: 4px !important;
    }
    .food-vendor-details {
        font-size: 12px;
        margin-bottom: 5px;
    }
    .food-vendor-request-to-add-button {
        position: absolute;
        top: calc(50% - 40px);
        left: 18px;
    }
    .food-vendor-delivery-cost{
        position:absolute;
        top:calc(100% - 22px);
        background-color: rgba(255, 255, 255, 0.95);
        border-radius: 5px;
        font-size:11px;
        right: 183px;
        padding:2px;
    }
}




@media(min-width:501px) {
    .food-vendor-picture {
        height: 220px;
        width: 220px;
        object-fit: cover;
        background-position: center center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .food-vendor-column {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        width: 220px;
    }
    .food-vendor-name {
        font-size: 18px;
        margin-bottom: 3px;
    }
    .food-vendor-rating {
        font-size: 14px;
        display: flex;
        align-items:center;
        margin-bottom: 4px !important;
    }
    .food-vendor-details {
        font-size: 14px;
        margin-bottom: 8px;
    }
    .food-vendor-request-to-add-button {
        position: absolute;
        top: calc(50% - 40px);
        left: 53px;
    }
    .food-vendor-delivery-cost{
        position:absolute;
        top:calc(100% - 27px);
        background-color: rgba(255, 255, 255, 0.95);
        border-radius: 5px;
        font-size:13px;
        right: 244px;
        padding: 2px 3px;
    }
}

