/* calendar_copy_from_history.css */

.calendar-copy-from-history {
    display: inline-block;
    vertical-align: middle;
  }
  
  .calendar-copy-from-history-icon {
    cursor: pointer;
    height: 21px;
    margin: 0 15px;
    transition: opacity 0.2s ease;
  }
  
  .calendar-copy-from-history-icon:hover {
    opacity: 0.8;
  }
  
  .calendar-copy-from-history-dialog {
    padding: 20px 10px;
  }
  
  .history-dialog-paper {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    max-width: 600px !important;
  }
  
  .history-dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #eaeaea;
  }
  
  .history-dialog-title {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: #333;
  }
  
  .history-dialog-close-button {
    min-width: 40px !important;
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    color: #666 !important;
  }
  
  .history-dialog-close-button:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
  
  .history-dialog-filter {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
    font-size: 14px;
    color: #444;
  }
  
  .history-dialog-filter span {
    margin-right: 8px;
  }
  
  .history-category-select {
    min-width: 120px !important;
    margin-left: 8px !important;
  }
  
  .history-dialog-content {
    padding: 0;
    max-height: 400px;
    overflow-y: auto;
  }
  
  .history-empty-state {
    padding: 32px 24px;
    text-align: center;
    color: #888;
    font-style: italic;
    font-size: 14px;
  }
  
  .meal-history-list {
    padding: 8px 0;
  }
  
  .meal-history-item {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .meal-history-item:hover {
    background-color: rgba(45, 121, 51, 0.05);
  }
  
  .meal-history-item.selected {
    background-color: rgba(45, 121, 51, 0.08);
  }
  
  .meal-history-checkbox {
    margin-right: 8px;
  }
  
  .history-checkbox.Mui-checked {
    color: #2D7933 !important;
  }
  
  .history-dialog-meal-image-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 16px;
    background-color: #f5f5f5;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .history-dialog-meal-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .history-dialog-meal-placeholder {
    width: 100%;
    height: 100%;
    background-color: #eaeaea;
  }
  
  .history-dialog-meal-info {
    flex: 1;
  }
  
  .history-dialog-meal-name {
    font-weight: 500;
    color: #333;
    margin-bottom: 4px;
    font-size: 15px;
  }
  
  .history-dialog-meal-date {
    font-size: 13px;
    color: #666;
  }
  
  .history-dialog-actions {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
    background-color: #f9f9f9;
    border-top: 1px solid #eaeaea;
  }
  
  .history-cancel-button {
    margin-right: 12px !important;
    color: #666 !important;
    text-transform: none !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }
  
  .history-submit-button {
    background-color: #2D7933 !important;
    color: white !important;
    text-transform: none !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    padding: 6px 16px !important;
    border-radius: 4px !important;
  }
  
  .history-submit-button:hover {
    background-color: #236528 !important;
  }
  
  .history-submit-button:disabled {
    background-color: #D6D6D6 !important;
    color: #666666 !important;
  }
  
  @media (max-width: 600px) {
    .history-dialog-paper {
      width: calc(100% - 32px);
      margin: 16px;
    }
    
    .history-dialog-content {
      max-height: calc(100vh - 240px);
      max-height: calc(100dvh - 240px);
    }
    
    .meal-history-item {
      padding: 12px;
    }
    
    .history-dialog-meal-image-wrapper {
      width: 50px;
      height: 50px;
    }
  }
  