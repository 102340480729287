html,
body {
  height: 100%;
}

* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */

.calendar-column::-webkit-scrollbar {
  width: 0px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: rgb(233, 233, 233);
}

*::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 20px;
  border: 2px solid rgb(233, 233, 233);
}

.inner-calendar-frame .swiper-container {
  height: 100%;
}

.calendar-header {
  display: flex;
  flex-direction: column;
  letter-spacing: 0.05em;
  font-weight: 400;
  /* text-transform: uppercase; */
  padding: 0px 0px 0px 0px;
  font-size: 16px;
  /* min-height: 82px; */
  text-align: center;
  display: flex;
  align-items: center;
  background: none;
  color: white;

}

.calendar-header-top {
  display: flex;
  align-items: center;
  padding-top: 2px;
  min-height: 40px;
  max-width: 92%;
  font-family: 'Montserrat-SemiBold';
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 5px 0px;
  border-bottom: 1px white solid;
  width: 100%;
}

.calendar-header-calories {
  font-size: 11px;
  letter-spacing: 0.05em;
  font-weight: 400;
  text-shadow: 0 1px 1px #FFFFFF;
  text-transform: uppercase;
  padding-left: 10px;
  padding-top: 4px;
  box-sizing: border-box;
  font-family: 'Montserrat';
}

.calendar-header .add-button {
  background-color: #2D7933 !important;
  border-radius: 10px;
  color: white;
  font-family: 'Montserrat-Medium';
  padding: 9px 15px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border: none;
  align-items: center;
  text-transform: none !important;
  margin: 8px 14px !important;
  width: 115px !important;
}

.generate-button {
  background-color: #2D7933 !important;
  border-radius: 10px;
  color: white;
  font-family: 'Montserrat-Medium';
  padding: 9px 15px;
  display: flex;
  margin-left: 12px;
  margin-right: 12px;
  border: none;
  align-items: center;
  height: 42px;

}

.log-weight-button {
  font-size: 15px;
  background: #40B37D;
  color: white;
  border-radius: 10px;
  font-family: 'Montserrat-Medium';
  padding: 9px 11.7px;
  display: flex;
  margin-left: 12px;
  margin-right: 12px;
  border: none;
  align-items: center;

}



.generate-button-disabled {
  min-height: 30px !important;
  padding: 0px 10px !important;
  margin: 10px 5px !important;
  font-size: 13px !important;
  background-color: #d6d6d6 !important;
  color: #a1a1a1 !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.calendar-header .MuiButton-root {
  min-width: 0px !important;
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width .6s ease;
}


.calendar-header-delivery-time-window-message {
  font-size: 12px;
  text-transform: none;
  padding-top: 7px;
  padding-bottom: 5px;
}

.inner-calendar-frame {
  width: 100%;
  height: 100%;
  overflow: auto;
  border-bottom: 5px solid rgb(231, 231, 231);
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;


}


.swiper-slide,
.swipper-wrapper {
  display: flex;
}

.swiper-container {
  align-self: normal;
}

.swiper-custom-button-previous .MuiSvgIcon-root,
.swiper-custom-button-next .MuiSvgIcon-root,
.calendar-meal-carousel-previous .MuiSvgIcon-root,
.calendar-meal-carousel-next .MuiSvgIcon-root {
  font-size: 24px;
}

.swiper-button-disabled {
  color: #f9f9f9 !important;
}

.calendar-columns::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.calendar-columns::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.calendar-column {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  /* width: 270px; */
  overflow-y: auto;
  overflow-x: hidden;
  background: none;
}

.calendar-empty-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: grey;
}

.calendar-empty-slot {
  font-size: 11.5px;
  min-height: 84px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.calendar-empty-slot-wrapper {
  margin: 10px 5px;
  border-radius: 10px;

  background: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.calendar-meal-slot {
  margin: 10px 5px;
  font-size: 11.5px;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  overflow: hidden;
}

.calendar-hover-slot {
  height: 55px;
  margin: 5px;
  background-color: rgb(220, 220, 220);
}

.calendar-meal-slot-top {
  display: flex;
  align-items: center;
  padding: 3px 2px 3px 2px;
  font-weight: 400;
  text-shadow: 0 1px 1px #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 3px;
}

.calendar-meal-category {
  margin-right: auto;
}

.calendar-slot-category {
  font-family: 'Montserrat-SemiBold';
  text-transform: capitalize;
  margin-left: 15px;
  font-size: 13px;
  display: flex;

}


.calendar-empty-slot .add-button {
  min-width: 26px !important;
  max-width: 26px !important;
  font-size: 15px !important;
  margin-left: 10px !important;
  margin-right: 2px !important;
  color: #7c7c7c !important;

}

.calendar-meal-slot .add-button {
  min-width: 26px !important;
  max-width: 26px !important;
  font-size: 15px !important;
  margin-right: 3px !important;
  color: #7c7c7c !important;

}


.calendar-slot-copy-button {
  min-width: 22px !important;
  max-width: 22px !important;
  margin-left: 0px !important;
  padding-top: 4px !important;
  font-size: 16px !important;
  margin-left: 0px !important;
  margin-right: 2px !important;

}

.calendar-empty-slot .calendar-slot-generate-button {
  min-width: 22px !important;
  max-width: 22px !important;
  margin-left: 3px !important;
  padding-top: 5px !important;
  font-size: 16px !important;
  margin-right: 2px !important;
  color: #7c7c7c !important;


}

.calendar-meal-slot .calendar-slot-generate-button {
  min-width: 22px !important;
  max-width: 22px !important;
  margin-left: 3px !important;
  padding-top: 5px !important;
  margin-bottom: -1px !important;
  font-size: 16px !important;
  margin-right: 2px !important;
  color: #7c7c7c !important;

}



.calendar-meal {
  min-height: 60px;
  margin: 0px 3px 3px 3px;
  padding: 1px 4.5px 1px 3px;
  position: relative;
  background-color: white !important;
  width: 100%;

}

.calendar-meal:hover {

  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 22%);

}

.calendar-meal-text {
  flex: 1;
  margin: 6px 0px;
}

.calendar-meal-top {
  display: flex;
}

.calendar-meal-left {
  display: flex;
  flex: 1;
  align-items: center;
}


.calendar-meal-calorie-label {
  color: #B3B3B3;
  display: flex;
  margin-right: 5px;
  font-size: 11px;
}

.calendar-meal-calorie-chip {
  color: white;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 10px;
  margin: 0px 5px;
}

.calendar-meal-thumbnail {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;

}

.delete-button {
  min-width: 10px !important;
  max-height: 18px !important;
  padding: 12px 2px !important;
  float: right;
  text-align: right;
  position: sticky !important;
}

.delete-button svg {
  font-size: 14px !important;
  color: #919191
}

.calendar-meal-delete-button {
  min-width: 10px !important;
  max-height: 18px !important;
  padding: 12px 2px !important;
  right: 5px;
  top: 2px;
  position: absolute !important;
}


.calendar-meal-delete-button svg {
  font-size: 12px !important;
  color: #919191
}

.calendar-meal-label {
  font-family: 'Montserrat-SemiBold';
  max-width: 90%;
  margin-bottom: 10px;
}


.pin-button {
  min-width: 9px !important;
  max-height: 18px !important;
  padding: 5px 2px !important;
  float: right;
  text-align: right;
  font-size: 12px !important;
}

.disabled-pin {
  color: grey;
  width: 20px !important;

}

.enabled-pin {
  color: indianred;
  width: 20px !important;
}

.duplicate-button {
  min-width: 10px !important;
  max-height: 18px !important;
  padding: 5px 0px !important;
  font-size: 9px !important;
  position: absolute !important;
  bottom: 1px;
  right: 4px;
}

.calendar-add-meal-modal {
  min-width: 320px;
  max-width: 500px;
  min-height: 700px;
  padding: 10px;
}

.calendar-add-meal-modal .MuiAppBar-root {
  max-width: calc(100% - 30px);

}

.calendar-add-meal-popover .MuiPopover-paper {
  bottom: 50px;
  top: 80px;
}

.calendar-add-recipe-meal-top {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-recipes-textfield-button {
  background: none;
  border: none;
}

@media(min-width:501px) {
  .calendar-add-menu-meal-filter-textfield {
    margin: 10px 10px 10px 0px !important;
    width: 90%;
    max-width: 500px;
  }

}

.calendar-add-meal-modal-exit-button {
  padding: 5px 5px !important;
  min-width: 10px !important;
  margin-right: -3px !important;
  color: rgb(83, 83, 83) !important;
  font-size: 16px !important;

}

.calendar-add-meal-modal .MuiToggleButton-root.Mui-selected {
  color: white !important;
  background: #24C581;
}

.calendar-add-meal-modal .MuiToggleButton-root:hover {
  color: white !important;
  background: #24C581;
}

.calendar-add-menu-meal-name {
  padding: 5px 0px 5px 0px;
  font-size: 14px;
}

.calendar-add-menu-meal-likes {
  font-size: 12px;
  color: rgb(153, 153, 153);

  font-family: 'Montserrat-Medium';
  margin: 3px 0px 3px 1px;
}

@media(max-width:601px) {
  .inner-calendar-frame {

    border-bottom: none
  }

  .calendar-column-slots {
    overflow-y: auto;
  }

  .calendar-add-menu-meal-filter-textfield {
    margin: 10px 10px 10px 0px !important;
    width: 90%;
  }

  .calendar-add-menu-meal-filter-textfield input {
    font-size: 14px !important;
  }

  .calendar-add-meal-modal {
    width: calc(100vw - 20px);
    min-height: calc(100vh - 20px);
    min-height: calc(100dvh - 20px);


  }


  .calendar-add-menu-meal-name {
    font-size: 12px;
  }

}


.calendar-add-menu-meal-row {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.calendar-add-menu-meal-picture {
  height: 110px;
  width: 110px;
  object-fit: cover;
  border-radius: 50px;
}

.calendar-add-menu-meal-column {
  display: flex;
  flex-direction: column;
}

.calendar-add-menu-meal-caloric-info {
  font-size: 10px;
  padding: 10px 0px 12px 0px;
}

.calendar-add-custom-meal-caloric-info {
  font-size: 10px;
  padding: 5px 0px 5px 0px;
}

.caloric-chip {
  padding: 4px 7px;
  border-radius: 10px;
  color: white;
  margin-right: 4px;
  text-transform: none;

}

.caloric-chip-calories {
  color: black;
  padding: 4px 0px;
  margin-right:5px;
  font-size: 11px;
}

.caloric-chip-protein {
  background-color: #24C581;
}

.caloric-chip-carbs {
  background-color: #25ABFF;
}

.caloric-chip-fats {
  background-color: #EE681D;
}



.calendar-add-to-cart-top-button {
  background: #F9F9F9;
  border: 0 solid #E2E8F0;
  font-size: 13px !important;
  border-radius: 10px !important;
  text-transform: none !important;
  font-family: 'Montserrat-SemiBold' !important;
  color: #4e4e4e !important;
  padding: 5px 9px !important;

  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */

}


.calendar-add-menu-meal-button {
  margin: 5px 5px 5px 0px !important;
  background: #F9F9F9;
  border: 0 solid #E2E8F0;
  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
  box-sizing: border-box;
  color: #1A202C;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: 14px;
  /* font-weight: 300; */
  justify-content: center;
  line-height: 24px;
  overflow-wrap: break-word;
  padding: 5px 18px;
  text-decoration: none;
  width: auto;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-left: 6px;
  line-height: 20px;
  background: #2D7933;
  color: white;
  font-family: 'Montserrat-Medium';
}

.calendar-add-recipe-meal-header {

  font-family: 'Montserrat-SemiBold';
  padding: 8px 10px 0px 10px;
  font-size: 14px;
  margin-bottom: -3px;
}

.custom-meal-form {
  margin-top: 20px;
}

.custom-meal-form input {
  padding: 11px 12px 10px !important;
}

.custom-meal-form-top-message {
  text-align: center;
  margin: 20px auto;
  width: 250px;
  font-family: 'Montserrat-SemiBold';
}

.custom-meal-form-header {
  padding: 4px;
  font-weight: 500;
}

.custom-meal-form-line-item {
  padding: 5px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.custom-meal-form-line-item input {
  padding: 3px;
}

.custom-meal-form-input {
  width: 200px;
}

@media(max-width:501px) {
  .custom-meal-form-input input {
    font-size: 16px !important;
  }

  .calendar-add-recipe-meal-content {
    height: calc(100vh - 115px);
    height: calc(100dvh - 115px);
    overflow-y: auto;

  }
}

@media(min-width:501px) {
  .calendar-add-recipe-meal-content {
    height: calc(100vh - 235px);
    height: calc(100dvh - 235px);
    overflow-y: auto;

  }
}


.custom-meal-form-submit-button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.calendar-hover-card {
  padding: 8px;
}


.checkmark-animation {
  font-size: 24px;
  color: white;
  /* Change the color to white */
  opacity: 1;
  animation: fadeOut 1.5s forwards;
  height: 20px;
  width: 29px;
  margin-bottom: 2px;
  margin-top: -2px;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}