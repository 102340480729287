.dietary-preferences-form {
    margin: 30px;
  }
  
  .dietary-preferences-form-line-item {
      padding: 8px;
      margin-bottom: 5px;
      overflow: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .dietary-preferences-form-input-narrow{
      width:120px !important;
    }
    .dietary-preferences-form input{
      font-size: 14px !important;
    }
  
    .dietary-preferences-form p{
      font-size: 14px !important;
    }
  
    .dietary-preferences-form label{
      font-size: 14px !important;
    }
  
    /* Medium height screens */
    @media screen and (min-height: 700px) {
      .dietary-preferences-form {
        margin: 40px;
      }
  
      .dietary-preferences-form-line-item {
        padding: 12px;
        margin-bottom: 8px;
      }
  
      .dietary-preferences-form input,
      .dietary-preferences-form p,
      .dietary-preferences-form label {
        font-size: 15px !important;
      }
  
      .modify-recipe-unit-text-normal {
        font-size: 15px;
      }
    }
  
    /* Taller screens */
    @media screen and (min-height: 800px) {
      .dietary-preferences-form {
        margin: 50px;
      }
  
      .dietary-preferences-form-line-item {
        padding: 16px;
        margin-bottom: 10px;
      }
  
      .dietary-preferences-form input,
      .dietary-preferences-form p,
      .dietary-preferences-form label {
        font-size: 16px !important;
      }
  
      .modify-recipe-unit-text-normal {
        font-size: 16px;
      }
    }
  
    /* Extra tall screens */
    @media screen and (min-height: 900px) {
      .dietary-preferences-form {
        margin: 60px;
      }
  
      .dietary-preferences-form-line-item {
        padding: 20px;
        margin-bottom: 12px;
      }
  
      .dietary-preferences-form input,
      .dietary-preferences-form p,
      .dietary-preferences-form label {
        font-size: 17px !important;
      }
  
      .modify-recipe-unit-text-normal {
        font-size: 17px;
      }
    }
  
  
    .dietary-preferences-form-submit-button {
      display: flex;
      justify-content: center;
      margin-top: 5px;
      align-items: center;
    } 