@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@font-face {
  font-family: "Gotham-Medium";
  src: local("Gotham-Medium"),
    url("./fonts/GothamMedium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Gotham-Book";
  src: local("Gotham-Book"),
    url("./fonts/GothamBook.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url("./fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
    url("./fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Recoleta-Medium";
  src: local("Recoleta-Medium"),
    url("./fonts/Recoleta-Medium.ttf") format("truetype");
  font-weight: normal;
}

/* 
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-Regular"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
} 

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url("./fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: normal;
}  */

@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

button {
  font-family: 'Montserrat';
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root {
  height: 100%;
}
body{
  margin:0px;
}

.MuiPaper-root{
  font-family: 'Montserrat';
}