.meal-hover-card-frame {
    background-color: #f9f9f9;
}

.meal-hover-card {
    padding: 30px;
    text-align: center;
    background-color: #f9f9f9;
    min-height: calc(100vh - 20px);
}

.meal-hover-card-header {
    margin: 15px 5px 15px 5px;
    padding: 10px 8px;
    font-size: 16px;
    letter-spacing: 0.06em;
    font-weight: 300;
    text-shadow: 0 1px 1px #ffffff;
    text-transform: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 0px #0000000D;
}

.meal-hover-card-header-description {
    font-size: 13px;
    letter-spacing: 0.02em;
    font-weight: 400;
    text-transform: none;
    margin-top: 8px;
    margin-bottom:5px;
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
}

.meal-hover-card-columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;

}

.meal-hover-card-picture {
    width: 350px;
    height: 350px;
    object-fit: cover;
    margin-right: 5px;


}

@media(max-width:500px) {
    .meal-hover-card-frame {
        /* height: 100vh; */
    }
}

@media(max-width:750px) {
    .meal-hover-card {
        padding: 10px;
        text-align: center;
        min-width: calc(100vw - 20px);
    }

    .meal-hover-card-picture {
        width: 280px;
        height: 280px;
        object-fit: cover;
        margin-right: 5px;
        border-radius: 10px;

    }

    .meal-hover-card-header {
        font-size: 16px;
    }

    .meal-hover-card-info-tag {
        font-size: 12px;
    }
}



.meal-hover-card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 0px #0000000D;

}

.meal-hover-card-top-info {
    margin: 0px 15px 12px 15px;
    text-align: center;
    font-size: 15px;
}

.meal-hover-card-info-tags {
    margin-top: 12px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    text-shadow: none;

}

.meal-hover-card-options {
    font-size: 13px;
}

.meal-hover-card-options .MuiSelect-selectMenu {
    font-size: 12px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    margin-left: 2px;
}

.meal-hover-card-info-tag {
    color: #5e8b20 !important;
    ;
    background: #E6EFDA !important;
    font-family: 'Montserrat-SemiBold' !important;
    text-transform: uppercase !important;
    padding: 4px;
    margin: 2px 2px 8px 2px;
    border-radius: 5px;
    display: inline-block;
}

.meal-hover-card-nutrients {
    background-color: white;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 0px #0000000D;
    padding: 5px 5px 20px 5px;

}


.meal-details-card-add-to-cart-button {
    align-items: center;
    background: #24C581;
    color: white;
    border: 0 solid #E2E8F0;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.4), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    display: inline-flex;
    font-size: 14px;
    /* font-weight: 300; */
    justify-content: center;
    line-height: 30px;
    overflow-wrap: break-word;
    padding: 4px 8px;
    text-decoration: none;
    width: auto;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-left: 6px;
    margin-top: 10px;
    margin-bottom: 10px;

}

.meal-details-card-add-to-cart-button:hover {
    background: rgb(219, 219, 219);
}