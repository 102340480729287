.water-tracker-card {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    background-color: white;
    border-radius: 10px;
    width: calc(100% - 10px);
    margin: 10px 5px;
    padding-top: 4px;
    padding-bottom: 7px;
  }
  
  .water-tracker-header {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
    padding: 0 15px;
  }
  
  .header-left {
    flex: 1;
    text-align: left;
  }
  
  .header-center {
    flex: 1;
    text-align: center;
    font-size: 12px;
    color: rgb(131, 131, 131);
    font-family: 'Montserrat';
    font-weight: 300;
  }
  
  .header-right {
    flex: 1;
    text-align: right;
  }
  
  .water-drops-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .water-drop {
    color: #dfdfdf; /* Empty color */
    transition: color 0.3s, transform 0.2s;
    height:32px;
    width:32px;
  }
  
