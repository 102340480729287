/* calorie_calculator.css */
.calorie-calculator-form {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (max-width: 768px) {
  .calorie-calculator-form {
    height: 100vh;
    height: 100dvh;
    
  }
}


.calorie-calculator-form .MuiSlider-root{
  color: rgba(76, 184, 133, 0.9);
}

.calorie-calculator-form-line-item,
.calorie-calculator-form-line-item-text {
  margin-bottom: 20px;
}

.calorie-calculator-form-buttons {
  text-align: center;
  margin-top: 20px;
}

.macro-slider {
  margin-bottom: 10px;
}

.macro-slider .MuiTypography-body2 {
  margin-bottom: 5px;
}

.calorie-calculator-form .MuiTextField-root {
  width: 100%;
}

.calorie-calculator-form .MuiFormControl-root {
  width: 100%;
}
