.MuiDrawer-paper{
    background-color: #f9f9f9 !important;
}

.sidebar-change-calories-button {
    background: #f9f9f9 !important;
    margin-left: 10px !important;
}

.settings-sidebar-paper {
  background-color: #f9f9f9 !important;
  width: 280px;
}

.settings-sidebar-container {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}

.settings-sidebar-title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin: 0;
  padding: 0 24px 16px;
  border-bottom: 1px solid #eaeaea;
}

.settings-sidebar-item {
  border-bottom: 1px solid #eaeaea;
}

.settings-sidebar-item a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.sidebar-button {
  width: 100%;
  justify-content: flex-start !important;
  padding: 12px 24px !important;
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  color: #444 !important;
  transition: background-color 0.2s ease !important;
  border-radius: 0 !important;
}

.sidebar-button:hover {
  background-color: rgba(45, 121, 51, 0.08) !important;
}

.sidebar-icon {
  margin-right: 12px !important;
  color: rgb(45, 121, 51) !important;
  font-size: 20px !important;
}

.settings-sidebar-divider {
  height: 16px;
  background-color: #f1f1f1;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}

.sidebar-button-danger {
  color: #d32f2f !important;
}

.sidebar-button-danger:hover {
  background-color: rgba(211, 47, 47, 0.08) !important;
}

.sidebar-button-signout {
  color: #666 !important;
}

