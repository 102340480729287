.recipes-page {
    /* padding: 30px; */
}

.recipes-page-header {
    text-align: center;
    font-size: 32px;
    margin-top: 30px;
}

.recipes-page-sign-out-button {
    color: rgb(184, 28, 28) !important;
}

.recipes-page-columns {
    display: flex;
    justify-content: center;
}

.recipes-page-orders {
    text-align: center;
    margin: 15px 0px;

}


.recipes-page-user-info {
    width: 500px;
    margin: 30px;

}

.recipes-page-subheading {
    text-align: center;
    font-size: 24px;
}

.recipes-page-user-info-inner {
    text-align: center;
    margin: 10px;
}



.recipe-book-picture {
    width: 220px;
    object-fit: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: auto 60px;
}

.recipe-book-row {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.recipe-book-name {
    padding: 5px;
    width: 110px;
}


@media(max-width:700px) {
    .recipe-book-picture {
        width: 180px;
    }

    
.recipes-page-header {
    margin-top: 0px;
}

}