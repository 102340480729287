.weekly-report-button {
    background-color: #2D7933;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .weekly-report-button:hover {
    background-color: #27662d;
  }
  